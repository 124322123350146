import { Plugin, getCurrentInstance } from "vue";
import ModulesService, { ProcessEnabledModel } from "@/modules/wms/common/services/ModulesService";

export interface WmsModules
{
    get(modules: Record<string, boolean>): Promise<Record<string, boolean>>;
    check(modulesToCheck: string[]): Promise<ProcessEnabledModel[]>;
}

class WmsModulesHelper implements WmsModules
{
    private wmsModules: ProcessEnabledModel[];

    public async get(modules: Record<string, boolean>): Promise<Record<string, boolean>>
    {
        const response = await ModulesService.getActiveStatus();

        const items = response.result;

        this.wmsModules = response.result;

        items.forEach(module =>
        {
            modules[module.moduleName] = module.value;
        });

        return modules;
    }

    public async check(modulesToCheck: string[]) : Promise<ProcessEnabledModel[]>
    {
        if (!this.wmsModules)
        {
            const modules: Record<string, boolean> = {};

            modulesToCheck.forEach(x => modules[x] = false);

            await this.get(modules);
        }

        return this.wmsModules.filter(x => modulesToCheck.includes(x.moduleName));
    }
}

const SitemapPlugin: Plugin =
{
    install(app, options)
    {
        app.config.globalProperties.$wmsmodules = new WmsModulesHelper();
    }
};

export const useWmsModules = () =>
{
    const app = getCurrentInstance();

    return {
        $wmsmodules: app.appContext.config.globalProperties.$wmsmodules
    };
};

export default SitemapPlugin;

declare module "@vue/runtime-core"
{
    interface ComponentCustomProperties
    {
        $wmsmodules: WmsModules;
    }
}
