import FieldAffix from './FieldAffix.vue';
import FieldAutocomplete from './FieldAutocomplete.vue';
import FieldCategory from './FieldCategory.vue';
import FieldCheckbox from './FieldCheckbox.vue';
import FieldCheckboxList from './FieldCheckboxList.vue';
import FieldChoice from './FieldChoice.vue';
import FieldComponent from './FieldComponent.vue';
import FieldCondition from './FieldCondition.vue';
import FieldExpression from './FieldExpression.vue';
import FieldDateTime from './FieldDateTime.vue';
import FieldEditor from './FieldEditor.vue';
import FieldError from './FieldError.vue';
import FieldFilter from './FieldFilter.vue';
import FieldForm from './FieldForm.vue';
import FieldMinMax from './FieldMinMax.vue';
import FieldModule from './FieldModule.vue';
import FieldName from './FieldName.vue';
import FieldNumeric from './FieldNumeric.vue';
import FieldOptions from './FieldOptions.vue';
import FieldPeriod from './FieldPeriod.vue';
import FieldRadioList from './FieldRadioList.vue';
import FieldReadonly from './FieldReadonly.vue';
import FieldRequired from './FieldRequired.vue';
import FieldSelect from './FieldSelect.vue';
import FieldText from './FieldText.vue';
import FieldTextarea from './FieldTextarea.vue';
import FieldVisible from './FieldVisible.vue';
import FieldColumns from './FieldColumns.vue';

export {
    FieldAffix,
    FieldAutocomplete,
    FieldCategory,
    FieldCheckbox,
    FieldCheckboxList,
    FieldChoice,
    FieldComponent,
    FieldCondition,
    FieldExpression,
    FieldDateTime,
    FieldEditor,
    FieldError,
    FieldFilter,
    FieldForm,
    FieldMinMax,
    FieldModule,
    FieldName,
    FieldNumeric,
    FieldOptions,
    FieldPeriod,
    FieldRadioList,
    FieldReadonly,
    FieldRequired,
    FieldSelect,
    FieldText,
    FieldTextarea,
    FieldVisible,
    FieldColumns
};

export default {
    'field-affix': FieldAffix,
    'field-autocomplete': FieldAutocomplete,
    'field-category': FieldCategory,
    'field-checkbox': FieldCheckbox,
    'field-checkboxlist': FieldCheckboxList,
    'field-choice': FieldChoice,
    'field-component': FieldComponent,
    'field-condition': FieldCondition,
    'field-expression': FieldExpression,
    'field-datetime': FieldDateTime,
    'field-editor': FieldEditor,
    'field-filter': FieldFilter,
    'field-form': FieldForm,
    'field-error': FieldError,
    'field-minmax': FieldMinMax,
    'field-module': FieldModule,
    'field-name': FieldName,
    'field-numeric': FieldNumeric,
    'field-options': FieldOptions,
    'field-period': FieldPeriod,
    'field-radiolist': FieldRadioList,
    'field-readonly': FieldReadonly,
    'field-required': FieldRequired,
    'field-select': FieldSelect,
    'field-text': FieldText,
    'field-textarea': FieldTextarea,
    'field-visible': FieldVisible,
    'field-columns': FieldColumns
};
