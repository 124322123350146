import { axios } from '@/plugins/axios';
import { Statement, Resource } from '@/helpers/Interfaces';

/**
 * GlobalConfigurationService
 */
export default class GlobalConfigurationService
{
    /**
     *
     * @returns Promise<Resource<ConfigFormModel>>
     */
    public static async fetchConfig(): Promise<Resource<ConfigFormModel>>
    {
        return (await axios.get<Resource<ConfigFormModel>>(`admin/configuration/global`)).data;
    }

    /**
     * @param model FormModel
     *
     * @returns Promise<Statement>
     */
    public static async updateConfig(model: ConfigFormModel): Promise<Statement>
    {
        return (await axios.put<Statement>(`admin/configuration/global`, model)).data;
    }
}

export interface ConfigFormModel
{
    connectZoneWithWarehouse: boolean
    useCarriers: boolean
    allowInsertBatchNumber: boolean
    qrCodeScheme: string
    localizationFillingCalculationType: string
}
