<template>
    <template v-if="item.result[configuration.field]">
        <template v-if="configuration.withRedirect">
            <router-link :to="{name: configuration.route, params: params}">{{ item.result[configuration.field].quantityAsString }}</router-link>
        </template>
        <span v-else>{{ item.result[configuration.field].quantityAsString }}</span>
    </template>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component';
import { BaseFormatterMixin } from '@/components/dynamicgird/mixins/BaseFormatterMixin';

@Options({})
export default class QuantityFormatter extends mixins(BaseFormatterMixin)
{

}
</script>
