<template>
    <div class="number-widget">
        <span v-if="loaded">{{ statValue || "n/a" }}</span>
        <placeholder :height="10" v-else></placeholder>
    </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { useAlerts } from '@/plugins/alerts';
import { useLocalization } from '@/plugins/localization';
import { useMixins } from '@/plugins/mixins';
import { WidgetModel } from "@/modules/core/home/services/HomeService";
import WidgetsService from "@/modules/core/common/services/WidgetsService";

interface Props
{
    config: WidgetModel
    publicId: string
}

defineOptions({
    name: 'ReceiptsProductsSumWidget',
    inheritAttrs: false,
});

const props = defineProps({
  "config": { default: null },
  "publicId": { default: '' }
});

const { $alert } = useAlerts();
const { $t } = useLocalization();
const { $handleException } = useMixins();

const statValue = ref<string>('');
const loaded = ref<boolean>(false);

onMounted(async (): Promise<void> =>
{
    await loadData();
});

const loadData = async (): Promise<void> =>
{
    loaded.value = false;

    try
    {
        const { result } = await WidgetsService.getReceiptsProductsSum();

        statValue.value = result.count.toString();
    }
    catch (ex)
    {
        $handleException(ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });
    }
    finally
    {
        loaded.value = true;
    }
};
</script>

<style lang="scss" scoped>
</style>
