<template>
    <div class="input-wrapper">
        <div class="labeled-input" @click.stop="focusOnInput">
            <span v-if="placeholder" class="input-label" :class="{ 'active': isFocused || currentValue, 'disabled': isDisabled }">
                {{ placeholder }}
                <span class="required-star" v-if="flag(required)">*</span>
            </span>
            <input
                class="form-control"
                :class="{'clearable-input': clearable}"
                :disabled="isDisabled"
                :type="type"
                :min="min"
                :name="name"
                :value="currentValue"
                :aria-label="placeholder"
                @input="updateModel"
                @focus="activateLabel"
                @blur="deactivateLabel"
                ref="input"
                :step="step"
            >
            <div class="append-container">
                <slot name="append" v-if="hasAppendSlot"></slot>
                <button v-if="lockable" @click.prevent.stop="toggleField" type="button" class="lock button-reset" :class="{'locked': isDisabled || currentValue}" :title="isDisabled ? $t('[[[Odblokuj pole]]]') : $t('[[[Zablokuj pole]]]')">
                    <span class="fa-solid fa-lock gray-icon" v-if="isDisabled"></span>
                    <span class="fa-solid fa-lock-open gray-icon" v-else></span>
                </button>
            </div>
        </div>
        <ideo-button variant="secondary" class="clear-button" :title="$t('[[[Wyczyść]]]')" v-if="clearable" @click.prevent="clear()" :disabled="isDisabled">
            <i class="fas fa-fw fa-times"></i>
        </ideo-button>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Ref, Watch } from '@/helpers/Decorators';

@Options({
    name: 'labeled-form-input'
})
export default class LabeledFormInput extends Vue
{
    private currentValue: string = null;
    private isFocused: boolean = false;
    private isDisabled: boolean = false;

    @Ref('input')
    private input: () => HTMLInputElement;

    @Prop({ default: "text" })
    public type: string;

    @Prop({ default: 0 })
    public min: number;

    @Prop({ default: '' })
    public name: string;

    @Prop()
    public modelValue: string;

    @Prop({ default: '' })
    public placeholder: string;

    @Prop({ default: false, type: Boolean })
    private disabled: boolean;

    @Prop({ default: false, type: Boolean })
    private lockable: boolean;

    @Prop({ default: false })
    public required: boolean;

    @Prop({ default: '0' })
    public step: string;

    @Prop({ default: true })
    public clearable: boolean;

    public hasAppendSlot(): boolean
    {
        return !!this.$slots.append;
    }

    private flag(value: any): boolean
    {
        return value !== false;
    }

    public toggleField(): void
    {
        this.isDisabled = !this.isDisabled;
        this.onDisabledField();

        if (this.isDisabled) this.isFocused = false;
        else
        {
            this.$nextTick(() =>
            {
                this.focusOnInput();
            });
        }
    }

    @Emit('disabledField')
    public onDisabledField(): any
    {
        return {
            name: this.name,
            disabled: this.isDisabled
        };
    }

    @Emit('duringChanges')
    public activateLabel(): boolean
    {
        this.isFocused = true;

        return true;
    }

    public deactivateLabel(): boolean
    {
        if (this.input() && !this.input().value)
            this.isFocused = false;

        if (this.input() && this.input().value && this.input().value.length === 0)
            this.isFocused = false;

        return false;
    }

    public focusOnInput(): void
    {
        this.$nextTick(() =>
        {
            this.input().focus();
        });
    }

    @Emit('input')
    @Emit('update:modelValue')
    private updateModel(e: Event): string
    {
        const target = e.target as HTMLInputElement;

        this.currentValue = target.value;

        return this.currentValue;
    }

    @Emit('update:modelValue')
    public clear(): null
    {
        this.currentValue = null;

        return this.currentValue;
    }

    @Watch('modelValue', { immediate: true })
    private onModelChanged(value: any[] | any): void
    {
        this.currentValue = value;

        if (value === null || value === undefined || !String(value))
        {
            this.isFocused = false;
        }
        else
        {
            this.isFocused = true;
        }
    }

    @Watch('disabled', { immediate: true })
    private onDisabledChanged(value: boolean): void
    {
        this.isDisabled = value;
    }
}
</script>

<style lang="scss" scoped>
    .input-wrapper {
        position: relative;
        display: flex;
        align-items: stretch;
        width: 100%;

        .form-control {
            &.clearable-input {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        .clear-button {
            z-index: 1;
            margin-left: calc(var(--bs-border-width) * -1);
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .labeled-input {
        .append-container {
            position: absolute;
            right: 12px;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-items: flex-end;
            gap: 20px;
            font-size: .875rem;

            &:deep(> *) {
                display: flex;
            }

            &:deep(button) {
                font-size: 1.25rem;
            }

            #layout.mobile & {
                gap: 40px;
                right: 25px;
            }
        }

        .form-control {
            height: 40px;
            font-weight: 500;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
            &[type=number] {
                appearance: textfield;
                -moz-appearance: textfield;
            }

            @media (min-width: 1200px) {
                height: 36px;
            }
        }
    }
</style>
