<template>
    <div ref="popover" :class="popoverClasses" v-if="visible">
        <div :class="arrowClasses" style=""></div>
        <h3 class="popover-header" v-if="title || 'title' in $slots">
            <slot name="title">{{ title }}</slot>
        </h3>
        <div class="popover-body">
            <slot name="default"></slot>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch, Ref } from '@/helpers/Decorators';
import { createPopper, Instance as Popper } from '@popperjs/core';
import { nextTick } from 'vue';

const PLACEMENTS = {
    'auto': { popover: 'auto', arrow: 'auto' },
    'top': { popover: 'top', arrow: 'center' },
    'topleft': { popover: 'top', arrow: 'left' },
    'topright': { popover: 'top', arrow: 'right' },
    'right': { popover: 'right', arrow: 'middle' },
    'righttop': { popover: 'right', arrow: 'top' },
    'rightbottom': { popover: 'right', arrow: 'bottom' },
    'bottom': { popover: 'bottom', arrow: 'center' },
    'bottomleft': { popover: 'bottom', arrow: 'left' },
    'bottomright': { popover: 'bottom', arrow: 'right' },
    'left': { popover: 'left', arrow: 'middle' },
    'lefttop': { popover: 'left', arrow: 'top' },
    'leftbottom': { popover: 'left', arrow: 'bottom' }
};

@Options({
    name: 'ideo-popover'
})
export default class IdeoPopover extends Vue
{
    public popper: Popper = null;
    public visible: boolean = false;
    public popoverPlacement: string = 'auto';
    public arrowPlacement: string = 'auto';

    @Ref('popover')
    public popover: () => HTMLElement;

    @Prop({ default: undefined })
    public show: boolean;

    @Prop({ default: null })
    public title: string;

    @Prop({ default: false})
    public local: boolean;

    @Prop({
        default: 'click',
        validator: (value: string) =>
        {
            const allowed = ['click', 'hover', 'focus', 'blur'];
            const items = value.split(new RegExp('\\s+'));
            const approved = items.filter(p => allowed.includes(p));

            return items.length == approved.length;
        }
    })
    public triggers: string;

    @Prop({ default: null })
    public boundary: string;

    @Prop({ default: null, required: true })
    public target: HTMLElement | string;

    @Prop({
        default: 'right',
        validator: (value: string) => Object.keys(PLACEMENTS).includes(value)
    })
    public placement: string;

    @Prop({ default: false })
    public rounded: boolean;

    @Prop({ default: true })
    public shadow: boolean;

    public get resolvedPlacement(): {popover: string, arrow: string}
    {
        return PLACEMENTS[this.placement];
    }

    public get resolvedTriggers(): string[]
    {
        return this.show == undefined ? this.triggers.toLowerCase().trim().split(' ').sort() : [];
    }

    public get popoverClasses(): Record<string, boolean>
    {
        let popoverPlacementClass;

        // due to coreUI changes we have to replace CSS classes
        switch (this.popoverPlacement)
        {
            case 'left':
                popoverPlacementClass = 'start';
                break;
            case 'right':
                popoverPlacementClass = 'end';
                break;
            default:
                popoverPlacementClass = this.popoverPlacement;
        }

        return {
            'popover': true,
            'shadow': this.shadow,
            'rounded-0': !this.rounded,
            [`bs-popover-${popoverPlacementClass}`]: true
        };
    }

    public get arrowClasses(): Record<string, boolean>
    {
        return {
            'arrow': true,
            'popover-arrow': true,
            [`bs-popover-arrow-${this.arrowPlacement}`]: true
        };
    }

    public targetElement(): HTMLElement
    {
        return typeof this.target == 'string' ? document.getElementById(this.target) : this.target;
    }

    public mounted(): void
    {
        const target = this.targetElement();

        for (const trigger of this.resolvedTriggers)
        {
            if (trigger === 'click')
            {
                target.addEventListener('click', this.handleEvent, { capture: false });
            }
            else if (trigger === 'focus')
            {
                target.addEventListener('focusin', this.handleEvent, { capture: false });
                target.addEventListener('focusout', this.handleEvent, { capture: false });
            }
            else if (trigger === 'blur')
            {
                target.addEventListener('focusout', this.handleEvent, { capture: false });
            }
            else if (trigger === 'hover')
            {
                target.addEventListener('mouseenter', this.handleEvent, { capture: false });
                target.addEventListener('mouseleave', this.handleEvent, { capture: false });
            }
        }

        if (!this.local)
            document.addEventListener("ideo-popover:open", this.onGlobalOpen);
    }

    public beforeUnmount(): void
    {
        this.destroyPopper();
    }

    public unmounted(): void
    {
        const target = this.targetElement();
        const events = ['click', 'focusin', 'focusout', 'mouseenter', 'mouseleave'];

        for (const event of events)
        {
            target && target.removeEventListener(event, this.handleEvent as any, { capture: false });
        }

        document.removeEventListener("ideo-popover:open", this.onGlobalOpen);
    }

    public onGlobalOpen(e: any): void
    {
        if (e.detail.component() != this)
        {
            this.close();
        }
    }

    public handleEvent(e: MouseEvent | FocusEvent): void
    {
        const type = e.type;
        const target = this.targetElement();
        const triggers = this.resolvedTriggers;

        if (type === 'click' && triggers.includes('click'))
        {
            this.toggle(true);
        }
        else if (type === 'mouseenter' && triggers.includes('hover'))
        {
            this.toggle(true);
        }
        else if (type === 'focusin' && triggers.includes('focus'))
        {
            this.toggle(true);
        }
        else if ((type === 'focusout' && (triggers.includes('focus') || triggers.includes('blur'))) || (type === 'mouseleave' && triggers.includes('hover')))
        {
            const tip = this.$refs.popover as HTMLElement;
            const eventTarget = e.target as HTMLElement;
            const relatedTarget = e.relatedTarget as HTMLElement;

            if (
                // From tip to target
                (tip && tip.contains(eventTarget) && target.contains(relatedTarget)) ||
                // From target to tip
                (tip && target.contains(eventTarget) && tip.contains(relatedTarget)) ||
                // Within tip
                (tip && tip.contains(eventTarget) && tip.contains(relatedTarget)) ||
                // Within target
                (target.contains(eventTarget) && target.contains(relatedTarget))
            )
            {
                // If focus/hover moves within `tip` and `target`, don't trigger a leave
                return;
            }

            // Otherwise trigger a leave
            this.toggle(false);
        }
    }

    public toggle(visible: boolean): void
    {
        visible ? this.open() : this.close();
    }

    public onPopoverMouseLeave(e: any): void
    {
        if (e?.toElement?.id !== 'ai-generator-button')
            this.close();
    }

    @Emit('shown')
    public open(target?: HTMLElement): void
    {
        this.visible = true;
        this.createPopper(target || this.targetElement());

        document.dispatchEvent(new CustomEvent("ideo-popover:open", {
            detail: { component: () => this },
        }));

        nextTick(() =>
        {
            this.popover().addEventListener('mouseleave', this.onPopoverMouseLeave);
        });
    }

    @Emit('hidden')
    public close(): void
    {
        if (this.visible)
        {
            this.popover().removeEventListener('mouseleave', this.onPopoverMouseLeave);
            this.destroyPopper();
            this.visible = false;
        }
    }

    @Watch('show')
    public onShowChanged(value: boolean): void
    {
        this.toggle(value);
    }

    public createPopper(element: HTMLElement): void
    {
        this.destroyPopper();
        this.$nextTick(() =>
        {
            this.popper = createPopper(element, this.$refs.popover as any, this.getPopperConfig());
        });
    }

    public destroyPopper(): void
    {
        this.popper && this.popper.destroy();
        this.popper = null;
    }

    public getPopperConfig(): any
    {
        let placement = this.resolvedPlacement.popover;

        switch (this.resolvedPlacement.arrow)
        {
            case 'top':
                placement = `${placement}-start`;
                break;
            case 'middle':
                break;
            case 'bottom':
                placement = `${placement}-end`;
                break;
            case 'left':
                placement = `${placement}-start`;
                break;
            case 'center':
                break;
            case 'right':
                placement = `${placement}-end`;
                break;
        }

        const config: any = {
            placement: placement,
            strategy: 'fixed',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 8]
                    }
                }
            ],
            onFirstUpdate: (state: any) =>
            {
                // eslint-disable-next-line prefer-const
                let [popover, arrow] = state.placement.split('-');

                if (['top', 'bottom'].includes(popover))
                {
                    switch (arrow)
                    {
                        case 'start': arrow = 'left'; break;
                        case 'end': arrow = 'right'; break;
                        default: arrow = 'center'; break;
                    }
                }

                if (['left', 'right'].includes(popover))
                {
                    switch (arrow)
                    {
                        case 'start': arrow = 'top'; break;
                        case 'end': arrow = 'bottom'; break;
                        default: arrow = 'middle'; break;
                    }
                }

                this.popoverPlacement = popover;
                this.arrowPlacement = arrow;
            }
        };

        if (this.boundary)
        {
            config.modifiers.push({
                name: 'preventOverflow',
                options: {
                    boundary: document.querySelector(this.boundary),
                    padding: 0
                }
            });
        }

        return config;
    }
}
</script>

<style lang="scss">
.popover {
    &-body {
        overflow: hidden;
    }
    &-arrow {
        position: absolute;
    }
}

.bs-popover {
    &-arrow {
        &-top,
        &-middle,
        &-bottom {
            height: 100% !important;
            top: 0;
        }

        &-top {
            &:before, &:after {
                top: 0;
            }
        }
        &-middle {
            &:before, &:after {
                top: calc(50% - 8px);
            }
        }
        &-bottom {
            &:before, &:after {
                bottom: 0;
            }
        }

        &-left,
        &-center,
        &-right {
            width: 100% !important;
            left: 0;
        }

        &-left {
            &:before, &:after {
                left: 0;
            }
        }
        &-center {
            &:before, &:after {
                left: calc(50% - 8px);
            }
        }
        &-right {
            &:before, &:after {
                right: 0;
            }
        }
    }
}
</style>
