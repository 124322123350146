import { axios } from '@/plugins/axios';
import { Resource } from '@/helpers/Interfaces';


export default class VersionService
{
    public static async getWmsVersion(): Promise<Resource<VersionModel>>
    {
        return (await axios.get<Resource<VersionModel>>('admin/wms/version')).data;
    }
}

export interface VersionModel {
    version: string;
}
