import ValidationSchema, { Validator, Errors } from './Validation';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';
import { $t } from '@/plugins/localization';

type ValidationSchematic = Record<string, Validator.AnySchema>;
type FormError = Record<string, any>;

class FormHelper<T extends Record<string, any>>
{
    private $initial: Partial<T> = {};

    public $validators: ValidationSchematic = {};
    public $loading: boolean;
    public $loaded: boolean;
    public $errors: Errors;

    [prop: string]: any;

    /**
     * Create a new Form instance.
     */
    public constructor(data: T, validationSchema: ValidationSchematic)
    {
        this.$loaded = false;
        this.$loading = false;
        this.$errors = new Errors();
        this.setInitialValues(data);
        this.withData(data)
            .withErrors({});

        if (validationSchema)
        {
            this.$validators = validationSchema;
        }
    }

    private initializeValidationSchema(schema: ValidationSchematic): void
    {
        if (schema)
        {
            this.$validators = schema;
        }
    }

    public withData(data: T): FormHelper<T>
    {
        for (const field in data)
        {
            this[field as string] = data[field];
        }

        return this;
    }

    public withErrors(errors: Record<string, string[]>): FormHelper<T>
    {
        this.$errors.record(errors);

        return this;
    }

    /**
     * Fetch all relevant data for the form.
     */
    public data(): T
    {
        const data = {} as any;

        for (const property in this.$initial)
        {
            data[property] = this[property] as any;
        }

        return data as T;
    }

    /**
     * Fetch only selected data for the form.
     */
    public only(props: string[]): any
    {
        const data = {} as any;

        for (const property in this.$initial)
        {
            if (props.includes(property))
            {
                data[property] = this[property] as any;
            }
        }

        return data;
    }

    /**
     * Fetch data for the form except selected.
     */
    public except(props: string[]): any
    {
        const data = {} as any;

        for (const property in this.$initial)
        {
            if (!props.includes(property))
            {
                data[property] = this[property] as any;
            }
        }

        return data;
    }

    /**
     * Reset the form fields.
     */
    public reset(field: string = null): void
    {
        if (field)
        {
            this[field] = this.$initial[field];
            this.$errors.clear(field);
        }
        else
        {
            merge(this, cloneDeep(this.$initial));
            this.$errors.clear();

            Object.keys(this).forEach(field =>
            {
                if (Array.isArray(this[field]))
                    this[field] = [];
            });
        }

        this.complete(true);
    }

    public setInitialValues(values: any): void
    {
        this.$initial = {};

        merge(this.$initial, cloneDeep(values));
    }

    /**
     * Clear the form fields.
     */
    public clear(): void
    {
        for (const field in this.$initial)
        {
            this[field as string] = cloneDeep(this.$initial[field]);
        }

        this.$errors.clear();
    }

    public loading(): boolean
    {
        return !this.$loaded && this.$loading;
    }

    public loaded(): boolean
    {
        return !this.loading();
    }

    public wait($forceLoading: boolean = false): void
    {
        if ($forceLoading)
            this.$loaded = false;

        this.$loading = true;
    }

    public continue(): void
    {
        this.complete(this.valid());
    }

    public complete(status: boolean = true): void
    {
        if (status == true)
        {
            this.$errors.clear();
        }

        this.$loading = false;
        this.$loaded = true;
    }

    public dirty(): string[]
    {
        return Object.keys(this.$initial).filter(key => this[key] != this.$initial[key]);
    }

    public async ready(values: Promise<boolean>[]): Promise<void>
    {
        this.wait();

        const result = await Promise.all(values);

        this.complete(result.every(p => p === true));
    }

    public valid(): boolean
    {
        return !this.$errors.any();
    }

    public active(): boolean
    {
        return !this.$loading && this.valid();
    }

    private rebuildValuesModel(): T
    {
        const values: Record<string, unknown> = {};

        for (const field in this.$initial)
        {
            values[field] = this[field];
        }

        return values as T;
    }

    /**
     * @param { string } field Validate value of specific field synchronously
     * @returns { void }
     */
    public validateFieldFromSchema(field: string): void
    {
        const values: T = this.rebuildValuesModel();

        try
        {
            this.$errors.clear(field);
            Validator.object(this.$validators).validateSyncAt(field, values, { abortEarly: false });
        }
        catch (e)
        {
            (e as FormError).errors.forEach((message: string) => this.$errors.push(field, $t(message)));
        }
    }

    /**
     * @param { string } field Validate value of specific field asynchronously
     * @returns { Promise<void> }
     */
    public async validateFieldFromSchemaAsync(field: string): Promise<void>
    {
        const values: T = this.rebuildValuesModel();

        try
        {
            this.$loading = true;
            this.$errors.clear(field);
            await Validator.object(this.$validators).validateAt(field, values, { abortEarly: false });
        }
        catch (e)
        {
            (e as FormError).errors.forEach((message: string) => this.$errors.push(field, $t(message)));
        }
        finally
        {
            this.$loading = false;
        }
    }

    /**
     * @description Validate all fields synchronously
     * @description Use this method if you want to use validators passed in the constructor
     * @returns {boolean} isFormValid state
     */
    public validateFromSchema(): boolean
    {
        for (const field in this.$initial)
        {
            this.validateFieldFromSchema(field);
        }

        return this.$errors.any();
    }

    /**
     * @description Validate all fields asynchronously
     * @description Use this method if you want to use validators passed in the constructor
     * @returns {boolean} isFormValid state
     */
    public validateFromSchemaAsync(): boolean
    {
        for (const field in this.$initial)
        {
            this.validateFieldFromSchemaAsync(field);
        }

        return this.$errors.any();
    }

    public validate(rules: (schema: ValidationSchema) => Record<string, any>): void
    {
        const schema = new ValidationSchema();
        const ruleset = rules(schema);
        const validator = schema.object(ruleset);

        try
        {
            validator.validateSync(this.data(), {
                abortEarly: false,
                stripUnknown: true
            });
        }
        catch (ex)
        {
            const validationException: Validator.ValidationError = ex as Validator.ValidationError;
            const exceptions = validationException.inner.length > 0 ? validationException.inner : [validationException];
            const errors = Object.assign({}, ...exceptions.map((err: Validator.ValidationError) => ({
                [err.path.replace('["', '').replace('"]', '')]: err.errors
            })));

            throw (() =>
            {
                return {
                    code: 422,
                    message: null as string,
                    data: { errors: errors },
                    inner: null as any
                };
            })();
        }
    }

    /**
     * @description Validate form field live
     * @description Use this method if you want to use live validation in form
     * @requires ValidationSchema You need to pass validation schema to constructor before
     * @requires Name You need to pass name property to input
     * @param {Event} event form tag @focusout event handler
     */
    public handleFocusOut(event: Record<string, any>): void
    {
        const field: string = event?.target?.name;

        if (field)
        {
            this.validateFieldFromSchemaAsync(field);
        }
    }

    /**
     * @description Clear form field errors live
     * @description Use this method if you want to use live validation in form
     * @requires ValidationSchema You need to pass validation schema to constructor before
     * @requires Name You need to pass name property to input
     * @param {Event} event form tag @focusin event handler
     */
    public handleFocusIn(event: Record<string, any>): void
    {
        const field: string = event?.target?.name;

        if (field)
        {
            this.$errors.clear(field);
        }
    }
}

export { Validator, ValidationSchematic, FormError };

export type FormType<T> = FormHelper<T> & T;

export class Form
{
    public static create<T>(data: T, validationSchema?: ValidationSchematic): FormType<T>
    {
        return new FormHelper<T>(data, validationSchema) as FormType<T>;
    }
}
