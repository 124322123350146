import { axios } from '@/plugins/axios';
import assign from 'lodash/assign';
import { DateTime } from 'luxon';
import { Resource, Statement } from '@/helpers/Interfaces';
import { Resource as FileResource } from '@/modules/core/files/services/StorageService';
import { MenuNode } from '@/views/partials/navigation/MenuList.vue';

/**
 * NotificationsService
 */
export default class NotificationsService
{
    /**
     * @returns Promise<ChannelType[]>
     */
    public static async getChannels(): Promise<ChannelType[]>
    {
        return (await axios.get<ChannelType[]>('admin/notifications/channels')).data;
    }

    /**
     * @returns Promise<NotificationType[]>
     */
    public static async getNotifications(): Promise<NotificationType[]>
    {
        return (await axios.get<NotificationType[]>('admin/notifications/types')).data;
    }

    /**
     * @returns Promise<NotificationType[]>
     */
    public static async getUserNotifications(): Promise<NotificationType[]>
    {
        return (await axios.get<NotificationType[]>('admin/notifications/user-types')).data;
    }

    /**
     * @returns Promise<MessageModel[]>
    */
    public static async getMessages(pager: any, filter: MessageFilters)
    {
        const data = pager.data();

        assign(data, {
            ...filter
        });

        const result = await axios.get<MessageModel[]>('admin/notifications/messages', {
            params: data
        });

        return result.data;
    }

    /**
     * @returns Promise<UserNotiticationsFormModel>
     */
    public static async getUserSettings(): Promise<UserNotiticationsFormModel>
    {
        return (await axios.get<UserNotiticationsFormModel>('admin/notifications/settings')).data;
    }

    /**
     * @param model UserNotiticationsFormModel
     *
     * @returns Promise<Statement>
     */
    public static async saveUserSettings(model: UserNotiticationsFormModel): Promise<Statement>
    {
        return (await axios.put<Statement>(`admin/notifications/settings`, model)).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Resource<NotificationsOptions>>
     */
    public static async getGlobalSettings(): Promise<Resource<NotificationsOptions>>
    {
        return (await axios.get<Resource<NotificationsOptions>>('admin/notifications/globalsettings')).data;
    }

    /**
      * @param model NotificationsOptions
      *
      * @returns Promise<Statement>
      */
    public static async saveGlobalSettings(model: NotificationsOptions): Promise<Statement>
    {
        return (await axios.put<Statement>('admin/notifications/globalsettings', model)).data;
    }

    /**
     * @returns Promise<Statement>
     */
    public static async testSettings(): Promise<Statement>
    {
        return (await axios.post<Statement>(`admin/notifications/test`)).data;
    }

    public static async center(id: string): Promise<RedirectModel>
    {
        return (await axios.get<RedirectModel>(`admin/notifications/center/${id}?redirect=false`)).data;
    }
}

interface MessageFilters
{
    level?: Level,
    isRead?: boolean,
    search?: string,
    dateFromUtc?: string,
    dateDueUtc?: string
}

export enum Level {
    success = 'success',
    warning = 'warning',
    error = 'error'
}

export interface LevelNode extends MenuNode {
    id: Level;
}

export enum Status {
    unreaded = 'unreaded',
    readed = 'readed'
}
export interface StatusNode extends MenuNode {
    id: Status;
}

export interface ChannelType
{
    value: string;
    name: string;
    richText: boolean;
    required: boolean;
    enabled: boolean;
}

export interface NotificationType
{
    value: string;
    name: string;
    module: string;
    description: string;
    defaultSubject: string;
    defaultMessage: string;
    enabled: boolean;
    required: boolean;
    channels: string[];
    keys: KeyModel[];
    isConfigurableByUser: boolean;
}

export interface KeyModel
{
    key: string;
    description: string;
}

export interface UserNotiticationsFormModel
{
    channels: string[];
    notifications: string[];
}

export interface NotificationsOptions
{
    enabled: boolean;
    channels: string[];
    footer: Record<string, string>;
    logo?: FileResource;
    logoUrl: string;
}

export interface FilterModel
{
    dateFromUtc?: DateTime;
    dateDueUtc?: DateTime;
    isRead?: boolean;
    search: string;
}

export interface MessageModel
{
    id: number;
    uid: string;
    dateCreatedUtc: DateTime;
    payload: any;
    isRead: boolean;
}

export interface RedirectModel
{
    uri: string;
    path: string
}