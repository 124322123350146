import { Plugin } from 'vue';
import DocumentNumeration from './DocumentNumeration.vue';
import ProcessOptionConfiguration from './ProcessOptionConfiguration.vue';
import CollectionSplitConfiguration from './CollectionSplitConfiguration.vue';
import PackerStationConfiguration from './PackerStationConfiguration.vue';


const AsistonPlugin: Plugin =
{
    install(app, options)
    {
        app.component('DocumentNumeration', DocumentNumeration);
        app.component('ProcessOptionConfiguration', ProcessOptionConfiguration);
        app.component('CollectionSplitConfiguration', CollectionSplitConfiguration);
        app.component('PackerStationConfiguration', PackerStationConfiguration);
    }
};

export default AsistonPlugin;
