<template>
    <div class="dashboard">
        <div class="py-2 px-3 mt-n3 mx-n3 dashboard-header">
            <div class="d-flex align-items-center flex-wrap gap-2">
                <ActionButton
                    v-if="isLayoutChanged"
                    variant="success"
                    icon="fas fa-check-circle"
                    class="m-0"
                    type="button"
                    :text="$t('[[[Zapisz ustawienia widgetów]]]')"
                    @click="saveSettings"
                />
                <ActionButton
                    v-if="widgets.length"
                    variant="light"
                    :icon="!isEditMode ? 'fas fa-pen-to-square' : 'fas fa-check-circle'"
                    :text="toogleEditText"
                    class="m-0"
                    :class="{ active: isEditMode }"
                    @click.stop="toogleEdit"
                />
                <ActionButton
                    v-if="isEditMode && widgets.length"
                    variant="primary"
                    icon="fas fa-rectangle-history-circle-plus"
                    :text="$t('[[[Dodaj widget]]]')"
                    @click.stop="openMenu"
                />
            </div>
        </div>
        <Transition name="fade">
            <div v-if="isLoaded" class="dashboard-container d-flex flex-column flex-fill">
                <div class="grid-stack h-100">
                    <BaseWidget
                        v-for="widget in widgets"
                        :key="widget.publicId"
                        :widget="widget"
                        :is-edit-mode="isEditMode"
                        @delete-widget="deleteWidget(widget.publicId)"
                    />
                </div>
                <div
                    v-if="!widgets.length"
                    class="dashboard-empty flex-1 d-flex align text-center flex-column justify-content-center align-items-center"
                >
                    <i class="dashboard-icon fa-duotone fa-grid-horizontal"></i>
                    <h2 class="dashboard-h2 dashboard-h2-margin-bottom">
                        {{ $t("[[[Ten dashboard nie zawiera jeszcze żadnych widgetów]]]") }}
                    </h2>
                    <p class="dashboard-p">
                        {{ $t("[[[Dodaj jeden lub więcej widgetów, aby uzyskać wgląd w postępy swojego zespołu.]]]") }}
                    </p>
                    <ActionButton
                        variant="primary"
                        icon="fas fa-rectangle-history-circle-plus"
                        class="dashboard-button"
                        :text="$t('[[[Dodaj widget]]]')"
                        @click.stop="openMenu"
                    />
                </div>
            </div>
            <div v-else class="position-absolute top-50 start-50 translate-middle">
                <Loader />
            </div>
        </Transition>
        <Transition name="slide">
            <MenuWidget
                v-show="isMenuWidget"
                :available-widgets="filtredAvailableWidgets"
                @get-available-widgets="getAvailableWidgets"
                @add="addWidget"
                @close="isMenuWidget = false"
            />
        </Transition>
        <WidgetSettingsModal @update-widget="updateWidget" />
    </div>
</template>

<script lang="ts" setup>
import { nextTick, onMounted, onUnmounted, ref, watch, computed } from "vue";
import HomeService, { Widget, APIWidget, FormFilter, WidgetModel, WidgetTypeEnum } from "@/modules/core/home/services/HomeService";
import { cloneDeep, isEqual } from "lodash";
import { useAlerts } from "@/plugins/alerts";
import { useLocalization } from "@/plugins/localization";
import { useEvents } from '@/plugins/events';
import { useLogging } from "@/plugins/logging";

import { GridStack, GridStackOptions, GridItemHTMLElement, GridStackNode } from "gridstack";
import "gridstack/dist/gridstack.css";

import Loader from "@/components/common/Loader.vue";
import BaseWidget from "@/modules/core/home/components/BaseWidget.vue";
import MenuWidget from "@/modules/core/home/components/MenuWidget.vue";
import WidgetSettingsModal from "@/modules/core/home/components/modals/WidgetSettingsModal.vue";

const { $events } = useEvents();
const { $alert } = useAlerts();
const { $log } = useLogging();
const { $t } = useLocalization();


let grid: GridStack | null = null;
const CELL_HEIGHT = 135;

const isMenuWidget = ref(false);
const isMounted = ref(false);
const isLayoutChanged = ref(false);
const isEditMode = ref(false);
const isLoaded = ref(false);
const widgets = ref<Array<Widget>>([]);
const availableWidgets = ref<Array<APIWidget>>([]);
const copyWidgets = ref<Array<Widget>>([]);
const publicIdSettings = ref<string>("");
const widgetTypeEnum = WidgetTypeEnum;

const toogleEditText = computed(() =>
{
    return `${!isEditMode.value ? $t("[[[Edytuj]]]") : $t("[[[Zakończ edycje]]]")}`;
});

const filtredAvailableWidgets = computed(() =>
{
    return availableWidgets.value?.filter(
        (availableWidget) => widgets.value.findIndex((widget) => widget.publicId === availableWidget.publicId) === -1 && availableWidget.name != 'Aktywne zastępstwa'
    );
});

const toogleEdit = ({ value }: { value?: boolean }): void =>
{
    isEditMode.value = value ?? !isEditMode.value;
    isEditMode.value && openMenu();
    grid.setStatic(!isEditMode.value);
};

const openMenu = (): void =>
{
    isMenuWidget.value = true;

    if (!isEditMode.value) toogleEdit({ value: true });
};

const getAvailableWidgets = async (filter?: FormFilter): Promise<void> =>
{
    try
    {
        availableWidgets.value = await HomeService.getAvailableWidgets(filter);
    }
    catch (ex: any)
    {
        if (ex.code == 400) $alert.error(ex.message);
        else $log.debug(ex);
    }
};

const getWidgets = async (): Promise<void> =>
{
    try
    {
        isLoaded.value = false;

        const widgetsGet = await HomeService.getSettings();

        publicIdSettings.value = widgetsGet.publicId;

        if (!widgetsGet?.config) return;

        for (const item of widgetsGet.config)
        {
            const widgetData = (await HomeService.getWidgetUserConfig(item.publicId));
            const singleWidget: Widget = {
                publicId: item.publicId,
                name: widgetData?.name,
                w: +widgetData?.width,
                h: +widgetData?.height,
                x: item.x,
                y: item.y,
                config: widgetData,
            };

            widgets.value.push(singleWidget);
        }

        copyWidgets.value = cloneDeep(widgets.value);
    }
    catch (ex: any)
    {
        if (ex.code == 400) $alert.error($t("[[[Nie udało się pobrać widgetu/ów]]]"));
        else $log.debug(ex);
    }
    finally
    {
        isLoaded.value = true;
    }
};

const addWidget = (availableWidget: APIWidget, x?: number, y?: number): void =>
{
    if (widgets.value.some((widget: Widget) => widget.publicId === availableWidget.publicId))
    {
        $alert.warning($t("[[[Nie możesz dodać tego samego widgetu dwa razy]]]"));

        return;
    }

    const widget: Widget = {
        publicId: availableWidget.publicId,
        name: availableWidget.name,
        w: +availableWidget.width,
        h: +availableWidget.height,
        config: availableWidget,
        x: x ?? 0,
        y: y ?? 0,
    };

    widgets.value.push(widget);

    nextTick(() =>
    {
        grid?.makeWidget(`#x${widget.publicId}`);
    });
};

const updateWidget = async (data: WidgetModel, publicId: string): Promise<void> =>
{
    const widget = widgets.value.find((w: Widget) => w.publicId === publicId);

    if (!widget) return;

    widget.w = +data.width;
    widget.h = +data.height;
    widget.config.sourceConfig = typeof data.sourceConfig === "string" ? JSON.parse(data.sourceConfig) : null;

    grid?.update(`#x${widget.publicId}`, { h: +data.height, w: +data.width });
    getAvailableWidgets();

    if (data.type == widgetTypeEnum.Shortcuts)
    {
        resizeToContent(widget.publicId);
    }
};

const updateWidgetPosition = (_: Event, items: GridItemHTMLElement | GridStackNode | GridStackNode[]): any =>
{
    if (Array.isArray(items))
    {
        items.forEach((item) =>
        {
            const widget = widgets.value.find((w: Widget) => w.publicId === `${item.id}`.slice(1));

            if (!widget) return;

            widget.x = item.x;
            widget.y = item.y;
        });
    }
};

const deleteWidget = async (widgetID: string): Promise<void> =>
{
    grid?.removeWidget(`#x${widgetID}`, false);

    const index = widgets.value.findIndex((item: Widget) => item.publicId === widgetID);

    widgets.value.splice(index, 1);
};

const saveSettings = async (): Promise<void> =>
{
    try
    {
        const girdToSave = grid.save();

        if (Array.isArray(girdToSave))
        {
            const settings = {
                config: girdToSave.map((item) => ({
                    publicID: `${item.id}`.slice(1),
                    x: item.x,
                    y: item.y,
                    width: item.w,
                    height: item.h,
                })),
            };

            await HomeService.saveSettings(settings);
            $alert.success($t("[[[Udało się zapisać ustawienia widgetów]]]"));

            toogleEdit({ value: false });

            copyWidgets.value = cloneDeep(widgets.value);
            isLayoutChanged.value = false;
        }
    }
    catch (ex: any)
    {
        if (ex.code == 400) $alert.error(ex.message);
        else $log.debug(ex);
    }
};

const initGrid = async (): Promise<void> =>
{
    const gridConfig: GridStackOptions = {
        acceptWidgets: true,
        staticGrid: !isEditMode.value, // Disable drag
        cellHeight: CELL_HEIGHT, // Height of one column
        float: false, // Put widget whereever you want
    };

    grid = GridStack.init(gridConfig);

    await nextTick();

    setupDragIn();

    grid.on("change", (event: Event, items: GridItemHTMLElement | GridStackNode | GridStackNode[]) => updateWidgetPosition(event, items));

    grid.on("dropped", (_0, _1, newWidget) =>
    {
        nextTick(() =>
        {
            grid?.removeWidget(`#${newWidget.id}`, true);
        });

        if (availableWidgets.value != null)
        {
            const widget = availableWidgets.value.find((item) => item.publicId === `${newWidget.id}`.slice(1));

            if (widget != null)
            {
                addWidget(widget, newWidget.x, newWidget.y);
            }
        }
    });
};

const resizeToContent = (widgetPublicId: string): void =>
{
    const widgetID = `x${widgetPublicId}`;
    const widget = grid?.engine?.nodes?.find((grid) => grid.id == widgetID);

    grid.update(widget.el, { sizeToContent: true, resizeToContentParent: '.grid-stack-item-content' });

    widget.el.querySelector('.grid-stack-card').classList.remove('flex-fill', 'overflow-hidden');
    widget.el.querySelector('.grid-stack-item-content').classList.add('overflow-y-hidden');
    widget.el.querySelector('.grid-stack-item-content .card-form').classList.remove('flex-fill', 'overflow-hidden');
    widget.el.querySelector('.grid-stack-item-content .card-body').classList.remove('scroll');

    nextTick(() =>
    {
        grid.resizeToContent(widget.el);
    })
};

const setupDragIn = (): void =>
{
    GridStack.setupDragIn(".new-widget", { appendTo: "body", helper: "clone" });
};

watch(widgets.value, (newValue: Array<Widget>) =>
{
    if (!isEqual(newValue, copyWidgets.value) && isMounted.value) isLayoutChanged.value = true;
    else if (isEqual(newValue, copyWidgets.value) && isMounted.value) isLayoutChanged.value = false;
});

watch(filtredAvailableWidgets, () =>
{
    if (grid != null)
    {
        nextTick(() =>
        {
            setupDragIn();
        });
    }
});

onMounted(async () =>
{
    getAvailableWidgets();
    await getWidgets();
    isMounted.value = true;
    initGrid();
    $events.$on('gridstack::resize-widget', resizeToContent);
});

onUnmounted(() =>
{
    grid.destroy();
    $events.$off('gridstack::resize-widget', resizeToContent);
});
</script>

<style lang="scss" scoped>
.dashboard {
    display: flex;
    flex-direction: column;
    height: 100%;

    &-header {
        min-height: 49.5px;
    }

    &-h2 {
        font-size: 1.5rem;
        font-weight: 600;
        margin: 0;

        &-margin-bottom {
            margin-bottom: 10px;
        }
    }

    &-p {
        margin-bottom: 10px;
    }

    &-container {
        margin: -10px;
    }

    &-icon {
        font-size: 9rem;
        margin-bottom: 3px;
        color: #9ea4ac;
    }

    &-empty {
        padding: 3.5rem 1rem;
        position: absolute;
        left: 50%;
        pointer-events: none;
        transform: translateX(-50%);
        width: 100%;
    }

    &-button {
        pointer-events: all;
    }
}
</style>
