<template>
    <div class="grid-stack-item" v-bind="gridStackAttributes">
        <div class="grid-stack-item-content" :class="{ move: isEditMode }">
            <DataCard class="overflow-hidden grid-stack-card" :scroll="true" :overflow="true">
                <template #header>
                    <div class="d-flex justify-content-between gap-2 align-items-center">
                        <h5 class="m-0">{{ widget.name }}</h5>
                        <div class="d-flex align-items-center gap-2">
                            <PortalTarget :name="`base-widget-header-${widget.publicId}`" />
                            <IdeoDropdown variant="light" ref="ideo-dropdown" :text="$t('[[[Akcje]]]')" dropleft no-caret class="options">
                                <template #button-content>
                                    <i class="fal fa-ellipsis-v-alt"></i>
                                </template>
                                <template #default>
                                    <IdeoDropdownItemButton @click="changeSettings">
                                        <i class="icon far fa-gear"></i>
                                        <span>{{ $t("[[[Ustawienia]]]") }}</span>
                                    </IdeoDropdownItemButton>
                                    <IdeoDropdownItemButton @click="deleteWidget">
                                        <i class="icon far fa-trash-alt"></i> {{ $t("[[[Usuń]]]") }}
                                    </IdeoDropdownItemButton>
                                </template>
                            </IdeoDropdown>
                        </div>
                    </div>
                </template>
                <template #default>
                    <template v-if="getWidgetComponent(widget.config)">
                        <component :is="getWidgetComponent(widget.config)" :key="widget.config.sourceConfig" :public-id="widget.publicId" :config="widget.config" :height="widget.h" :width="widget.w"></component>
                        <div class="d-flex align-items-center gap-2 widget-footer">
                            <PortalTarget :name="`base-widget-footer-${widget.publicId}`" />
                        </div>
                    </template>
                    <span v-else>{{ $t("[[[Nie znaleziono pasującego widgetu]]]") }}</span>
                </template>
            </DataCard>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Emit, Prop } from "@/helpers/Decorators";
import { GridItemAttributes, Widget, WidgetModel, WidgetTypeEnum } from "@/modules/core/home/services/HomeService";
import ActiveSubstitutions from "@/modules/core/home/components/widgets/ActiveSubstitutions.vue";
import UnfinishedTransfers from "@/modules/core/home/components/widgets/UnfinishedTransfers.vue";
import UnfinishedIssues from "@/modules/core/home/components/widgets/UnfinishedIssues.vue";
import UsersOnline from "@/modules/core/home/components/widgets/UsersOnline.vue";
import PiecesIssuedToCouriers from "@/modules/core/home/components/widgets/PiecesIssuedToCouriers.vue";
import ActiveInventories from "@/modules/core/home/components/widgets/ActiveInventories.vue";
import Inventories from "@/modules/core/home/components/widgets/Inventories.vue";
import ReceiptStatuses from "@/modules/core/home/components/widgets/ReceiptStatuses.vue";
import ReceiptsProductsSum from "@/modules/core/home/components/widgets/ReceiptsProductsSum.vue";
import TodaysIssues from "@/modules/core/home/components/widgets/TodaysIssues.vue";
import MostIssuedProducts from "@/modules/core/home/components/widgets/MostIssuedProducts.vue";
import TransfersByStatus from "@/modules/core/home/components/widgets/TransfersByStatus.vue";
import PiecesIssuedByCouriers from "@/modules/core/home/components/widgets/PiecesIssuedByCouriers.vue";
import IssuesByStatus from "@/modules/core/home/components/widgets/IssuesByStatus.vue";
import IssuesProductsSum from "@/modules/core/home/components/widgets/IssuesProductsSum.vue";
import Shortcuts from "@/modules/core/home/components/widgets/shortcuts/Shortcuts.vue";
import AiUsage from '@/modules/core/home/components/widgets/AiUsage.vue';

@Options({
    name: "BaseWidget",
    emits: ["delete-widget"],
    components: {
        ActiveSubstitutions,
        UnfinishedTransfers,
        UnfinishedIssues,
        UsersOnline,
        PiecesIssuedToCouriers,
        ActiveInventories,
        Inventories,
        ReceiptStatuses,
        ReceiptsProductsSum,
        TodaysIssues,
        MostIssuedProducts,
        TransfersByStatus,
        PiecesIssuedByCouriers,
        IssuesByStatus,
        IssuesProductsSum,
        Shortcuts,
        AiUsage
    }
})
export default class BaseWidget extends Vue
{
    @Prop({ default: () => ({}) })
    public widget: Widget;

    @Prop({ default: false })
    public isEditMode: boolean;

    public get gridStackAttributes(): GridItemAttributes
    {
        return {
            id: `x${this.widget.publicId}`,
            "gs-id": `x${this.widget.publicId}`,
            "gs-x": this.widget.x,
            "gs-y": this.widget.y,
            "gs-w": this.widget.w,
            "gs-h": this.widget.h,
            "gs-no-resize": true,
        };
    }

    public getWidgetComponent(widget: WidgetModel): string
    {
        switch (widget.type)
        {
            case WidgetTypeEnum.UnfinishedTransfers:
                return "UnfinishedTransfers";
            case WidgetTypeEnum.UnfinishedIssues:
                return "UnfinishedIssues";
            case WidgetTypeEnum.UsersOnline:
                return "UsersOnline";
            case WidgetTypeEnum.PiecesIssuedToCouriers:
                return "PiecesIssuedToCouriers";
            case WidgetTypeEnum.ActiveInventories:
                return "ActiveInventories";
            case WidgetTypeEnum.Inventories:
                return "Inventories";
            case WidgetTypeEnum.ReceiptStatuses:
                return "ReceiptStatuses";
            case WidgetTypeEnum.ReceiptsProductsSum:
                return "ReceiptsProductsSum";
            case WidgetTypeEnum.TodaysIssues:
                return "TodaysIssues";
            case WidgetTypeEnum.MostIssuedProducts:
                return "MostIssuedProducts";
            case WidgetTypeEnum.IssuesByStatus:
                return "IssuesByStatus";
            case WidgetTypeEnum.TransfersByStatus:
                return "TransfersByStatus";
            case WidgetTypeEnum.PiecesIssuedByCouriers:
                return "PiecesIssuedByCouriers";
            case WidgetTypeEnum.IssuesProductsSum:
                return "IssuesProductsSum";
            case WidgetTypeEnum.Shortcuts:
                return "Shortcuts";
        }
    }

    public changeSettings(): void
    {
        this.$events.$emit("widget-settings-modal", { publicId: this.widget.publicId });
    }

    @Emit("delete-widget")
    public deleteWidget(): void
    {
    }
}
</script>
<style lang="scss" scoped>
.grid-stack-item-content {
    display: flex;
    flex-direction: column;
    position: relative;

    &.move {
        cursor: move;
    }
}

.widget-footer:has(*) {
    padding: 1rem 0 0.5rem 0; 
}
</style>
