import Lightbox from 'bs5-lightbox';
import { Directive, Plugin } from 'vue';
import { useMouseClick } from '@/helpers/Utils';
// import iFrameResize from 'iframe-resizer/js/iframeResizer';

const DirectivesPlugin: Plugin =
{
    install(app, options)
    {
        app.directive('slugify', {
            beforeMount: (el: any, binding: any, vnode: any) =>
            {
                const search = [" ", "ę", "ó", "ą", "ś", "ł", "ż", "ź", "ć", "ń"];
                const replace = ["-", "e", "o", "a", "s", "l", "z", "z", "c", "n"];

                el.slugify = function(value: string)
                {
                    let text = value.toLowerCase();

                    for (let i = 0; i < search.length; i++)
                    {
                        const re = new RegExp(search[i], "g");

                        text = text.replace(re, replace[i]);
                    }

                    return text.replace(/[^a-z0-9-]/g, "");
                };

                el.addEventListener('blur', function(e: any)
                {
                    e.target.value = el.slugify(e.target.value);
                    e.target.dispatchEvent(new Event('input', { bubbles: true }));
                });
            }
        } as Directive);

        app.directive('focus', {
            mounted: function(el: HTMLElement)
            {
                el.dataset.focus = 'true';
                el.focus();
            }
        } as Directive);

        app.directive('lightbox', {
            mounted: function(el: any, binding: any)
            {

                if (binding.value?.inactive)
                    return;

                const lightbox = new Lightbox(el, {});
                const dbClick = binding.value?.dbClick ?? false;

                lightbox.modal._backdrop._config.className = 'modal-backdrop lightbox-backdrop';

                const mouseClick = useMouseClick();

                el.clickListener = (e: any) =>
                {
                    e.stopPropagation();

                    if (el.getAttribute('data-src'))
                    {
                        mouseClick(
                            () => !dbClick && lightbox.show(),
                            () => dbClick && lightbox.show()
                        );
                    }
                };

                el.addEventListener('click', el.clickListener);
            },
            beforeUnmount: function(el: any)
            {
                el.removeEventListener('click', el.clickListener);
            }
        });

        app.directive('validate-step', {
            mounted(el, binding)
            {
                const step = parseFloat(binding.value) || 1;

                el.addEventListener('keydown', (event: KeyboardEvent) =>
                {
                    if (step % 1 !== 0) return;

                    const keyCode = event.key;

                    if (keyCode === '.' || keyCode === ',')
                    {
                        event.preventDefault();
                    }
                });

                el.addEventListener('input', (ev: InputEvent) =>
                {
                    const elementToCheck = typeof el.checkValidity == 'function' ? el : el.querySelector('input');

                    if (!elementToCheck.checkValidity()) elementToCheck.reportValidity();

                    // uncomment if needed to correct mismatch
                    // const inputValue = parseFloat(el.value.replace(',', '.'));
                    // const stepMismatch = (inputValue % step !== 0);

                    // if (stepMismatch && !isNaN(inputValue) && ev.data !== ',' && ev.data != null)
                    // {
                    //     el.value = Number(inputValue / step) * step;
                    // }
                });
            },
        } as Directive);
    }
};

export default DirectivesPlugin;
