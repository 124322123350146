import { $t } from '@/plugins/localization';
import { Option } from './Interfaces';

enum PublicationEnum {
    Draft = 'Draft',
    Finished = 'Finished',
    Rejected = 'Rejected',
    Accepted = 'Accepted'
}

const PublicationOptions = (): Option[] => ([
    { value: PublicationEnum.Draft, text: $t('[[[Szkic]]]'), icon: 'fas fa-pen-circle text-info' },
    { value: PublicationEnum.Finished, text: $t('[[[Do akceptacji]]]'), icon: 'fas fa-clock text-warning' },
    { value: PublicationEnum.Rejected, text: $t('[[[Zwrócony]]]'), icon: 'fas fa-times-circle text-danger' },
    { value: PublicationEnum.Accepted, text: $t('[[[Zaakceptowany]]]'), icon: 'fas fa-check-circle text-success' }
]);

const AllPublicationOptions = (): Option[] => (
    [ { value: null, text: $t('[[[Wszystkie]]]') } ].concat(PublicationOptions())
);

const PublicationName = (value: PublicationEnum): string => PublicationOptions().find(p => p.value === value)?.text || PublicationEnum[value];
const PublicationIcon = (value: PublicationEnum): string => PublicationOptions().find(p => p.value === value)?.icon || '';
const PublicationStyle = (classTemplate: string, status: PublicationEnum): Record<string, boolean> =>
{
    const classes: Record<string, boolean> = {};
    const re = new RegExp('\\{0\\}', 'g');

    classes[classTemplate.replace(re, 'info')] = status === PublicationEnum.Draft;
    classes[classTemplate.replace(re, 'warning')] = status === PublicationEnum.Finished;
    classes[classTemplate.replace(re, 'danger')] = status === PublicationEnum.Rejected;
    classes[classTemplate.replace(re, 'success')] = status === PublicationEnum.Accepted;

    return classes;
};

export {
    PublicationEnum,
    PublicationOptions,
    PublicationName,
    PublicationStyle,
    PublicationIcon,
    AllPublicationOptions
};

// ----------------------------------------------------------------------------------------------------

enum EntityState {
    Deleted = 'Deleted',
    Modified = 'Modified',
    Added = 'Added',
    Trashed = 'Trashed',
    Recovered = 'Recovered'
}

const EntityStateOptions = (): Option[] => ([
    { value: EntityState.Deleted, text: $t('[[[Usunięcie]]]') },
    { value: EntityState.Modified, text: $t('[[[Modyfikacja]]]') },
    { value: EntityState.Added, text: $t('[[[Dodanie]]]') },
    { value: EntityState.Trashed, text: $t('[[[Kosz]]]') },
    { value: EntityState.Recovered, text: $t('[[[Przywrócenie]]]') }
]);

const EntityStateName = (value: EntityState): string => EntityStateOptions().find(p => p.value === value)?.text || EntityState[value];

export {
    EntityState,
    EntityStateOptions,
    EntityStateName
};

// ----------------------------------------------------------------------------------------------------

enum AccountType {
    Local = 'Local',
    Ldap = 'Ldap'
}

export {
    AccountType
};

// ----------------------------------------------------------------------------------------------------

enum DictionaryType {
    CoreUsers = 'CoreUsers',
    CoreRoles = 'CoreRoles',
    CoreTeams = 'CoreTeams',

    // CoreJobPositions = 'CoreJobPositions',
    // CoreCountries = 'CoreCountries',
    // CoreCities = 'CoreCities',
    // CoreProvinces = 'CoreProvinces',
    // ContactsEventTypes = 'ContactsEventTypes',
    // CalendarResourceCategories = 'CalendarResourceCategories'
}

const DictionaryTypeOptions = (): Option[] => ([
    { value: DictionaryType.CoreUsers, text: $t('[[[Administracja - Użytkownicy]]]') },
    { value: DictionaryType.CoreRoles, text: $t('[[[Administracja - Role]]]') },
    { value: DictionaryType.CoreTeams, text: $t('[[[Administracja - Zespoły]]]') }

    // { value: DictionaryType.CoreJobPositions, text: $t('[[[Administracja - Stanowiska pracy]]]') },
    // { value: DictionaryType.CoreCountries, text: $t('[[[Administracja - Kraje]]]') },
    // { value: DictionaryType.CoreCities, text: $t('[[[Administracja - Miasta]]]') },
    // { value: DictionaryType.CoreProvinces, text: $t('[[[Administracja - Województwa]]]') },
    // { value: DictionaryType.ContactsEventTypes, text: $t('[[[Kontakty - Typ działania]]]') },
    // { value: DictionaryType.CalendarResourceCategories, text: $t('[[[Kalendarz - Kategorie zasobów]]]') }
]);

const DictionaryTypeName = (value: DictionaryType): string => DictionaryTypeOptions().find(p => p.value === value)?.text || DictionaryType[value];

export {
    DictionaryType,
    DictionaryTypeOptions,
    DictionaryTypeName
};

// ----------------------------------------------------------------------------------------------------

enum QueueStage {
    DISPATCH = 100,
    QUEUED = 200,
    DELAYED = 300,
    RETRY = 400,
    REJECTED = 500,
    PROCESSING = 600,
    SUCCEEDED = 700,
    FAILED = 800
}

const QueueStageOptions = (): Option<QueueStage>[] => ([
    { value: QueueStage.DISPATCH, text: $t('[[[Wysłane]]]') },
    { value: QueueStage.QUEUED, text: $t('[[[Zakolejkowane]]]'), icon: 'fad fa-inbox-in text-info' },
    { value: QueueStage.DELAYED, text: $t('[[[Zaplanowane]]]'), icon: 'fad fa-clock text-info' },
    { value: QueueStage.RETRY, text: $t('[[[Powtórzone]]]') },
    { value: QueueStage.REJECTED, text: $t('[[[Odrzucone]]]') },
    { value: QueueStage.PROCESSING, text: $t('[[[Przetwarzane]]]'), icon: 'fad fa-gears text-warning' },
    { value: QueueStage.SUCCEEDED, text: $t('[[[Zakończone]]]'), icon: 'fad fa-check text-success' },
    { value: QueueStage.FAILED, text: $t('[[[Błędne]]]'), icon: 'fad fa-triangle-exclamation text-danger' }
]);

const QueueStageName = (value: QueueStage): string => QueueStageOptions().find(p => p.value === value)?.text || QueueStage[value];

export {
    QueueStage,
    QueueStageOptions,
    QueueStageName
};

// ----------------------------------------------------------------------------------------------------

enum JobStage {
    QUEUED = 210,
    PROCESSING = 610,
    SUCCEEDED = 620,
    FAILED = 630
}

const JobStageOptions = (): Option<JobStage>[] => ([
    { value: JobStage.QUEUED, text: $t('[[[Zakolejkowane]]]'), icon: 'fad fa-inbox-in text-info' },
    { value: JobStage.PROCESSING, text: $t('[[[Przetwarzane]]]'), icon: 'fad fa-gears text-warning' },
    { value: JobStage.SUCCEEDED, text: $t('[[[Zakończone]]]'), icon: 'fad fa-check text-success' },
    { value: JobStage.FAILED, text: $t('[[[Błędne]]]'), icon: 'fad fa-triangle-exclamation text-danger' }
]);

const JobStageName = (value: JobStage): string => JobStageOptions().find(p => p.value === value)?.text || JobStage[value];

export {
    JobStage,
    JobStageOptions,
    JobStageName
};

// ----------------------------------------------------------------------------------------------------

enum CodeConfigurationType {
    Zone = 0,
    Row = 1,
    Rack = 2,
    Level = 3,
    Localization = 4
}

const CodeConfigurationTypeOptions = (): Option<CodeConfigurationType>[] => ([
    { value: CodeConfigurationType.Zone, text: $t('[[[Strefa]]]') },
    { value: CodeConfigurationType.Row, text: $t('[[[Rząd]]]') },
    { value: CodeConfigurationType.Rack, text: $t('[[[Przęsło]]]') },
    { value: CodeConfigurationType.Level, text: $t('[[[Poziom]]]') },
    { value: CodeConfigurationType.Localization, text: $t('[[[Lokalizacja]]]') }
]);

const CodeConfigurationTypeName = (value: CodeConfigurationType): string => CodeConfigurationTypeOptions().find(p => p.value === value)?.text || CodeConfigurationType[value];

export {
    CodeConfigurationType,
    CodeConfigurationTypeOptions,
    CodeConfigurationTypeName
};

// ----------------------------------------------------------------------------------------------------

enum WarehouseLocalizationsType {
    Rack = 'cfa34703-0501-4996-ae27-383d7e725bf1',
    Level = '3296becf-8d5d-4db2-a54a-905bb325845d',
    Localization = '93d67975-22f0-47b7-ba2e-3e834c837136'
}

const WarehouseLocalizationsTypeOptions = (): Option<WarehouseLocalizationsType>[] => ([
    { value: WarehouseLocalizationsType.Rack, text: $t('[[[Przęsło]]]') },
    { value: WarehouseLocalizationsType.Level, text: $t('[[[Poziom]]]') },
    { value: WarehouseLocalizationsType.Localization, text: $t('[[[Lokalizacja]]]') }
]);

const WarehouseLocalizationsTypeName = (value: WarehouseLocalizationsType): string => WarehouseLocalizationsTypeOptions().find(p => p.value === value)?.text || WarehouseLocalizationsType[value];

export {
    WarehouseLocalizationsType,
    WarehouseLocalizationsTypeOptions,
    WarehouseLocalizationsTypeName
};

// ----------------------------------------------------------------------------------------------------

enum RowDirectionType {
    LeftToRight = 'LeftToRight',
    RightToLeft = 'RightToLeft'
}

const RowDirectionTypeOptions = (): Option<RowDirectionType>[] => ([
    { value: RowDirectionType.LeftToRight, text: $t('[[[Od lewej do prawej]]]') },
    { value: RowDirectionType.RightToLeft, text: $t('[[[Od prawej do lewej]]]') }
]);

export {
    RowDirectionType,
    RowDirectionTypeOptions
};
// ----------------------------------------------------------------------------------------------------

interface ReceiptDocumentOptionModel {
    value: ReceiptDocumentStatusType
    color: string
}

interface DirectReceiptDocumentOptionModel {
    value: DirectReceiptDocumentStatusType
    color: string
}

interface DirectIssueDocumentOptionModel {
    value: DirectIssueDocumentStatusType
    color: string
}

interface IssueCouriersDocumentOptionModel {
    value: IssueCouriersDocumentStatusType
    color: string
}

interface QualityControlDocumentOptionModel {
    value: QualityControlDocumentStatusType
    color: string
}

enum ReceiptDocumentStatusType {
    created = 'BCBFCE2D-CB15-4ECF-A198-A5A50599A111',
    in_progress = 'F1B028F7-6C7B-4C84-B58E-D8D81CB5FFA3',
    end_acceptation = 'D1AEE85E-A6CD-4445-91DE-E27D50AC8065',
    end = 'CDF83CE8-4B8A-42F0-92D5-0EC60DF184E3',
    cancel = 'BAD12B33-E010-4793-9194-479DC03175F1',
    in_queue = 'AB08FA8D-6E93-41B2-A4C9-17DB153D3C6D',
    waiting_for_receipt = '7C1C835C-4C8A-416B-8370-BDFD98A207D6',
    interrupted = 'B008537D-7CFA-42CF-8FAF-9D9CC42EE106',
    returned = '98CD32CA-ADB6-4A10-83C1-43CE246ABA0A'
}

enum DirectReceiptDocumentStatusType {
    created = '40468195-928F-49F0-9666-14062131273A',
    in_progress = '62217C75-0341-42F7-95C4-B350A8E9F7DC',
    end = '4a8137ec-90f4-4d7c-8066-8d006eca44f4',
    end_acceptation = '2BF6CE3D-30C8-476F-9519-637560D82954',
    cancel = 'D6008BA9-A7EC-4272-BD62-0F92232DA408',
    returned = '59FC5691-EEC8-430B-A56A-659AFC15FB89',
    interrupted = '7e838b19-8cba-47dc-bdec-93920e69fe69'
}

enum IssueCouriersDocumentStatusType {
    created = 'BFBF44A8-018D-4B1A-8CC5-897AE997CF81',
    in_progress = 'F64242FB-D13E-4E1A-838B-28FACBEF850C',
    end = '81418C41-F8C5-4362-AD77-2910598F027D',
    cancel = 'F9C5CA04-0C78-41D0-A8BE-D7AFAAD9BBAA'
}

enum DirectIssueDocumentStatusType {
    created = '029e63e5-8d38-4536-9ea6-0e11fb2d479a',
    in_progress = '01f210ac-5bc7-4f68-8134-f190240e70b5',
    end = 'e32829d1-f6da-4677-81ba-91b65832004c',
    end_acceptation = '438362a2-a250-4a5c-8783-80518ea8ccd3',
    cancel = '5b7f0979-c417-49c3-9865-a5aef488c8b3',
    returned = '7f300b44-921d-45af-96fc-a1c13f707766',
    interrupted = '97f5c449-0b13-4094-b614-625975e8f4c4',
    to_cancel = '569FF604-99AF-4D1B-813A-AC1C3F95DD5B'
}

enum QualityControlDocumentStatusType {
    created = '8FD876E7-6B2B-4722-8475-E341675CFB13',
    in_progress = 'FA7B9A76-CFB4-4CB3-941E-E24BF1417F02',
    interrupted = 'f3b61c20-c881-4773-971b-b9e2c13b42f3'
}

const ReceiptDocumentStatusOptions = (): ReceiptDocumentOptionModel[] => ([
    { value: ReceiptDocumentStatusType.created, color: '#03A4DC' },
    { value: ReceiptDocumentStatusType.in_progress, color: '#F28705' },
    { value: ReceiptDocumentStatusType.end_acceptation, color: '#4DBD73' },
    { value: ReceiptDocumentStatusType.end, color: '#4DBD73' },
    { value: ReceiptDocumentStatusType.cancel, color: '#FF4255' },
    { value: ReceiptDocumentStatusType.in_queue, color: '' },
    { value: ReceiptDocumentStatusType.waiting_for_receipt, color: '#03A4DC' },
    { value: ReceiptDocumentStatusType.interrupted, color: '#FF4255' },
    { value: ReceiptDocumentStatusType.returned, color: '#FF4255' },
]);

const DirectReceiptDocumentStatusOptions = (): DirectReceiptDocumentOptionModel[] => ([
    { value: DirectReceiptDocumentStatusType.created, color: '#03A4DC' },
    { value: DirectReceiptDocumentStatusType.in_progress, color: '#F28705' },
    { value: DirectReceiptDocumentStatusType.end_acceptation, color: '#4DBD73' },
    { value: DirectReceiptDocumentStatusType.end, color: '#4DBD73' },
    { value: DirectReceiptDocumentStatusType.cancel, color: '#FF4255' },
    { value: DirectReceiptDocumentStatusType.returned, color: '#FF4255' },
    { value: DirectReceiptDocumentStatusType.interrupted, color: '#FF4255' }
]);

const IssueCourierstDocumentStatusOptions = (): IssueCouriersDocumentOptionModel[] => ([
    { value: IssueCouriersDocumentStatusType.created, color: '#03A4DC' },
    { value: IssueCouriersDocumentStatusType.in_progress, color: '#F28705' },
    { value: IssueCouriersDocumentStatusType.end, color: '#4DBD73' },
    { value: IssueCouriersDocumentStatusType.cancel, color: '#FF4255' }
]);

const DirectIssueDocumentStatusOptions = (): DirectIssueDocumentOptionModel[] => ([
    { value: DirectIssueDocumentStatusType.created, color: '#03A4DC' },
    { value: DirectIssueDocumentStatusType.in_progress, color: '#F28705' },
    { value: DirectIssueDocumentStatusType.end_acceptation, color: '#4DBD73' },
    { value: DirectIssueDocumentStatusType.end, color: '#4DBD73' },
    { value: DirectIssueDocumentStatusType.cancel, color: '#FF4255' },
    { value: DirectIssueDocumentStatusType.returned, color: '#FF4255' },
    { value: DirectIssueDocumentStatusType.interrupted, color: '#FF4255' },
    { value: DirectIssueDocumentStatusType.to_cancel, color: '#FF4255' }
]);


const QualityControlDocumentStatusOptions = (): QualityControlDocumentOptionModel[] => ([
    { value: QualityControlDocumentStatusType.created, color: '#03A4DC' },
    { value: QualityControlDocumentStatusType.in_progress, color: '#F28705' },
    { value: QualityControlDocumentStatusType.interrupted, color: '#FF4255' }
]);

const ReceiptDocumentStatusColor = (value: ReceiptDocumentStatusType): string => ReceiptDocumentStatusOptions().find(p => p.value === value)?.color || WarehouseLocalizationsType[value];

const DirectReceiptDocumentStatusColor = (value: DirectReceiptDocumentStatusType): string => DirectReceiptDocumentStatusOptions().find(p => p.value === value)?.color || WarehouseLocalizationsType[value];

const IssueCouriersDocumentStatusColor = (value: IssueCouriersDocumentStatusType): string => IssueCourierstDocumentStatusOptions().find(p => p.value === value)?.color || WarehouseLocalizationsType[value];

const DirectIssueDocumentStatusColor = (value: DirectIssueDocumentStatusType): string => DirectIssueDocumentStatusOptions().find(p => p.value === value)?.color || WarehouseLocalizationsType[value];

const QualityControlDocumentStatusColor = (value: QualityControlDocumentStatusType): string => QualityControlDocumentStatusOptions().find(p => p.value === value)?.color || WarehouseLocalizationsType[value];

export {
    ReceiptDocumentStatusType,
    ReceiptDocumentStatusOptions,
    ReceiptDocumentStatusColor,
    DirectReceiptDocumentStatusType,
    DirectReceiptDocumentStatusOptions,
    DirectReceiptDocumentStatusColor,
    DirectIssueDocumentStatusType,
    DirectIssueDocumentStatusOptions,
    DirectIssueDocumentStatusColor,
    IssueCouriersDocumentStatusType,
    IssueCourierstDocumentStatusOptions,
    IssueCouriersDocumentStatusColor,
    QualityControlDocumentStatusType,
    QualityControlDocumentStatusOptions,
    QualityControlDocumentStatusColor
};
// ----------------------------------------------------------------------------------------------------

enum ViewKindEnum {
    None = 'None',
    Trade = 'Trade',
    Warehouse = 'Warehouse',
    Creating = 'Creating',
    Courier = 'Courier',
    Collecting = 'Collecting',
    Packing = 'Packing',
    OneStageIssue = 'OneStageIssue',
    PersonalCollection = 'PersonalCollection',
    Giveback = 'Giveback'
}

enum ViewModuleEnum {
    Receipt = 'receipt',
    DirectReceipt = 'direct_receipt',
    Transfer = 'transfer',
    Issue = 'issue',
    DirectIssue = 'direct_issue',
    Inventories = 'inventories',
    PersonalCollection = 'personal_collection',
    Giveback = 'giveback',
    QualityControl = 'quality_control',
}

export {
    ViewKindEnum,
    ViewModuleEnum
};

// -----------------------------------CarriersTypes-----------------------------------------------------
enum CarrierType {
    Constant = 'ef956439-f9a3-4fd8-9b5c-c8a90d4bcc02',
    Package = 'd6b78acb-3ddd-4659-b3c2-6687c8892653',
}

export {
    CarrierType
};

// -----------------------------------Rodzaje przesunięć-----------------------------------------------------
enum TransferKindEnum {
    Normal = 'Normal',
    BetweenWarehouses = 'BetweenWarehouses',
}

const TransferKindOptions = (): Option<TransferKindEnum>[] => ([
    { value: TransferKindEnum.Normal, text: $t('[[[Normalne]]]') },
    { value: TransferKindEnum.BetweenWarehouses, text: $t('[[[Międzymagazynowe]]]') }
]);

export {
    TransferKindEnum,
    TransferKindOptions
};

// -----------------------------------Statusy wiadomości integracji-----------------------------------------------------
enum IntegrationsMessageStatusEnum
    {
    Sheduled = 'Sheduled',
    Failed = 'Failed',
    Processed = 'Processed',
    Success = 'Success'
}

const IntegrationsMessageStatusEnumOptions = (): Option<IntegrationsMessageStatusEnum>[] => ([
    { value: IntegrationsMessageStatusEnum.Sheduled, text: $t('[[[Zaplanowane]]]') },
    { value: IntegrationsMessageStatusEnum.Failed, text: $t('[[[Nieudane]]]') },
    { value: IntegrationsMessageStatusEnum.Processed, text: $t('[[[W trakcie]]]') },
    { value: IntegrationsMessageStatusEnum.Success, text: $t('[[[Zakończone]]]') },
]);

export {
    IntegrationsMessageStatusEnum,
    IntegrationsMessageStatusEnumOptions
};

// -----------------------------------Typy ruchu magazynowego-----------------------------------------------------
enum StockMovementTypeEnum {
    All = 'All',
    Transfer = 'Transfer',
    Receipt = 'Receipt',
    Issue = 'Issue',
    IssueInventories = 'IssueInventories',
    ReceiptInventories = 'ReceiptInventories'
}

const StockMovementTypeOptions = (): Option<StockMovementTypeEnum>[] => ([
    { value: StockMovementTypeEnum.All, text: $t('[[[Brak wyboru]]]') },
    { value: StockMovementTypeEnum.Transfer, text: $t('[[[Przesunięcie]]]') },
    { value: StockMovementTypeEnum.Receipt, text: $t('[[[Przyjęcie]]]') },
    { value: StockMovementTypeEnum.Issue, text: $t('[[[Wydanie]]]') },
    { value: StockMovementTypeEnum.IssueInventories, text: $t('[[[Wydanie (inwentaryzacja)]]]') },
    { value: StockMovementTypeEnum.ReceiptInventories, text: $t('[[[Przyjęcie (inwentaryzacja)]]]') },
]);

const StockMovementTypeName = (value: StockMovementTypeEnum): string => StockMovementTypeOptions().find(p => p.value === value)?.text || StockMovementTypeEnum[value];

export {
    StockMovementTypeEnum,
    StockMovementTypeOptions,
    StockMovementTypeName
};

// -----------------------------------Typy etykiet-----------------------------------------------------
enum PrinterTemplateTypeEnum {
    ProductTemplateModel = 'ProductTemplateModel',
    AdditionalBarcodeTemplateModel = 'AdditionalBarcodeTemplateModel',
    CarrierTemplateModel = 'CarrierTemplateModel',
    PackageTemplateModel = 'PackageTemplateModel',
    LocalizationTemplateModel = 'LocalizationTemplateModel',
    ShipmentsLabelTemplateModel = 'ShipmentsLabelTemplateModel',
    IssueExternalDocumentTemplateModel = 'IssueExternalDocumentTemplateModel',
}

const PrinterTemplateTypeOptions = (): Option<PrinterTemplateTypeEnum>[] => ([
    { value: PrinterTemplateTypeEnum.ProductTemplateModel, text: $t('[[[Etykieta produktu]]]') },
    { value: PrinterTemplateTypeEnum.AdditionalBarcodeTemplateModel, text: $t('[[[Etykieta kodu zbiorczego]]]') },
    { value: PrinterTemplateTypeEnum.CarrierTemplateModel, text: $t('[[[Etykieta nośnika stałego]]]') },
    { value: PrinterTemplateTypeEnum.PackageTemplateModel, text: $t('[[[Etykieta opakowania]]]') },
    { value: PrinterTemplateTypeEnum.LocalizationTemplateModel, text: $t('[[[Etykieta lokalizacji]]]') },
    { value: PrinterTemplateTypeEnum.ShipmentsLabelTemplateModel, text: $t('[[[Etykieta listu przewozowego]]]') },
    { value: PrinterTemplateTypeEnum.IssueExternalDocumentTemplateModel, text: $t('[[[Zewnętrzny dokument wydania]]]') },
]);

const PrinterTemplateTypeName = (value: PrinterTemplateTypeEnum | string): string => PrinterTemplateTypeOptions().find(p => p.value === value)?.text || value;

export {
    PrinterTemplateTypeEnum,
    PrinterTemplateTypeOptions,
    PrinterTemplateTypeName
};

// -----------------------------------Walidacja umieszczania towarów na lokalizacji-----------------------------------------------------
enum ProductReceiptValidationTypeEnum {
    ByProductDimensions = 'ByProductDimensions',
    ByProductWeight = 'ByProductWeight',
    ByCarrierDimensions = 'ByCarrierDimensions',
    ByCarrierAndProduct = 'ByCarrierAndProduct',
    CarrierQuantity = 'CarrierQuantity'
}

export {
    ProductReceiptValidationTypeEnum,
};
