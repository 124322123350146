<template>
    <div>
        <list-view
            :items="items"
            :pager="pager"
            :show-header="false"
            :row-click="(item: Resource<PiecesIssuedToCouriersCountModel>) => $router.push({name:'wms-issue-couriers-details', params: { publicId: item.result.publicId }})"
            @change="loadData()"
        >
            <template #row="{ item }">
                <list-view-header :title="$t('[[[Nazwa]]]')">
                    <span class="name">
                        {{ item.result.name }}
                    </span>
                </list-view-header>
                <list-view-item
                    :title="$t('[[[Ilość]]]')"
                    sort="numOfIssuedPieces"
                    class="text-end"
                >
                    {{ $t('[[[%0 z %1|||{0}|||{1}]]]', item.result.numOfIssuedPieces.toString(), item.result.numOfAllPieces.toString()) }}
                </list-view-item>
            </template>
        </list-view>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref, reactive, nextTick, onMounted } from 'vue';
import Pager from "@/helpers/Pager";
import { Resource } from '@/helpers/Interfaces';
import { useAlerts } from '@/plugins/alerts';
import { useLocalization } from '@/plugins/localization';
import { useMixins } from '@/plugins/mixins';
import { WidgetModel } from "@/modules/core/home/services/HomeService";
import WidgetsService, { PiecesIssuedToCouriersCountModel } from "@/modules/core/common/services/WidgetsService";

interface Props
{
    config: WidgetModel
    publicId: string
}

defineOptions({
    name: 'UnfinishedTransfersWidget',
    inheritAttrs: false,
});

const props = defineProps({
  "config": { default: null },
  "publicId": { default: '' }
});

const { $alert } = useAlerts();
const { $t } = useLocalization();
const { $handleException } = useMixins();

const items = ref<Resource<PiecesIssuedToCouriersCountModel>[]>([]);
const pager = reactive<Pager>(new Pager(1, 20, 'name', 'ASC'));

onMounted(async (): Promise<void> =>
{
    await loadData();
});

const loadData = async (): Promise<void> =>
{
    items.value = [];

    try
    {
        const response = await WidgetsService.getPiecesIssuedToCouriersCount();

        items.value = response;
    }
    catch (ex)
    {
        items.value = [];

        $handleException(ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });
    }
};
</script>

<style lang="scss" scoped>
.name {
    font-weight: 600;
}
</style>
