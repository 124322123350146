<script lang="ts" setup>
import { ref, computed, watch, onMounted, onBeforeMount } from 'vue';
import { useAuth } from '@/plugins/auth';
import { useSignalR } from '@/plugins/signalr';
import { useMedia } from '@/plugins/media';
import { useRoute } from 'vue-router';
import { axios } from '@/plugins/axios';
import { useLogging } from '@/plugins/logging';
import { useFirebase } from '@/plugins/firebase';
import Account from '@/views/partials/layout/Account.vue';
import Notifications from '@/views/partials/layout/Notifications.vue';
import Breadcrumbs from '@/views/partials/navigation/Breadcrumbs.vue';
// import Menu from '@/views/partials/navigation/Menu.vue';
import Menu from '@/views/partials/navigation/MenuClassic.vue';
import PushNotifications from "@/views/partials/layout/PushNotifications.vue";
import NotificationsService, { ChannelType } from '@/modules/core/common/services/NotificationsService';
import Omnibox from "@/views/partials/layout/Omnibox.vue";
import { useThemeStore } from '@/store/theme';

import { useWmsViewsSettingsStore } from '@/modules/wms/configuration/settings/store';

const wmsViewsSettingsStore = useWmsViewsSettingsStore();

defineOptions({
    name: 'default-layout'
});

const media = useMedia();
const { $auth } = useAuth();
const { $log } = useLogging();
const { channel } = useSignalR();
const { $firebase } = useFirebase();
const server = channel('authentication');
const channels = ref<ChannelType[]>([]);

const route = useRoute();
const sidebar = ref(null);
const collapsed = ref(false);
const hover = ref(false);

onMounted(async () =>
{
    await wmsViewsSettingsStore.loadGlobalSettings();
});

// onClickOutside(sidebar, () =>
// {
//     if (media.mobile())
//     {
//         collapsed.value = false;
//     }
// });

const themeStore = useThemeStore();
const changeTheme = (): void =>
{
    themeStore.changeTheme();
};

server.on.logout = async (sessionId: number): Promise<void> =>
{
    const session = Number($auth.claims()["sid"] || 0);

    if (sessionId == null || sessionId == session)
    {
        $auth.logout();
    }
};

const toggleMenu = (value?: boolean): void =>
{
    if (typeof value === 'boolean')
        collapsed.value = value;
    else
        collapsed.value = !collapsed.value;
};

watch(route, (value) =>
{
    if (media.mobile())
    {
        collapsed.value = false;
    }
});

const loadChannels = async (): Promise<void> =>
{
    try
    {
        channels.value = await NotificationsService.getChannels();
    }
    catch (ex)
    {
        channels.value = [];
        $log.debug(ex);
    }
};

const initFirebase = async (): Promise<void> =>
{
    try
    {
        const firebaseEnabled = channels.value.find(el => el.value === 'Firebase').enabled;

        if (firebaseEnabled)
        {
            // TODO: Uncomment after completing the backend
            // const token = await $firebase.init();
            // await axios.post('auth/firebase/token', { token });
        }
    }
    catch (ex)
    {
        $log.debug(ex);
    }
};

onBeforeMount(async (): Promise<void> =>
{
    await loadChannels();
    await initFirebase();
});
</script>

<template>
    <main id="layout" class="d-flex" :class="{'desktop': desktop, 'laptop': laptop, 'mobile': mobile, 'phone': phone, [mq]: true, 'collapsed': collapsed, 'hover': hover}">
        <nav id="sidebar" ref="sidebar" @mouseenter="hover = true" @mouseleave="hover = false">
            <Menu :collapsed="collapsed" :hover="hover || mobile"></Menu>
        </nav>
        <section id="content" :class="{'scroll': mobile && !collapsed, 'lock-scroll': collapsed}" class="bg-body-secondary">
            <header>
                <nav class="navbar bg-body align-items-stretch">
                    <portal to="navbar-toggler-button">
                        <button type="button" class="navbar-toggler toggle-menu" style="padding: 0 22px;" @click="collapsed = !collapsed">
                            <i class="far fa-bars icon" v-if="!mobile || (mobile && !collapsed)"></i>
                            <i class="fa-solid fa-xmark-large icon" v-if="mobile && collapsed"></i>
                        </button>
                    </portal>
                    <button type="button" class="navbar-toggler toggle-menu" @click="collapsed = !collapsed">
                            <i class="far fa-bars icon" v-if="!mobile || (mobile && !collapsed)"></i>
                            <i class="fa-solid fa-xmark-large icon" v-if="mobile && collapsed"></i>
                    </button>
                    <Omnibox></Omnibox>
                    <button type="button" class="navbar-toggler" @click="changeTheme">
                        <i class="far fa-fw fa-circle-half-stroke"></i>
                    </button>
                    <Notifications></Notifications>
                    <Account></Account>
                </nav>
            </header>
            <section id="view" class="position-relative" :class="{'bg-body-tertiary': !mobile}">
                <Breadcrumbs></Breadcrumbs>
                <div class="d-flex flex-column" :class="{'scroll': !mobile, 'content-mobile': mobile}">
                    <div class="wrapper flex-fill d-flex flex-column">
                        <router-view v-slot="{ Component }">
                            <transition name="fade" mode="out-in">
                                <component :is="Component" />
                            </transition>
                        </router-view>
                    </div>
                </div>
            </section>
        </section>
    </main>
    <PushNotifications v-if="$myOneSignal.isEnabled" />
</template>

<style lang="scss">
#layout {
    --ideo-nav-width: var(--ideo-nav-width-default);
    --ideo-view-padding-size: 20px;
    --ideo-view-outer-height: calc(100dvh - var(--ideo-header-height));
    --ideo-view-inner-height: calc(var(--ideo-view-outer-height) - var(--ideo-breadcrumbs-height));

    height: 100dvh;

    #sidebar {
        position: fixed;
        z-index: 10001;
        height: 100%;
        width: var(--ideo-nav-width);
        overflow: hidden;
        transition: var(--ideo-transition);
        background-color: var(--ideo-nav-bg);
        color: var(--ideo-nav-color);

        > header {
            height: var(--ideo-header-height);
        }
        > section {
            height: calc(100dvh - var(--ideo-header-height) - var(--ideo-footer-height));
        }
        > footer {
            height: var(--ideo-footer-height);
            width: var(--ideo-nav-width);
        }
    }
    #content {
        height: 100%;
        width: calc(100dvw - var(--ideo-nav-width));
        margin-left: var(--ideo-nav-width);
        transition: var(--ideo-transition);

        > header {
            height: var(--ideo-header-height);

            > .navbar {
                padding: 0px;
                border-bottom: 1px solid var(--bs-border-color);
                height: calc(var(--ideo-header-height) - 1px);
            }
        }
        > #view {
            // overflow: hidden;
            height: var(--ideo-view-outer-height);

            > .scroll,
            > .content-mobile {
                > .wrapper {
                    padding: var(--ideo-view-padding-size);
                }
            }

            > .scroll{
                height: calc(var(--ideo-view-inner-height));
            }
        }
    }

    .navbar-toggler {
        height: var(--ideo-header-height);
        padding: 0 14px;
        border: none;
        border-radius: 0;

        &:hover, &.active i {
            color: var(--bs-primary)
        }

        &.toggle-menu {
            padding: 0 16px 0 22px;
        }
    }

    &.collapsed:not(.mobile) {
        --ideo-nav-width: var(--ideo-nav-width-collapsed);

        #sidebar {
            > section {
                height: calc(100dvh - var(--ideo-header-height));
            }
            > footer {
                display: none;
                width: var(--ideo-nav-width-default);
            }
        }
        #content {
            margin-left: var(--ideo-nav-width-collapsed);
        }

        &.hover {
            #sidebar {
                width: var(--ideo-nav-width-default);

                > section {
                    height: calc(100dvh - var(--ideo-header-height) - var(--ideo-footer-height));
                }
                > footer {
                    display: block;
                }
            }
        }
    }

    &.mobile {
        #sidebar {
            visibility: hidden;
            width: 100%;
            top: var(--ideo-header-height);
            left: -100%;
            z-index: 10003;

            header {
                display: none;
            }

            > section {
                height: calc(100dvh - var(--ideo-header-height));
            }
        }
        #content {
            width: 100%;
            margin-left: 0;

            &.lock-scroll {
                overflow: hidden;
            }
        }

        &.collapsed {
            #sidebar {
                visibility: visible;
                left: 0;
            }
        }

        .navbar-toggler {
            .icon {
                font-size: 1.5rem;
            }
        }
    }
}
</style>
