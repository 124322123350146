import { axios } from '@/plugins/axios';
import { KeyValue } from '@/helpers/Interfaces';
import { RouteParamsRaw } from 'vue-router';

/**
 * DynamicGridService
 */
export default class DynamicGridService
{
    /**
     * @returns Promise<DynamicGridConfiguration>
     */
    public static async get(id: string): Promise<DynamicGridConfiguration>
    {
        return (await axios.get<DynamicGridConfiguration>(`admin/grids/${id}`)).data;
    }

    /**
     * @returns Promise<DynamicGridConfiguration>
     */
    public static async save(id: string, data: DynamicGridConfiguration): Promise<DynamicGridConfiguration>
    {
        return (await axios.post<DynamicGridConfiguration>(`admin/grids/${id}`, data)).data;
    }

}

export interface DynamicGridConfiguration
{
    grid: DynamicGridColumn[]
}

export interface DynamicGridColumn
{
    field: string;
    label: string;
    description: string;
    isActive: boolean;
    type: string;
    formatter: string;
    order: number;
    orderBy: string;
    sortable: boolean;
    customCssClass: string;
    withRedirect: boolean;
    route: string;
    params: Record<string, string>;
}
