import { axios } from '@/plugins/axios';
import { Option, Resource } from '@/helpers/Interfaces';
import { ViewKindEnum } from '@/helpers/Enums';
import { DateTime } from 'luxon';

/**
 * WmsSettingsService
 */
export default class WmsSettingsService
{
    public static async getGlobalConfiguration():  Promise<Resource<GlobalConfiguration>>
    {
        return (await axios.get<Resource<GlobalConfiguration>>('admin/configuration/global')).data;
    }

    public static async putGlobalConfiguration(data: GlobalConfiguration): Promise<Resource<GlobalConfiguration>>
    {
        return (await axios.put<Resource<GlobalConfiguration>>('admin/configuration/global', data)).data;
    }

    public static async getViewSettings(module: string, kind: ViewKindEnum, documentPublicId: string): Promise<Resource<ViewSettingsItemModel[]>>
    {
        return (await axios.get<Resource<ViewSettingsItemModel[]>>(`admin/configuration/viewSettings/${module}/${kind}`, {
            params: {
                documentPublicId
            }
        })).data;
    }
    public static async getLicence(): Promise<Resource<LicenceModel>>
    {
        return (await axios.get<Resource<LicenceModel>>(`licence/model`)).data;
    }
}

export interface GlobalConfiguration {
    connectZoneWithWarehouse: boolean
    useCarriers: boolean
    useBatches: boolean
    useCounters: boolean
    qrCodeScheme: string
    weightValuePrecision: number
    dimensionValuePrecision: number
    quantityPrecision: number
    weightUnit: string
    weightUnitsOptions: WeightUnit[]
    dimensionUnit: string
    dimensionUnitOptions: DimensionUnit[]
    localizationFillingCalculationType: '',
    localizationFillingCalculationTypeOptions: Option[],
    useLocalizations: boolean,
    documentBlockadesType: string,
    documentBlockadesTypeOptions: Option[],
    issuePriorityType: string,
    issuePriorityTypeOptions: Option[],
    couriersToken: string,
    maxScanQuantity: number,
    localizationSuggestions: string,
    localizationSuggestionsOptions: Option[],
    localizationsSuggestionsEnabled: boolean,
    localiationSpotType: string,
    localiationSpotTypeOptions: Option[],
    productReceiptValidationType: string,
    productReceiptValidationTypeOptions: Option[],
    carrierReceiptValidationType: string,
    carrierReceiptValidationTypeOptions: Option[],
    productWithCarrierValidationType: string,
    productWithCarrierValidationTypeOptions: Option[],
    dropshipping: boolean
}

export interface ViewSettingsItemModel {
    id: string
    kind: string
    module: string
    blocked: boolean
}

export interface WeightUnit {
    unit: string;
    description: string
}

export interface DimensionUnit {
    unit: string;
    description: string
}

export interface LicenceModel
{
    licenceStartDateUtc: DateTime
    licenceEndDateUtc: DateTime
    wmsPermissions: LicenceConfigurationValue[],
    wmsVisibility: LicenceConfigurationValue[],
    applicationAccess: LicenceConfigurationValue[],
    mesPermissions: LicenceConfigurationValue[],
    mesVisibility: LicenceConfigurationValue[]
}

export interface LicenceConfigurationValue {
    id: string,
    value: boolean
}
