<template>
    <span>{{ $filters.documentDate(item.result[configuration.field], 'yyyy-MM-dd HH:mm:ss', 'yyyy-MM-dd') }}</span>
</template>

<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { Options, Vue } from 'vue-class-component';
import { Resource } from '@/helpers/Interfaces';

import { DynamicGridColumn } from '../../services/DynamicGridService';

@Options({})
export default class DateTimeFormatter extends Vue
{
    @Prop()
    public configuration: DynamicGridColumn;
    @Prop()
    public item: Resource<any>;
}
</script>
