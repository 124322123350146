/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import EmptyLayout from '@/views/layouts/EmptyLayout.vue';

export default [
    {
        path: 'ai',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            // Formularze
            {
                path: 'heroes',
                name: 'ai-heores',
                meta: { auth: true },
                component: () => import('@/modules/ai/heroes/views/Index.vue')
            }
        ]
    }
];
