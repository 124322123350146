import { ShortcutsEnum } from '@/modules/core/common/services/WidgetsService';

export const SHORTCUTS = [
    {
        name: ShortcutsEnum.ReceiptsToAccept,
        text: "[[[Przyjęcia awizowane (Akceptacje)]]]",
        route: 'wms-approvals-receipts-list',
        icon: 'fa-regular fa-folder-gear'
    },
    {
        name: ShortcutsEnum.DirectReceiptsToAccept,
        text: "[[[Przyjęcia bezpośrednie (Akceptacje)]]]",
        route: 'wms-approvals-direct-receipts-list',
        icon: 'fa-regular fa-folder-gear'
    },
    {
        name: ShortcutsEnum.IssuesToAccept,
        text: "[[[Wydania (Akceptacje)]]]",
        route: 'wms-approvals-issue-list',
        icon: 'fa-regular fa-folder-gear'
    },
    {
        name: ShortcutsEnum.Receipts,
        text: "[[[Przyjęcia awizowane]]]",
        route: 'wms-receipts-receipt-list',
        icon: 'fa-solid fa-warehouse '
    },
    {
        name: ShortcutsEnum.DirectReceipts,
        text: "[[[Przyjęcia bezpośrednie]]]",
        route: 'wms-receipts-direct-receipt-list',
        icon: 'fa-solid fa-warehouse '
    },
    {
        name: ShortcutsEnum.DeliveryCalendar,
        text: "[[[Kalendarz dostaw]]]",
        route: 'wms-receipts-calendar',
        icon: 'fa-solid fa-calendar-days'
    },
    {
        name: ShortcutsEnum.QuickTransfers,
        text: "[[[Szybkie przesunięcia]]]",
        route: 'wms-transfers-quick-transfer-form',
        icon: 'fa-regular fa-arrow-right-arrow-left'
    },
    {
        name: ShortcutsEnum.Transfers,
        text: "[[[Zlecenia przesunięć]]]",
        route: 'wms-transfers-list',
        icon: 'fa-regular fa-arrow-right-arrow-left'
    },
    {
        name: ShortcutsEnum.Collecting,
        text: "[[[Zbieranie]]]",
        route: 'wms-issue-collecting-list',
        icon: 'fa-solid fa-cart-flatbed-boxes'
    },
    {
        name: ShortcutsEnum.Packing,
        text: "[[[Pakowanie]]]",
        route: 'wms-issue-packing-list',
        icon: 'fa-solid fa-cart-flatbed-boxes'
    },
    {
        name: ShortcutsEnum.CouriersIssues,
        text: "[[[Wydania do kurierów]]]",
        route: 'wms-issue-couriers-list',
        icon: 'fa-solid fa-cart-flatbed-boxes '
    },
    {
        name: ShortcutsEnum.OneStageIssues,
        text: "[[[Wydania]]]",
        route: 'wms-issue-onestage-list',
        icon: 'fa-solid fa-cart-flatbed-boxes'
    },
    {
        name: ShortcutsEnum.DirectIssues,
        text: "[[[Wydania bezpośrednie]]]",
        route: 'wms-issue-direct-issue-list',
        icon: 'fa-solid fa-cart-flatbed-boxes'
    },
    {
        name: ShortcutsEnum.PersonalCollecting,
        text: "[[[Odbiór osobisty]]]",
        route: 'wms-issue-personal-collecting-list',
        icon: 'fa-solid fa-cart-flatbed-boxes'
    },
    {
        name: ShortcutsEnum.Inventories,
        text: "[[[Inwentaryzacja]]]",
        route: 'wms-inventories-groups',
        icon: 'fas fa-clipboard-list-check'
    },
    {
        name: ShortcutsEnum.Products,
        text: "[[[Produkty]]]",
        route: 'wms-products-list',
        icon: 'fa-brands fa-product-hunt'
    },
    {
        name: ShortcutsEnum.Carriers,
        text: "[[[Nośniki]]]",
        route: 'wms-carriers',
        icon: 'fa-solid fa-pallet-boxes'
    },
];

export function GetShortcut(name: ShortcutsEnum)
{
    return SHORTCUTS.find(p => p.name === name);
}
