<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { AuthModel } from "@/modules/core/auth/services/AuthService";
import { useOneSignal } from '@onesignal/onesignal-vue3';

@Options({})
export default class PushNotifications extends Vue
{
    public get userIdentity(): AuthModel
    {
        return this.$auth.user();
    }

    public mounted(): void
    {
        setTimeout( async () =>
        {
            await this.loginOneSignal();
        }, 500);
    }

    private async loginOneSignal() : Promise<void>
    {
        const oneSignal = useOneSignal();

        const notifications = oneSignal.Notifications;

        if (!notifications.permission)
            await oneSignal.Notifications.requestPermission();

        if (notifications.permission && notifications.isPushSupported())
        {
            if (this.userIdentity)
            {
                await oneSignal.login(this.userIdentity.publicId);
            }
        }

        notifications.setDefaultTitle("Asiston WMS");
    }
}
</script>
