<template>
    <div>
        <list-view
            :items="items"
            :pager="pager"
            :show-header="false"
            :row-click="(item: Resource<MostIssuedProductItemModel>) => $router.push({name:'wms-products-edit', params: { publicId: item.result.product.publicId }})"
            @change="loadData()"
        >
            <template #row="{ item }">
                <list-view-image width="40">
                    <ideo-img :src="$filters.image(item.result.product.imageUrl, 'thumb')" :rounded="true" :blank="!item.result.product.imageUrl" width="40" height="40" blank-color="#777" :alt="$t('[[[Zdjęcie produktu]]]')" @click.stop="openImage($filters.baseurl(item.result.product.imageUrl))" />
                </list-view-image>
                <list-view-header :title="$t('[[[Nazwa]]]')" sort="product.name">
                    <span class="name">
                        {{ item.result.product.name }}
                    </span>
                </list-view-header>
                <list-view-item :title="$t('[[[Ilość]]]')" sort="count" class="text-end">
                    {{ item.result.count }}
                </list-view-item>
            </template>
        </list-view>
    </div>
</template>

<script lang="ts" setup>
import { ref, reactive, onMounted } from 'vue';
import Pager from "@/helpers/Pager";
import { Resource } from '@/helpers/Interfaces';
import { Form, FormType } from '@/helpers/Form';
import { useAlerts } from '@/plugins/alerts';
import { useLocalization } from '@/plugins/localization';
import { useMixins } from '@/plugins/mixins';
import { useGallery } from '@/plugins/useGallery';
import { WidgetModel } from "@/modules/core/home/services/HomeService";
import WidgetsService, { MostIssuedProductItemModel, MostIssuedProductsFilterModel } from "@/modules/core/common/services/WidgetsService";

interface Props
{
    config: WidgetModel
    publicId: string
}

defineOptions({
    name: 'MostIssuedProductsWidget',
    inheritAttrs: false,
});

const props = defineProps({
  "config": { default: null },
  "publicId": { default: '' }
});

const { $alert } = useAlerts();
const { $t } = useLocalization();
const { $handleException } = useMixins();
const { openImage } = useGallery();

const items = ref<Resource<MostIssuedProductItemModel>[]>([]);
const filter = reactive<FormType<MostIssuedProductsFilterModel>>(Form.create<MostIssuedProductsFilterModel>({
    limit: 5
}));
const pager = reactive<Pager>(new Pager(1, 20, 'Id', 'ASC'));

onMounted(async (): Promise<void> =>
{
    await loadData();
});

const loadData = async (): Promise<void> =>
{
    items.value = [];

    try
    {
        const response = await WidgetsService.getMostIssuedProductsList(filter.data());

        items.value = response;
    }
    catch (ex)
    {
        items.value = [];

        $handleException(ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });
    }
};
</script>

<style lang="scss" scoped>
.name {
    font-weight: 600;
}
</style>
