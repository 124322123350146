<script lang="ts" setup>
import { computed, ref } from 'vue';
import { DateTime } from 'luxon';
import { useLocalization } from '@/plugins/localization';
import { useThemeStore } from '@/store/theme';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

const model = defineModel<any|any[]>({
    get(value: any|any[])
    {
        if (value !== null && Array.isArray(value))
        {
            return value.map(p => toIso(p));
        }

        return toIso(value);
    },
    set(value: any|any[])
    {
        if (value !== null && Array.isArray(value))
        {
            return value.map(p => toDateTime(p));
        }

        return toDateTime(value);
    }
});
const props = defineProps({
  "name": { default: '' },
  "type": { default: 'date' },
  "zone": { default: 'local' },
  "disabled": { type: Boolean, default: false },
  "minuteStep": { default: 5 },
  "placeholder": { default: null },
  "inline": { type: Boolean, default: false },
  "range": { type: Boolean, default: false },
  "multi": { type: Boolean, default: false },
  "preview": { type: Boolean, default: true },
  "position": { default: 'center' },
  "teleport": { type: Boolean, default: false },
  "maxDate": { default: null },
  "minDate": { default: null },
  "hideInputIcon": { type: Boolean, default: false }
});

const { $i18n } = useLocalization();
const store = useThemeStore();

const picker = ref(null);
const format = computed(() => props.type === 'datetime' ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd');
const locale = computed(() => $i18n.locale());
const timezone = computed(() => props.zone == 'UTC' || props.type == 'date' ? 'UTC' : undefined);
const timepicker = computed(() => props.type == 'datetime');
const dark = computed(() => store.theme == 'dark');
const maxDate = computed(() => props.maxDate && props.maxDate instanceof DateTime ? props.maxDate.toISO() : props.maxDate);
const minDate = computed(() => props.minDate && props.minDate instanceof DateTime ? props.minDate.toISO() : props.minDate);

function clear()
{
    picker.value?.dispatchEvent(new CustomEvent('input', { bubbles: true }));
}

function toIso(value: DateTime)
{
    return value instanceof DateTime ? value.toISO() : null;
}

function toDateTime(value: string)
{
    let date = value ? DateTime.fromISO(value).setZone('UTC') : null;

    if (props.type == 'date' && date !== null)
    {
        date = date.set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
        });
    }

    return date;
}
</script>

<template>
    <div>
        <VueDatePicker
            v-model="model"
            model-type="iso"
            :timezone="timezone"
            :format="format"
            :locale="locale"
            :cancel-text="$t('[[[Anuluj]]]')"
            :select-text="$t('[[[Wybierz]]]')"
            :now-button-label="$t('[[[Teraz]]]')"
            :placeholder="props.placeholder"
            :enable-time-picker="timepicker"
            :minutes-increment="props.minuteStep"
            :minutes-grid-increment="props.minuteStep"
            :start-time="{minutes: Math.floor(DateTime.now().minute / 5) * 5}"
            :inline="props.inline"
            :range="props.range"
            :multi-calendars="props.multi"
            :dark="dark"
            :disabled="props.disabled"
            :auto-apply="true"
            :position="props.position"
            :teleport="props.teleport"
            :max-date="maxDate"
            :min-date="minDate"
            :hide-input-icon="props.hideInputIcon"
            @cleared="clear"
        >
            <template #trigger v-if="!!$slots.trigger">
                <slot name="trigger"></slot>
            </template>
        </VueDatePicker>
        <input type="hidden" :name="props.name" ref="picker">
        <div class="text-center small" v-if="model && props.preview && !timezone && !props.range">
            {{ $filters.date(model as string, format, 'UTC') }} (UTC)
        </div>
    </div>
</template>

<style scoped lang="scss">
:deep(.dp__input) {
    margin-top: -1px;
    padding-top: 7px;
    padding-bottom: 7px;
    background-color: var(--input-bg);

    &:disabled {
        background: var(--bs-secondary-bg);
    }
}
</style>
