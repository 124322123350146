import { App, Plugin, getCurrentInstance } from 'vue';
import { I18n } from '@/plugins/localization';
import { Alerts } from '@/plugins/alerts';
import { Sitemap } from '@/plugins/sitemap';
import AuthService, { AuthModel, TokenModel } from '@/modules/core/auth/services/AuthService';
import { RouteLocationRaw as Location, RouteLocation as Route } from 'vue-router';
import { Store } from 'vuex';
import Cookies from 'js-cookie';
import { useAuthStore, AuthStore } from '@/store/auth';

export interface Auth
{
    login(username: string, password: string, captchaResult?: string, rememberMe?: boolean, forceLogin?: boolean, redirectFrom?: Route): Promise<void>;
    pinLogin(pinCode: string, rememberMe?: boolean): Promise<void>;
    logout(redirect?: Location): Promise<void>;
    validate(throwError?: boolean): Promise<TokenModel>;
    refresh(throwError?: boolean): Promise<TokenModel>;
    recover(throwError?: boolean): Promise<TokenModel>;
    ntlm(throwError?: boolean): Promise<TokenModel>;
    token(): string;
    claims(): Record<string, string>;
    fetch(): void;
    user(): AuthModel;
    userId(): number;
    check(): boolean;
    ready(): boolean;
    reload(): void;
    impersonating(): number;
    impersonatingValue(): string;
    impersonate(id: number): void;
    unimpersonate(): void;
    resetSession(): Promise<void>;
}

interface AuthRedirect
{
    homePage: Location;
    loginPage: Location;
    forbidden: Location;
    notFound: Location;
    changePassword: Location;
}

export class AuthOptions
{
    public store: Store<any>;
    public routes: AuthRedirect;
    public defaultPolicy: (token: Record<string, string>) => boolean = (token: Record<string, string>) => true;
}

export class AuthHelper implements Auth
{
    private vue: any;
    private router: any;
    private alert: Alerts;
    private i18n: I18n;
    private sitemap: Sitemap;
    private store: AuthStore;
    private options: AuthOptions;
    private tokenTimeoutHandler: any;
    private sessionDuration: number;
    private online: boolean = true;

    public constructor(app: App<any>, options: AuthOptions, store: AuthStore)
    {
        const vue = app.config.globalProperties;

        this.router = vue.$router;
        this.sitemap = vue.$sitemap;
        this.alert = vue.$alert;
        this.i18n = vue.$i18n;
        this.store = store;
        this.options = options;

        this.initRouter();
        this.initImpersonation();
    }

    private initRouter(): void
    {
        this.router.beforeEach(async (to: Route, from: Route, next: any) =>
        {
            if (this.store.loaded === false)
            {
                // Sprawdzenie ważności access tokena po odświeżeniu przeglądarki
                if (!this.check())
                {
                    await this.validate();
                }

                // Uzyskanie nowego access tokena za pomocą refresh tokena (opcja "remember me")
                if (!this.check())
                {
                    await this.recover();
                }

                // Logowanie Windows
                if (!this.check() && import.meta.env.VITE_APP_WIN_AUTH == "true")
                {
                    await this.ntlm();
                }

                // Pobranie danych użytkownika
                if (this.check())
                {
                    await this.fetch();
                }

                this.store.setLoaded(true);
            }

            next();
        });

        this.router.beforeEach(async (to: Route, from: Route, next: any) =>
        {
            if (to.meta && to.meta.hasOwnProperty('auth'))
            {
                const auth: any = to.meta.auth;

                // Wymuszenie zmiany hasła
                if (auth !== false && this.store.authenticated === true && this.store.identity.forcePasswordChange === true && to.meta.stop !== true)
                {
                    this.alert.info(this.i18n.$t('[[[Twoje hasło wygasło. Zmień je na nowe.]]]'));
                    next(this.options.routes.changePassword);

                    return;
                }

                if (typeof (auth) === "boolean")
                {
                    if (auth === true && this.store.authenticated === false)
                    {
                        next(this.options.routes.loginPage);

                        return;
                    }

                    if (auth === false && this.store.authenticated === true)
                    {
                        next(this.options.routes.homePage);

                        return;
                    }

                    if (this.store.authenticated === true)
                    {
                        // Pobranie aktualnego widoku z sitemapa
                        const node = await this.sitemap.find(to);

                        // Sprawdzenie uprawnień do widoku w sitemapie
                        if (node && node.allowed == false)
                        {
                            next(this.options.routes.forbidden);

                            return;
                        }
                    }
                }

                if (Array.isArray(auth) && auth.length > 0)
                {
                    if (this.store.authenticated === false)
                    {
                        next(this.options.routes.loginPage);

                        return;
                    }
                }
            }

            next();
        });
    }

    private async initSession(): Promise<void>
    {
        await this.setSessionDuration();

        const sessionLifetime = this.sessionDuration; // minuty
        const activityInterval = 5; // sekundy

        const updateLastActivity = (): void =>
        {
            this.lastActivity = Date.now();
        };
        const handleNetworkOnline = (event: any): void =>
        {
            this.online = true;
        };
        const handleNetworkOffline = (event: any): void =>
        {
            this.online = false;
        };

        window.addEventListener("online", handleNetworkOnline);
        window.addEventListener("offline", handleNetworkOffline);
        document.body.addEventListener('click', updateLastActivity);
        document.body.addEventListener('mousemove', updateLastActivity);
        document.body.addEventListener('keypress', updateLastActivity);

        const intervalId = setInterval(() =>
        {
            this.lastActivity = this.lastActivity || Date.now();

            // Z włączoną opcją "remember me" sesja nigdy nie wygasa
            if (this.check() && this.store.rememberMe === false)
            {
                const timeLeft = (this.lastActivity + sessionLifetime * 60 * 1000) - Date.now();

                if (timeLeft < 0)
                {
                    this.logout();
                }
            }
        },
        activityInterval * 1000);

        const handleStorageChange =  (e: StorageEvent): void =>
        {
            if (e.key === 'last-activity' && e.oldValue != null && e.newValue == null)
            {
                this.clearAll();
                this.router.push(this.options.routes.loginPage).catch(() => {});
            }
        };

        window.addEventListener('storage', handleStorageChange);

        this.destroySession = () =>
        {
            document.body.removeEventListener('keypress', updateLastActivity);
            document.body.removeEventListener('mousemove', updateLastActivity);
            document.body.removeEventListener('click', updateLastActivity);
            clearInterval(intervalId);
            window.removeEventListener('storage', handleStorageChange);
            window.removeEventListener("offline", handleNetworkOnline);
            window.removeEventListener("online", handleNetworkOnline);
        };
    }

    private destroySession(): void {}

    public resetSession(): Promise<void>
    {
        this.destroySession();

        return this.initSession();
    }

    private initImpersonation(): void
    {
        const impersonated = localStorage.getItem("impersonated");

        if (impersonated)
        {
            this.store.impersonate(Number(impersonated));
        }

        window.addEventListener('storage', (e) =>
        {
            if (e.key === "impersonated")
            {
                if (e.newValue != null)
                    this.store.impersonate(Number(e.newValue));
                else
                    this.store.unimpersonate();
            }
        });
    }

    private get lastActivity(): number
    {
        return Number(localStorage.getItem("last-activity") || 0);
    }

    private set lastActivity(value: number)
    {
        localStorage.setItem("last-activity", value.toString());
    }

    private async setSessionDuration(): Promise<void>
    {
        const userSessionDuration = await AuthService.getSessionSettings();

        this.sessionDuration = userSessionDuration.sessionDuration >0 ? userSessionDuration.sessionDuration : 60; // minuty
    }

    private clearSession(): void
    {
        localStorage.removeItem("last-activity");
    }

    private decryptToken(token: string): any
    {
        const base64 = token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
        const payload = JSON.parse(window.atob(base64));

        return payload;
    }

    private setToken(tokens: TokenModel): void
    {
        if (tokens?.token.length > 0)
        {
            this.store.setToken(tokens.token);
            this.store.setAuthenticated(true);

            const clockSkew = 60 * 1000; // 60 sekund przed wygaśnięciem tokena musimy go odświeżyć
            const payload = this.decryptToken(tokens.token);
            const timeLeft = Number(payload.exp) * 1000 - (new Date()).getTime() - clockSkew;

            if (timeLeft > 0)
            {
                this.tokenTimeoutHandler = setTimeout(async () =>
                {
                    try
                    {
                        this.refresh();
                    }
                    catch (ex)
                    {
                        this.logout();
                    }
                },
                timeLeft);
            }
        }
    }

    private clearToken(): void
    {
        clearTimeout(this.tokenTimeoutHandler);
        this.setToken(null);
        this.store.setAuthenticated(false);
    }

    private clearAll(): void
    {
        if (this.online)
        {
            this.store.setRememberMe(false);
            this.store.clearIdentity();
            this.unimpersonate();
            this.clearSession();
            this.clearToken();
            this.destroySession();
        }
    }

    private checkPermissions(token: Record<string, string>): void
    {
        if (this.options.defaultPolicy(token) == false)
        {
            throw (() =>
            {
                return {
                    code: 400,
                    message: "[[[Nie masz uprawnień do panelu administracyjnego.]]]",
                    data: null as any,
                    inner: null as any
                };
            })();
        }
    }

    public async login(username: string, password: string, captchaResult: string = null, rememberMe: boolean = false, forceLogin: boolean = false, redirectFrom: Route = undefined): Promise<void>
    {
        try
        {
            this.clearAll();

            const result = await AuthService.login(username, password, captchaResult, rememberMe, forceLogin);
            const token = this.decryptToken(result.token);

            this.checkPermissions(token);
            this.setToken(result);

            await this.fetch();

            this.initSession();
            this.store.setRememberMe(rememberMe);

            if (redirectFrom)
                this.router.push(redirectFrom.fullPath).catch(() => {});
            else
                this.router.push(this.options.routes.homePage).catch(() => {});
        }
        catch (ex)
        {
            this.clearAll();

            throw ex;
        }
    }

    public async pinLogin(pinCode: string, rememberMe?: boolean): Promise<void>
    {
        try
        {
            this.clearAll();

            const result = await AuthService.pinLogin(pinCode, rememberMe);
            const token = this.decryptToken(result.token);

            this.checkPermissions(token);
            this.setToken(result);

            await this.fetch();

            this.initSession();
            this.store.setRememberMe(rememberMe);
            this.router.push(this.options.routes.homePage).catch(() => {});
        }
        catch (ex)
        {
            this.clearAll();

            throw ex;
        }
    }


    public async logout(redirect?: Location): Promise<void>
    {
        try
        {
            this.clearToken();
            await AuthService.logout();
        }
        catch (ex)
        {
            // ignore errors
        }
        finally
        {
            this.clearAll();
            this.router.push(redirect || this.options.routes.loginPage).catch(() => {});
        }
    }

    public async validate(throwError: boolean = false): Promise<TokenModel>
    {
        try
        {
            const result = await AuthService.validateToken();
            const token = this.decryptToken(result.token);

            this.checkPermissions(token);
            this.setToken(result);

            this.initSession();
            this.store.setRememberMe(result.refresh !== null);

            return token;
        }
        catch (ex)
        {
            this.clearAll();

            if (throwError)
                throw ex;
        }

        return null;
    }

    public async refresh(throwError: boolean = false): Promise<TokenModel>
    {
        try
        {
            const result = await AuthService.refreshToken();
            const token = this.decryptToken(result.token);

            this.checkPermissions(token);
            this.setToken(result);

            return token;
        }
        catch (ex)
        {
            this.clearAll();

            if (throwError)
                throw ex;
        }

        return null;
    }

    public async recover(throwError: boolean = false): Promise<TokenModel>
    {
        try
        {
            const result = await AuthService.recoverToken();
            const token = this.decryptToken(result.token);

            this.checkPermissions(token);
            this.setToken(result);

            this.initSession();
            this.store.setRememberMe(true);

            return token;
        }
        catch (ex)
        {
            this.clearAll();

            if (throwError)
                throw ex;
        }

        return null;
    }

    public async ntlm(throwError: boolean = false): Promise<TokenModel>
    {
        try
        {
            const result = await AuthService.ntlm();
            const token = this.decryptToken(result.token);

            this.checkPermissions(token);
            this.setToken(result);
            this.initSession();

            return token;
        }
        catch (ex)
        {
            this.clearAll();

            if (throwError)
                throw ex;
        }

        return null;
    }

    public token(): string
    {
        return this.store.token;
    }

    public claims(): Record<string, string>
    {
        return this.decryptToken(this.token());
    }

    public async fetch(): Promise<void>
    {
        try
        {
            const result = await AuthService.getIdentity(true);

            this.store.setIdentity(result);
        }
        catch (ex)
        {
            this.clearAll();
        }
    }

    public user(): AuthModel
    {
        return this.store.identity;
    }

    public userId(): number
    {
        return this.impersonating() || this.user().id;
    }

    public check(): boolean
    {
        return this.store.authenticated;
    }

    public ready(): boolean
    {
        return this.store.loaded;
    }

    public reload(): void
    {
        this.store.setLoaded(false);
        setTimeout(() => this.store.setLoaded(true), 100);
    }

    public impersonating(): number
    {
        return this.store.impersonated as number;
    }

    public impersonatingValue(): string
    {
        if (this.token())
        {
            const payload = this.decryptToken(this.token());
            const value = window.btoa(`${this.impersonating().toString()}#${payload.jti}`);

            if (Cookies.get('access-impersonate') != value)
            {
                Cookies.set('access-impersonate', value, { path: '/', secure: true, sameSite: 'None' });
            }

            return value;
        }

        return null;
    }

    public impersonate(id: number): void
    {
        this.store.impersonate(id);
        localStorage.setItem("impersonated", id.toString());
    }

    public unimpersonate(): void
    {
        this.store.unimpersonate();
        localStorage.removeItem("impersonated");
        Cookies.remove('access-impersonate', { path: '/', secure: true, sameSite: 'None' });
    }
}

export const adminAccessPolicy = (token: Record<string, string>): boolean =>
{
    const claimName = "https://ideo.pl/identity/claims/permission";
    const claimValue = "AdminAccess";

    return claimName in token && ([].concat(token[claimName])).includes(claimValue);
};

export const useAuth = () =>
{
    const app = getCurrentInstance();
    const auth = app.appContext.config.globalProperties.$auth;

    return {
        $auth: auth
    };
};

const AuthPlugin: Plugin =
{
    install(app, options)
    {
        const vue = app.config.globalProperties;

        if (!vue.$router)
        {
            throw new Error("Vue:router must be set.");
        }

        if (!vue.$sitemap)
        {
            throw new Error("Vue:sitemap must be set.");
        }

        if (!vue.$alert)
        {
            throw new Error("Vue:alert must be set.");
        }

        if (!vue.$i18n)
        {
            throw new Error("Vue:i18n must be set.");
        }

        const store = useAuthStore();

        app.config.globalProperties.$auth = new AuthHelper(app, options, store);
    }
};

export default AuthPlugin;

declare module "@vue/runtime-core"
{
    interface ComponentCustomProperties
    {
        $auth: Auth;
    }
}
