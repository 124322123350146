export default {
    "Project-Id-Version": "",
    "POT-Creation-Date": "2024-07-05T08:43:00.966Z",
    "MIME-Version": "1.0",
    "Content-Type": "text/plain; charset=utf-8",
    "Content-Transfer-Encoding": "8bit",
    "X-Generator": "Edito",
    "PO-Revision-Date": "2024-07-05T08:43:02.315Z",
    "Language": "pl-PL",
    "Messages": {
        "1": "",
        "2": "",
        "3": "",
        "4": "",
        " - rozkładanie": "",
        " - zbieranie": "",
        " do {0}": "",
        " Konfiguracja widoków ": "",
        " lokalizacji: ": "",
        " strefy: ": "",
        " Wybierz rolę uprawnień ": "",
        "-": "",
        "...": "",
        "' + file.filename + '": "",
        "#": "",
        "%0 z %1": "",
        "%0 znaków": "",
        "<Brak nazwy>": "",
        "${copied ? 'Skopiowano' : 'Kopiuj'}": "",
        "${el.text}": "",
        "${getCurrentScope().label}": "",
        "${isActive(item) ? 'Odznacz' : 'Zaznacz'}": "",
        "${item.entityName}": "",
        "${item.text}": "",
        "${loading ? 'Ładowanie elementów...' : 'Nie znaleziono żadnych elementów.'}": "",
        "${props.inputPlaceholder}": "",
        "${tokenVisibility ? 'Ukryj' : 'Pokaż'} token": "",
        "1 godzina": "",
        "1:1": "",
        "10 minut": "",
        "12 godzin": "",
        "15 minut": "",
        "2 dni": "",
        "2 miesiące": "",
        "2 tygodnie": "",
        "24 godziny": "",
        "3 dni": "",
        "3 godziny": "",
        "3 miesiące": "",
        "30 minut": "",
        "4 dni": "",
        "4:7": "",
        "5 dni": "",
        "5 minut": "",
        "6 dni": "",
        "6 godzin": "",
        "7:4": "",
        "Aby dodawać członków zespołu musisz najpierw zapisać formularz.": "",
        "Aby móc przetestować poprawność kluczy, najpierw zapisz formularz.": "",
        "Adaptive sampling": "",
        "Administracja": "",
        "Administracja - Kraje": "",
        "Administracja - Miasta": "",
        "Administracja - Role": "",
        "Administracja - Stanowiska pracy": "",
        "Administracja - Użytkownicy": "",
        "Administracja - Województwa": "",
        "Administracja - Zespoły": "",
        "Administratorzy": "",
        "Adres": "",
        "Adres do serwisu OneSignal.": "",
        "Adres domenowy": "",
        "Adres dostawy": "",
        "Adres e-mail": "",
        "Adres e-mail potwierdzony": "",
        "Adres email": "",
        "Adres email nadawcy": "",
        "Adres IP": "",
        "Adres IP: %0": "",
        "Adres serwera": "",
        "Adres URL": "",
        "Adv": "",
        "Agreguj": "",
        "AI": "",
        "Akcent": "",
        "Akceptacja": "",
        "Akceptacja dokumentów": "",
        "Akceptacja dokumentu": "",
        "Akceptacja własnych dokumentów": "",
        "Akceptacja wszystkich dokumentów": "",
        "Akceptacje": "",
        "Akceptowany format danych": "",
        "Akceptuj": "",
        "Akcja": "",
        "Akcja błąd": "",
        "Akcja sukces": "",
        "Akcje": "",
        "Aktualizacja: {0}": "",
        "Aktualizuj": "",
        "Aktualna sesja": "",
        "Aktualne hasło": "",
        "Aktualne urządzenie": "",
        "Aktualne zapełnienie": "",
        "Aktualny styl numeracji:": "",
        "Aktywna": "",
        "Aktywne": "",
        "Aktywne kanały komunikacji": "",
        "Aktywne sesje": "",
        "Aktywni": "",
        "Aktywność": "",
        "Aktywny": "",
        "Aktywuj powiadomienia": "",
        "Aktywuj tłumaczenia": "",
        "Aktywuj typ": "",
        "Aktywuj zestaw inteligentnych narzędzi": "",
        "Alias": "",
        "Alias został przepięty.": "",
        "Angielski": "",
        "Ankieta": "",
        "Anonimowy": "",
        "Anulowano dokument.": "",
        "Anuluj": "",
        "Aparat": "",
        "Api key": "",
        "Aplikacja": "",
        "Aplikacja zostanie zrestartowana w przeciągu maksymalnie 60 sekund.": "",
        "application/json": "",
        "application/x-www-form-urlencoded": "",
        "Archiwizuj przez maksymalnie (dni)": "",
        "Asystent": "",
        "Asystent AI": "",
        "Asystent został dodany.": "",
        "Asystent został zaktualizowany.": "",
        "Asystenta AI": "",
        "Autor": "",
        "Azure AD": "",
        "Azure AI": "",
        "Azure Application Insights": "",
        "Azure OpenAI": "",
        "Baza danych": "",
        "Baza danych tego klienta jest nieaktualna.": "",
        "Bazy danych": "",
        "Bezpieczeństwo": "",
        "Bezpieczne połączenie SSL": "",
        "Bieżący dokument": "",
        "Blokada konta": "",
        "Blokowanie dokumentów": "",
        "Blokowanie kont": "",
        "Blokuj lokalizacje podczas inwentaryzacji": "",
        "Blokuj magazyn podczas inwentaryzacji": "",
        "Blokuj ruch produktów podczas inwentaryzacji": "",
        "Błąd 404. Nie znaleziono żądanego zasobu.": "",
        "Błąd instalacji rozszerzenia": "",
        "Błąd odświeżania listy rozszerzeń": "",
        "Błąd przeinstalowywania rozszerzenia": "",
        "Błąd skanowania, spróbuj ponownie.": "",
        "Błąd, proszę spróbować jeszcze raz.": "",
        "Błędne": "",
        "Błędny": "",
        "Błędy": "",
        "Błędy walidacji należy przypisać do \"entry.errors.value\" w postaci tablicy komunikatów.": "",
        "Brak": "",
        "Brak aktywnych skrótów": "",
        "Brak cennika dla wybranego dostawcy.": "",
        "Brak danych": "",
        "Brak danych.": "",
        "Brak dostępnych skrótów": "",
        "Brak działu nadrzędnego": "",
        "Brak elementów": "",
        "Brak elementów do wygenerowania": "",
        "Brak kategorii": "",
        "Brak kluczy": "",
        "Brak kolekcji": "",
        "Brak nowych powiadomień.": "",
        "Brak odpowiedzi": "",
        "Brak uprawnienia z licencji.": "",
        "Brak uprawnień": "",
        "Brak wersji": "",
        "Brak wyboru": "",
        "Brak wygenerowanych elementów": "",
        "Brak wyników": "",
        "Brak wystarczającej ilości informacji": "",
        "Brak zapisanych filtrów.": "",
        "Brak zapytania": "",
        "Brak zdefiniowanych ról.": "",
        "Brak zdefiniowanych zespołów.": "",
        "Budynek": "",
        "Budynek został dodany.": "",
        "Budynek został usunięty.": "",
        "Budynek został zaktualizowany.": "",
        "Budynki": "",
        "Cache będzie przechowywany z wykorzystaniem serwera Redis.": "",
        "Cache po stronie serwera": "",
        "Cache-Control": "",
        "Cel aktywny": "",
        "Cel został dodany.": "",
        "cel został usunięty.": "",
        "Cel został zaktualizowany.": "",
        "Cele": "",
        "Cena": "",
        "Cena w dolarach sumy tokenów wejściowych i wyjściowych.": "",
        "Cennik Azure OpenAI": "",
        "Cennik AzureAI": "",
        "Cennik OpenAI": "",
        "Centrum powiadomień": "",
        "Chat AI": "",
        "Checkbox": "",
        "Ciemny": "",
        "Co 10 dni": "",
        "Co 10 minut": "",
        "Co 12 godzin": "",
        "Co 15 minut": "",
        "Co 2 godziny": "",
        "Co 2 minuty": "",
        "Co 2 tygodnie": "",
        "Co 20 minut": "",
        "Co 3 godziny": "",
        "co 3 miesiące": "",
        "Co 3 miesiące": "",
        "co 30 dni": "",
        "Co 30 minut": "",
        "Co 4 miesiące": "",
        "Co 5 dni": "",
        "Co 5 minut": "",
        "Co 6 godzin": "",
        "co 6 miesięcy": "",
        "Co chcesz wygenerować?": "",
        "Co drugi dzień": "",
        "Co drugi miesiąc": "",
        "Co godzinę": "",
        "Co miesiąc": "",
        "Co minutę": "",
        "Co pół roku": "",
        "co rok": "",
        "Co tydzień": "",
        "Codziennie": "",
        "Connection String": "",
        "Coś poszło nie tak": "",
        "Cron": "",
        "Cyfry": "",
        "Cykl sprzedażowy": "",
        "Czas": "",
        "Czas pomiędzy": "",
        "Czas realizacji zamówienia": "",
        "Czas trwania blokady": "",
        "Czas uruchomienia": "",
        "Czas, w jakim zostanie zrealizowane zamówienie (dni)": "",
        "Czat z asystentem": "",
        "Czerwiec": "",
        "Członkowie zespołu": "",
        "Cztery kolumny": "",
        "Czy aby na pewno chesz przywrócić ustawienia domyślne?": "",
        "Czy aby na pewno chesz zrestartować aplikacje?": "",
        "Czy chcesz kontynuować?": "",
        "Czy chcesz wydrukować etykietę nośnika?": "",
        "Dalej": "",
        "DALL-E 3": "",
        "Dane": "",
        "Dane formularza są przechowywane w obiekcie Entry.": "",
        "Dane osobowe": "",
        "Dane podst.": "",
        "Dane podstawowe": "",
        "Dane w tabeli": "",
        "Dane wejściowe": "",
        "Dane wyjściowe": "",
        "Data": "",
        "Data do": "",
        "Data dodania": "",
        "Data dostarczenia": "",
        "Data dostawy": "",
        "Data generowania": "",
        "Data i czas": "",
        "Data i godzina": "",
        "Data modyfikacji": "",
        "Data od": "",
        "Data opuszczenia zespołu": "",
        "Data rejestracji": "",
        "Data rozpoczęcia": "",
        "Data uruchomienia": "",
        "Data usunięcia": "",
        "Data utworzenia": "",
        "Data utworzenia wersji": "",
        "Data utworzenia: ": "",
        "Data użycia": "",
        "Data wygaśnięcia": "",
        "Data wygenerowania treści.": "",
        "Data wykluczenia do": "",
        "Data wykluczenia od": "",
        "Data wylogowania": "",
        "Data wywołania": "",
        "Data zakonczenia": "",
        "Data zakończenia": "",
        "Data zakończenia blokady konta": "",
        "Data zalogowania": "",
        "Data zapytania": "",
        "Data zdarzenia": "",
        "Data: %0 | Status: %1": "",
        "Debug": "",
        "Debugger": "",
        "Dedykowane": "",
        "DeepL": "",
        "Definicja": "",
        "Definicja (JSON)": "",
        "Definicja formularza znajduje się w obiekcie Form.": "",
        "Delete": "",
        "DELETE: Usunięcie wpisu": "",
        "Dezaktywuj typ": "",
        "Długość": "",
        "Długość (%0)": "",
        "Długość [%0]": "",
        "Długość czasu przechowywania": "",
        "Długość trwania sesji (min)": "",
        "Dłużyca": "",
        "Dni": "",
        "do": "",
        "Do": "",
        "Do akceptacji": "",
        "Do akceptacji/Zakończony": "",
        "do czasu ręcznego odblokowania": "",
        "Do magazynu": "",
        "Do wygenerowania": "",
        "Docs": "",
        "Dodaj": "",
        "Dodaj asystenta": "",
        "Dodaj budynek": "",
        "Dodaj cel": "",
        "Dodaj dni": "",
        "Dodaj do zespołu": "",
        "Dodaj dokument": "",
        "Dodaj drukarkę": "",
        "Dodaj dział": "",
        "Dodaj endpoint": "",
        "Dodaj formularz": "",
        "Dodaj grupę": "",
        "Dodaj inwentaryzację częściową": "",
        "Dodaj jeden lub więcej widgetów, aby uzyskać wgląd w postępy swojego zespołu.": "",
        "Dodaj kategorię": "",
        "Dodaj klienta": "",
        "Dodaj kod": "",
        "Dodaj komponent": "",
        "Dodaj list przewozowy": "",
        "Dodaj logo": "",
        "Dodaj lokalizacje": "",
        "Dodaj moduł": "",
        "Dodaj nośnik": "",
        "Dodaj nowy": "",
        "Dodaj nowy folder": "",
        "Dodaj nowy wątek": "",
        "Dodaj plan": "",
        "Dodaj pliki": "",
        "Dodaj pliki asystenta": "",
        "Dodaj poziom": "",
        "Dodaj pozycję": "",
        "Dodaj produkt": "",
        "Dodaj przęsło": "",
        "Dodaj przyjęcie": "",
        "Dodaj raport": "",
        "Dodaj regułe": "",
        "Dodaj rodzaj": "",
        "Dodaj rodzaj nośnika": "",
        "Dodaj rolę": "",
        "Dodaj rząd": "",
        "Dodaj sekcję": "",
        "Dodaj serwer": "",
        "Dodaj shortcode": "",
        "Dodaj skrót": "",
        "Dodaj słowo": "",
        "Dodaj stanowisko": "",
        "Dodaj strefę": "",
        "Dodaj stronę": "",
        "Dodaj synonimy": "",
        "Dodaj szablon": "",
        "Dodaj token": "",
        "Dodaj towary": "",
        "Dodaj towary, aby przekazać dokument do relizacji.": "",
        "Dodaj typ": "",
        "Dodaj użytkownika": "",
        "Dodaj warunek": "",
        "Dodaj webhooka": "",
        "Dodaj widget": "",
        "Dodaj wpis": "",
        "Dodaj wydanie": "",
        "Dodaj Wydanie": "",
        "Dodaj załadunek": "",
        "Dodaj zastępstwo": "",
        "Dodaj zdjęcie": "",
        "Dodaj zespół": "",
        "Dodaj źródło": "",
        "dodał": "",
        "dodane i edytowane przeze mnie": "",
        "Dodane przeze mnie": "",
        "Dodanie": "",
        "Dodanie celu": "",
        "Dodanie klienta": "",
        "Dodanie roli": "",
        "Dodanie serwera": "",
        "Dodanie użytkownika": "",
        "Dodanie źródła": "",
        "dodany": "",
        "Dodanych plików %0 (limit wynosi %1).": "",
        "Dodatkowe informacje": "",
        "Dodatkowe kody kreskowe": "",
        "Dodawanie": "",
        "Dodawanie i edycja własnych dokumentów": "",
        "Dodawanie nośnika": "",
        "Dokładność miejsc po przecinku dla ilości": "",
        "Dokładność miejsc po przecinku dla wagi": "",
        "Dokładność miejsc po przecinku dla wymiarów": "",
        "Dokończ rozkładanie": "",
        "Dokument": "",
        "Dokument %0": "",
        "Dokument należy odłożyć do": "",
        "Dokument PDF": "",
        "Dokument powiązany": "",
        "Dokument powiązany z przyjęcia": "",
        "Dokument przekazano do magazynu.": "",
        "Dokument realizujący": "",
        "Dokument tekstowy": "",
        "Dokument wygenerowany pomyślnie": "",
        "Dokument wysłano do ERP.": "",
        "Dokument zablokowany przez": "",
        "Dokument został anulowany.": "",
        "Dokument został usunięty.": "",
        "Dokument został zwrócony do magazynu.": "",
        "Dokument został zwrócony.": "",
        "Dokumenty": "",
        "Dokumenty powiązane": "",
        "Dołącz stack trace": "",
        "Domena": "",
        "Domena dla powiadomień": "",
        "Domena do której będą kierowane powiadomienia": "",
        "Domyślna": "",
        "Domyślna lokalizacja": "",
        "Domyślna wartość": "",
        "Domyślne": "",
        "Domyślne dane lokalizacji": "",
        "Domyślne filtry": "",
        "Domyślnie ukryte": "",
        "Domyślnie widoczne": "",
        "Domyślny": "",
        "Dopuść brak odpowiedzi": "",
        "Dostarczone": "",
        "Dostawa": "",
        "Dostawca": "",
        "Dostęp do modułu": "",
        "Dostęp niezdefiniowany": "",
        "Dostęp przyznany": "",
        "Dostęp zabroniony": "",
        "Dostępna jest nowa aktualizacja. Proszę zapisać postęp pracy i klinąć przycisk \"Aktualizuj\". Możesz również zaakceptować aktualizację poprzez odświeżenie okna przeglądarki. Brak aktualizacji może spowodować błędy.": "",
        "Dostępne": "",
        "Dostępne opcje:": "",
        "Dostępne skróty": "",
        "Dostępny limit": "",
        "Dostępny w pakowaniu": "",
        "dowolnej wybranej wersji": "",
        "Dowolny": "",
        "Dozwolone produkty": "",
        "Dozwolone rozszerzenia": "",
        "Dozwolone rozszerzenia: %0.": "",
        "Dozwolone są tylko małe i duże litery": "",
        "Dozwolone są tylko małe litery i znak -": "",
        "Dozwolone typy plików: %0.": "",
        "DPI": "",
        "Drugie imię": "",
        "Drugorzędny": "",
        "Drukarka": "",
        "Drukarka została dodana.": "",
        "Drukarka została usunięta.": "",
        "Drukarka została zaktualizowana.": "",
        "Drukarki": "",
        "Drukowane etykiety na urządzeniu": "",
        "Drukowanie: %0": "",
        "Drukuj": "",
        "Drukuj nośnik": "",
        "Drukuj testowo": "",
        "Drukuj wszystkie listy przewozowe": "",
        "Dsn": "",
        "Duży": "",
        "Duży układ siatki": "",
        "Dwie kolumny": "",
        "Dwuetapowe": "",
        "Dyrektywa max-age=N wskazuje, że odpowiedź pozostaje świeża do N sekund po wygenerowaniu odpowiedzi.": "",
        "Dyrektywa no-store wskazuje, że jakikolwiek cache dowolnego rodzaju nie powinien przechowywać tej odpowiedzi.": "",
        "Dyrektywa PRIVATE wskazuje, że odpowiedź może być przechowywana wyłącznie w prywatnej pamięci podręcznej (np. lokalnej pamięci przeglądarki).": "",
        "Dyrektywa PUBLIC wskazuje, że odpowiedź może być przechowywana we współdzielonej pamięci podręcznej (np. proxy).": "",
        "Dyrektywa s-maxage jest ignorowana przez prywatne pamięci podręczne i zastępuje wartość określoną przez dyrektywę max-age dla współdzielonych pamięci podręcznych.": "",
        "Dyrektywa s-maxage wskazuje, jak długo odpowiedź pozostaje aktualna we współdzielonej pamięci podręcznej (np. proxy).": "",
        "Dyrektywy zakresu": "",
        "Dział aktywny": "",
        "Dział nadrzędny": "",
        "Dział został dodany.": "",
        "Dział został zaktualizowany.": "",
        "Działania masowe": "",
        "Dzielenie": "",
        "dzień": "",
        "Dzień": "",
        "E-mail": "",
        "EAN": "",
        "Edycja celu": "",
        "Edycja dokumentów": "",
        "Edycja dokumentu": "",
        "Edycja klienta": "",
        "Edycja roli": "",
        "Edycja serwera": "",
        "Edycja użytkownika": "",
        "Edycja w popupie": "",
        "Edycja wszystkich dokumentów": "",
        "Edycja zlecenia przesunięcia": "",
        "Edycja źródła": "",
        "Edytor": "",
        "Edytowana wersja": "",
        "Edytowane mogą być tylko szablony włączonych i obsługiwanych kanałów.": "",
        "Edytuj": "",
        "Edytuj członka grupy": "",
        "Edytuj dokument": "",
        "Edytuj filtr": "",
        "Edytuj kategorię": "",
        "Edytuj kod": "",
        "Edytuj opakowanie": "",
        "Edytuj plan": "",
        "Edytuj pozycję": "",
        "Edytuj rodzaj nośnika": "",
        "Edytuj szablon": "",
        "Edytuj uprawnienia": "",
        "Edytuj wpis": "",
        "Eksport": "",
        "Eksport dokumentów": "",
        "Eksport użytkowników": "",
        "Eksportuj do Excela": "",
        "Elastic": "",
        "Element został przywrócony.": "",
        "Element został usunięty.": "",
        "Email": "",
        "Encja": "",
        "Endpoint": "",
        "Endpoint został dodany.": "",
        "Endpoint został zaktualizowany.": "",
        "ERP": "",
        "Error - błedy wewnętrze SDK": "",
        "Eskportuj": "",
        "Etykieta": "",
        "Etykieta kodu zbiorczego": "",
        "Etykieta listu przewozowego": "",
        "Etykieta lokalizacji": "",
        "Etykieta nośnika stałego": "",
        "Etykieta opakowania": "",
        "Etykieta produktu": "",
        "Etykiety": "",
        "Ewidencja partii w systemie WMS": "",
        "Exportuj do Excela": "",
        "Fatal - błedy krytyczne": "",
        "Filtr dla synchronizacji działów": "",
        "Filtr dla synchronizacji użytkowników": "",
        "Filtr domyślny": "",
        "Filtr globalny": "",
        "Filtr prywatny": "",
        "Filtr został usunięty": "",
        "Filtr został zapisany": "",
        "Filtrowanie": "",
        "Filtrowanie listy dokumentów": "",
        "Filtruj": "",
        "Filtry": "",
        "Filtry globalne": "",
        "Folder plików": "",
        "Foldery": "",
        "Formularz": "",
        "Formularz bazuje na nieaktualnej wersji definicji.": "",
        "Formularz zarchiwizowany": "",
        "Formularz został dodany.": "",
        "Formularz został skopiowany.": "",
        "Formularz został zaktualizowany.": "",
        "Funkcjonalności": "",
        "Generator obrazów": "",
        "Generator obrazów AI": "",
        "Generowanie dokumentu": "",
        "Generowanie listu przewozowego": "",
        "Generowanie LP": "",
        "Generowanie obrazów": "",
        "Generowanie obrazów zakończone.": "",
        "Generuj": "",
        "Generuj dokument": "",
        "Generuj list przewozowy": "",
        "Generuj metadane (AI)": "",
        "Generuj obraz": "",
        "Generuj odbiór osobisty": "",
        "Get": "",
        "GET: Lista wpisów (sortowanie i stronicowanie)": "",
        "GET: Pobranie danych": "",
        "GET: Pobranie wpisu": "",
        "GET: Schemat (definicja formularza)": "",
        "Głębokość": "",
        "Główny": "",
        "Godziny": "",
        "Google Translate V2": "",
        "Gotowe": "",
        "GPT-4 Vision": "",
        "Grafika": "",
        "Grudzień": "",
        "Grupa": "",
        "Grupy produktowe": "",
        "Harmonogram zadań": "",
        "Harmonogram został zmieniony": "",
        "Hasło": "",
        "Hasło (Klucz ukryty)": "",
        "Hasło musi zawierać co najmniej jeden znak specjalny np.: ~ @ # $ % ^ & *": "",
        "Hasło użytkownika serwisowego": "",
        "Hasło zostało zmienione. Zaloguj się ponownie": "",
        "Health": "",
        "Hierarchia": "",
        "Historia": "",
        "Historia logowania": "",
        "Historia ruchów magazynowych": "",
        "Historia zadania": "",
        "Horyzontalna": "",
        "Host": "",
        "Host dodatkowy": "",
        "Host główny": "",
        "i": "",
        "Id": "",
        "ID": "",
        "ID lub typ zadania": "",
        "Id modelu: %0": "",
        "Id obiektu": "",
        "Id rozmowy": "",
        "Id sesji": "",
        "Id wpisu": "",
        "ID zadania": "",
        "Id zapytania": "",
        "Id zdarzenia": "",
        "Identyfikator": "",
        "Identyfikator przestrzeni (WorkspaceId)": "",
        "Identyfikator wdrożenia": "",
        "Identyfikator zadania": "",
        "Identyfikator zapytania.": "",
        "Ikona": "",
        "Ikona chatu AI": "",
        "Ilości dni, na które mamy zamiar zamówić": "",
        "Ilość": "",
        "Ilość dostępna": "",
        "Ilość lokalizacji": "",
        "Ilość na ZD": "",
        "Ilość nie może być mniejsza lub równa 0, w celu usunięcia pozycji należy użyć opcji usuwania": "",
        "Ilość paczek/palet": "",
        "Ilość palet na lokalizacji": "",
        "Ilość palet:": "",
        "Ilość powtórzeń": "",
        "Ilość poziomów": "",
        "Ilość przęseł": "",
        "Ilość przyjęta": "",
        "Ilość towaru z obliczeń": "",
        "Ilość wierszy": "",
        "Ilość wyników do odrzucenia": "",
        "Ilość wyników do pobrania": "",
        "Ilość zamawiana PJM": "",
        "Ilość znaków": "",
        "Ilość znaków odpowiedzi": "",
        "Ilość została zaktualizowana.": "",
        "Imię": "",
        "Imię i Nazwisko": "",
        "Import": "",
        "Import modułu": "",
        "Import użytkowników": "",
        "Importuj definicję formularza": "",
        "Indeks": "",
        "Indeks został usunięty.": "",
        "Indywidualny kod": "",
        "Info": "",
        "Info - informacje": "",
        "Informacja": "",
        "Informacje": "",
        "Informator": "",
        "Inspekcja": "",
        "Instalacja": "",
        "Instaluj": "",
        "Instrukcja": "",
        "Instrukcja modelu (prompt).": "",
        "Interpreter kodu": "",
        "Interpreter kodu umożliwia asystentowi pisanie i uruchamianie kodu. To narzędzie może przetwarzać pliki z różnymi danymi i formatowaniem oraz generować pliki.": "",
        "Inwentaryzacja": "",
        "Inwentaryzacja częściowa została dodana.": "",
        "Inwentaryzacja częściowa została usunięta.": "",
        "Inwentaryzacja zbiorcza została dodana.": "",
        "Inwentaryzacja zbiorcza została usunięta.": "",
        "Inwentaryzacje częściowe": "",
        "Jakość": "",
        "Jakość HD 1024x1024": "",
        "Jakość HD 1024x1792 i 1792x1024": "",
        "Jakość standardowa 1024x1024": "",
        "Jakość standardowa 1024x1792 i 1792x1024": "",
        "Jakość wygnerowanego obrazu.": "",
        "Jasny": "",
        "Jedna kolumna": "",
        "Jednoetapowe": "",
        "Jednoetapowo": "",
        "Jednostka miary": "",
        "Jednostka miary dla wagi": "",
        "Jednostka miary dla wymiarów": "",
        "Jeśli pole jest puste, wówczas używana jest wartość domyślna. W przeciwnym wypadku wartość domyślna jest nadpisywana.": "",
        "Jm": "",
        "JM": "",
        "Kalendarz": "",
        "Kalendarz - Kategorie zasobów": "",
        "Kalendarz dostaw": "",
        "Kalkulator pakowania": "",
        "Kanał jest domyślnie wymagany. Nie da się wyłączyć wysyłania powiadomienia tym kanałem. Możliwa jest tylko zmiana tytułu i treści komunikatu.": "",
        "kanał wymagany": "",
        "Kanały": "",
        "Kanały komunikacji": "",
        "Kanały obsługiwane przez wybrany typ powiadmienia": "",
        "Kanały włączone w ustawieniach aplikacji": "",
        "Katalog": "",
        "Katalog główny": "",
        "Katalog został utworzony": "",
        "Katalog został utworzony.": "",
        "Kategoria": "",
        "Kategoria jest wymagana": "",
        "Kategoria została dodana.": "",
        "Kategoria została usunięta.": "",
        "Kategoria została zaktualizowana.": "",
        "Kategorie": "",
        "Klasa rotacyjności": "",
        "Klawiatura numeryczna": "",
        "Klienci": "",
        "Klient aktywny": "",
        "Klient został dodany.": "",
        "Klient został usunięty.": "",
        "Klient został zaktualizowany.": "",
        "Klucz": "",
        "Klucz API": "",
        "Klucz aplikacji. Znajduje się w `Platformach`": "",
        "Klucz główny (PrimaryKey)": "",
        "Klucz prywatny": "",
        "Klucz publiczny": "",
        "Klucze": "",
        "Klucze są nie prawidłowe.": "",
        "Klucze są prawidłowe.": "",
        "Kod": "",
        "Kod dostępu": "",
        "Kod dostępu stanowiska": "",
        "Kod kreskowy": "",
        "Kod lokalizacji w strefie": "",
        "Kod pin": "",
        "Kod PIN": "",
        "Kod pocztowy": "",
        "Kod regału": "",
        "Kod towaru": "",
        "Kod walidacji": "",
        "Kod wertfikacyjny": "",
        "Kod weryfikacyjny jest nieprawidłowy.": "",
        "Kod wpisany poprawnie": "",
        "Kod zbiorczy został dodany.": "",
        "Kod zbiorczy został zaktualizowany.": "",
        "Kod został usunięty.": "",
        "Kod źródłowy": "",
        "Kody zbiorcze": "",
        "Kolejka": "",
        "Kolejka wydania": "",
        "Kolejki wydań": "",
        "Kolor drugorzędny: %0": "",
        "Kolor icon na panelu z aplikacjami: %0": "",
        "Kolor podstawowy: %0": "",
        "Kolor przewodni obrazu": "",
        "Kolor tekstu aktywnej pozycji menu: %0": "",
        "Kolor tła panelu z aplikacjami: %0": "",
        "Kolorowana konsola": "",
        "Kolory zostały przywrócone.": "",
        "Kolory zostały zapisane.": "",
        "Kolumny": "",
        "Komponent którego wartość zostanie użyta jako nazwa elementu nadrzędnego. Dostępne tylko komponenty typu ``Tekst``.": "",
        "Komponent został dodany.": "",
        "Komponent został usunięty.": "",
        "Komponent został zaktualizowany.": "",
        "Komponent źródłowy": "",
        "Komunikat": "",
        "Komunikat błędu": "",
        "Komunikat walidacji": "",
        "Konfiguracja": "",
        "Konfiguracja asystenta ai": "",
        "Konfiguracja domyślna": "",
        "Konfiguracja harmonogramu": "",
        "Konfiguracja kolumn tabeli": "",
        "Konfiguracja logów": "",
        "Konfiguracja lokalizacji": "",
        "Konfiguracja rzędów": "",
        "Konfiguracja ścieżki": "",
        "Konfiguracja zaawansowana": "",
        "Konfiguracja zapytania": "",
        "Konfiguracja została przywrócona dla reguł i celów": "",
        "Konfigurator lokalizacji": "",
        "Konta użytkowników": "",
        "Konta użytkowników zostały odblokowane": "",
        "Konta użytkowników zostały przypisane do zespołu": "",
        "Konta użytkowników zostały usunięte z systemu": "",
        "Konta użytkowników zostały zablokowane": "",
        "Kontakt": "",
        "Kontakty - Typ działania": "",
        "Kontener plików": "",
        "Konto aktywne": "",
        "Konto pozostanie nieaktywne dopóki adres e-mail nie zostanie potwierdzony": "",
        "Kontrahent": "",
        "Kontrola jakości": "",
        "Kontrolka wyboru (pojedynczy wybór)": "",
        "Kontrolka wyboru (wybór wielokrotny)": "",
        "Konwertuj obrazy do formatu WebP": "",
        "Końcowa": "",
        "Kopiuj": "",
        "Kopiuj adres": "",
        "Kopiuj definicję": "",
        "Kopiuj pole": "",
        "Kosz": "",
        "Kosz:": "",
        "Kraj": "",
        "Krok minut": "",
        "Krok minut musi być liczbą z zakresu od 1 do 60.": "",
        "Kroki": "",
        "Krótki opis": "",
        "Kurier": "",
        "Kurierzy": "",
        "Kwiecień": "",
        "LDAPS (LDAP over SSL)": "",
        "Lewy panel": "",
        "Licencja": "",
        "Liczba": "",
        "Liczba całkowita": "",
        "Liczba danych wejściowych podana w tokenach.": "",
        "Liczba danych wyjściowych podana w tokenach.": "",
        "Liczba dni na ile chcesz zamówić": "",
        "Liczba dni na ile wystarczy towaru": "",
        "Liczba dziesiętna": "",
        "Liczba miesięcy": "",
        "Liczba pozycji dokumentu": "",
        "Liczba punktów sprzedaży": "",
        "Liczba wariantów": "",
        "Liczba wyników na stronie": "",
        "Liczniki dokumentów": "",
        "Lider": "",
        "Limit": "",
        "Limit API": "",
        "Linia": "",
        "linie": "",
        "Link": "",
        "Lipiec": "",
        "List przewozowy": "",
        "List przewozowy został dodany.": "",
        "List przewozowy został wygenerowany.": "",
        "Lista dokumentów": "",
        "Lista dozwolonych / wykluczonych żądań HTTP": "",
        "Lista efektywnych uprawnień użytkownika wynikających z uprawnień indywidualnych oraz ról.": "",
        "Lista elementów zostanie zawężona tylko do tych, które są pozwiązane z wybranymi wartościami z zaznaczonych komponentów.": "",
        "Lista jest pusta": "",
        "Lista planów": "",
        "Lista plików": "",
        "Lista powiadomień": "",
        "Lista rozwijana": "",
        "Listopad": "",
        "Listy przewozowe": "",
        "Litera": "",
        "Litery": "",
        "Litery i cyfry": "",
        "Log Analytics": "",
        "Logi": "",
        "Logi wydajnościowe": "",
        "Login": "",
        "Logo": "",
        "Logowanie": "",
        "Logowanie zakończyło się niepowodzeniem.": "",
        "Logowanie zależności / żądań typu HTTP": "",
        "Loguj wybrane": "",
        "Lok. końc.": "",
        "Lok. pocz.": "",
        "Lokalizacja": "",
        "Lokalizacja końcowa": "",
        "Lokalizacja początkowa": "",
        "Lokalizacja początkowa i końcowa nie może być taka sama.": "",
        "Lokalizacja została dodana.": "",
        "Lokalizacja została usunięta.": "",
        "Lokalizacja została zaktualizowana.": "",
        "Lokalizacja:": "",
        "Lokalizacje": "",
        "Luty": "",
        "Ładowanie...": "",
        "Łączenie treści": "",
        "Łącznie na magazynie": "",
        "Magazyn": "",
        "Magazyn ERP": "",
        "Magazyny": "",
        "Maj": "",
        "Maksimum": "",
        "Maksymalna ilość do zeskanowania (0 - bez limitu)": "",
        "Maksymalna ilość plików": "",
        "Maksymalna rozdzielość %0x%1px": "",
        "Maksymalna waga jednego towaru": "",
        "Maksymalny rozmiar pliku w MB": "",
        "Maksymalny rozmiar pliku: %0MB.": "",
        "Małe znaki": "",
        "Mały": "",
        "Mały układ siatki": "",
        "Mapowanie": "",
        "Mappingi": "",
        "Marketplace": "",
        "Marzec": "",
        "Masa": "",
        "Masa [%0]": "",
        "Masz niezapisane zmiany. Czy na pewno chcesz wyjść?": "",
        "Masz niezapisane zmiany. Czy na pewno chcesz wykonać tą czynność?": "",
        "Metadane": "",
        "Metoda": "",
        "Metoda musi zwracać obiekt \"entry.errors\".": "",
        "Metody rozchodu zapasu": "",
        "Miasto": "",
        "Miejsca po przecinku": "",
        "Miejscowość": "",
        "miesiąc": "",
        "Miesiąc": "",
        "Miesiące": "",
        "Miesięczny": "",
        "Międzymagazynowe": "",
        "Migracje": "",
        "Miniaturki": "",
        "Minimalna długość hasła": "",
        "Minimalna średnia arytmetyczna": "",
        "Minimum": "",
        "Minimum jedna kolumna musi pozostać widoczna": "",
        "Minuty": "",
        "Mnożenie": "",
        "Model": "",
        "Model AI użyty do wygenerowanej treści.": "",
        "Model danych": "",
        "Moderacja danych wejściowych": "",
        "Moduł": "",
        "Moduł został dodany.": "",
        "Moduł został usunięty.": "",
        "Moduł został wyeksportowany.": "",
        "Moduł został zaktualizowany.": "",
        "Modyfikacja": "",
        "Modyfikacja dokumentów": "",
        "Modyfikuj istniejący wątek": "",
        "Moje dokumenty": "",
        "Moje filtry": "",
        "Moje formularze": "",
        "Moje konto": "",
        "Moje pliki": "",
        "Monitoring": "",
        "Monitoruj statystyki .NET": "",
        "Monitoruj żądania HTTP": "",
        "Możesz podać nową nazwę pliku.": "",
        "Możliwość modyfikacji tego działu jest ograniczona. Nieaktywne pola są sychronizowane z zewnętrzną bazą danych %0.": "",
        "Możliwość modyfikacji tego konta jest ograniczona. Nieaktywne pola są sychronizowane z zewnętrzną bazą danych %0.": "",
        "Możliwość modyfikacji tego konta jest ograniczona. Nieaktywne pola są synchronizowane z zewnętrzną bazą danych %0.": "",
        "Możliwość wielokrotnego wyboru": "",
        "Mój tytuł": "",
        "N/A": "",
        "Na ile dni chcesz zamówić": "",
        "Na pewno usunąć wybrane elementy?": "",
        "Na: %0": "",
        "Nadawanie uprawnień do dokumentów": "",
        "Nadawanie uprawnień do własnych dokumentów": "",
        "Nadawanie uprawnień do wszystkich dokumentów": "",
        "Nadawca": "",
        "Nagłówek": "",
        "Nagłówek dokumentu": "",
        "Najstarsza wiadomość": "",
        "Należy sprawdzić towary na podanej lokalizacji oraz na innych, na których dane towary się znajdują": "",
        "Należy sprawdzić towary tylko na podanej lokalizacji. Jeśli towary znajdują się na innych lokalizacjach, wówczas ich stan zostanie automatycznie dodany": "",
        "Należy wybrać encje": "",
        "Należy zaznaczyć conajmniej 1 dokument": "",
        "Napisz mi polecenie :-)": "",
        "Narzędzia": "",
        "Narzędzie integruje się z platformą SMSAPI.pl": "",
        "Następna strona": "",
        "Nazwa": "",
        "Nazwa aliasu": "",
        "Nazwa asystenta": "",
        "Nazwa bazy danych": "",
        "Nazwa budynku": "",
        "Nazwa działu": "",
        "Nazwa filtra": "",
        "Nazwa formularza": "",
        "Nazwa indeksu": "",
        "Nazwa jest wymagana": "",
        "Nazwa katalogu": "",
        "Nazwa katalogu została zmieniona.": "",
        "Nazwa klienta": "",
        "Nazwa kolumny": "",
        "Nazwa kolumny z opisem": "",
        "Nazwa kolumny z wartością": "",
        "Nazwa komponentu": "",
        "Nazwa konta (Klucz dostępu)": "",
        "Nazwa modelu": "",
        "Nazwa modułu": "",
        "Nazwa musi mieć co najmniej 3 znaki": "",
        "Nazwa musi mieć co najmniej 5 znaków": "",
        "Nazwa nadawcy": "",
        "Nazwa narzędzia": "",
        "Nazwa nie może być dłuższa niż 30 znaków": "",
        "Nazwa nie może być dłuższy niż 50 znaków": "",
        "Nazwa nośnika": "",
        "Nazwa obiektu": "",
        "Nazwa pliku": "",
        "Nazwa pliku została zmieniona.": "",
        "Nazwa pola": "",
        "Nazwa powiadomienia": "",
        "Nazwa produktu": "",
        "Nazwa przycisku ``Dalej``": "",
        "Nazwa przycisku ``Wstecz``": "",
        "Nazwa przycisku ``Wyślij``": "",
        "Nazwa raportu": "",
        "Nazwa rodzaju nośnika": "",
        "Nazwa roli": "",
        "Nazwa sekcji w menu": "",
        "Nazwa sekcji...": "",
        "Nazwa serwera": "",
        "Nazwa stanowiska": "",
        "Nazwa strefy": "",
        "Nazwa szablonu": "",
        "Nazwa towaru": "",
        "Nazwa typu": "",
        "Nazwa urządzenia": "",
        "Nazwa użytkownika": "",
        "Nazwa użytkownika serwisowego": "",
        "Nazwa wątku": "",
        "Nazwa webhooka": "",
        "Nazwa zadania": "",
        "Nazwa zasobu": "",
        "Nazwa zespołu": "",
        "Nazwa źródła": "",
        "Nazwa źródła danych": "",
        "Nazwa:": "",
        "Nazwisko": "",
        "Nazwy wartości": "",
        "Nicość - nie zapisuj": "",
        "Nie": "",
        "NIE": "",
        "Nie loguj wybranych": "",
        "Nie masz uprawnień do panelu administracyjnego.": "",
        "Nie masz uprawnień do tego dokumentu": "",
        "Nie masz uprawnień do tego widoku. Stan widoku mógł ulec zmianie, odśwież i sprawdź lub spróbuj ponownie później.": "",
        "Nie możesz dodać tego samego widgetu dwa razy": "",
        "Nie możesz modyfikować profilu zastępowanego użytkownika.": "",
        "Nie możesz modyfikować uprawnień": "",
        "Nie można usunąć swojego konta.": "",
        "Nie podano wymaganej ilości znaków: %0.": "",
        "Nie posiadasz uprawnień do modułu w licencji": "",
        "Nie udało się pobrać listy celów": "",
        "Nie udało się pobrać listy działów": "",
        "Nie udało się pobrać listy filtrów": "",
        "Nie udało się pobrać listy kanałów": "",
        "Nie udało się pobrać listy kolejek": "",
        "Nie udało się pobrać listy powiadomień": "",
        "Nie udało się pobrać listy ról": "",
        "Nie udało się pobrać listy serwerów baz danych": "",
        "Nie udało się pobrać listy serwerów plików": "",
        "Nie udało się pobrać listy typów modeli": "",
        "Nie udało się pobrać listy wydziałów": "",
        "Nie udało się pobrać listy zespołów": "",
        "Nie udało się pobrać obrazu.": "",
        "Nie udało się pobrać powiadomień.": "",
        "Nie udało się pobrać ustawień kont użytkowników": "",
        "Nie udało się pobrać widgetu/ów": "",
        "Nie udało się usunąć szablonu": "",
        "Nie udało się wykonać akcji": "",
        "Nie udało się wysłać pliku": "",
        "Nie udało się załadować widgetu AI.": "",
        "Nie udało się zapisać filtra": "",
        "Nie udało się zapisać szablonu": "",
        "Nie wszystkie towary zostały spakowane, czy napewno chcesz kontynuować?": "",
        "Nie wszystkie wybrane elementy zostały przeniesione. Nie posiadasz wymaganych uprawnień lub, w przypadku przenoszenia katalogów, katalog docelowy jest podkatalogiem katalogu źródłowego. Katalogi i pliki których nie udało się przenieść pozostały nadal w zakładce Wybrane.": "",
        "Nie wybrano żadnego użytkownika.": "",
        "Nie wymaga uwierzytelniania": "",
        "nie wymuszaj zmiany hasła": "",
        "Nie zdefiniowano żadnych ról.": "",
        "Nie znaleziono dostępnych widgetów": "",
        "Nie znaleziono filtrów.": "",
        "Nie znaleziono formularza.": "",
        "Nie znaleziono kolumn.": "",
        "Nie znaleziono pasującego widgetu": "",
        "Nie znaleziono pasujących elementów.": "",
        "Nie znaleziono zapisanych filtrów.": "",
        "Nie znaleziono żadnego pola.": "",
        "Nie znaleziono żadnych plików": "",
        "Nie znaleziono żadnych użytkowników.": "",
        "Nie znaleziono żadnych wyników.": "",
        "Nieaktywne": "",
        "Nieaktywni": "",
        "Nieaktywny": "",
        "Niebezpieczeństwo": "",
        "niedozwolone rozszerzenie pliku (%0)": "",
        "niedozwolony typ pliku (%0)": "",
        "Niepoprawne polecenie SQL": "",
        "Niepoprawne wyrażenie regularne": "",
        "Niepotwierdzeni": "",
        "Niepowiązana ": "",
        "Nieprawidłowa definicja": "",
        "Nieprawidłowy adres email": "",
        "Nieprawidłowy adres URL": "",
        "Nieprzeczytane": "",
        "Niestandardowy błąd": "",
        "Nieudane": "",
        "Nieudane próby logowania": "",
        "Niewidoczne": "",
        "Niezgodna": "",
        "Nieznane narzędzie": "",
        "Nieznany model": "",
        "Nieznany typ pliku": "",
        "Nieznany użytkownik": "",
        "Nigdy": "",
        "Nikomu nie przydzielono uprawnień": "",
        "no-store (wyłącza cache)": "",
        "Normalne": "",
        "Nośnik": "",
        "Nośnik końcowy": "",
        "Nośnik początkowy": "",
        "Nośnik zeskanowany pomyślnie.": "",
        "Nośnik został dodany": "",
        "Nośnik został usunięty.": "",
        "Nośnik został zaktualizowany": "",
        "Nośnik:": "",
        "Nośniki": "",
        "Nośność": "",
        "Nowa data": "",
        "Nowe hasło": "",
        "Nowe/Oczekuje na przyjęcie": "",
        "Nowy czat": "",
        "np. (&(objectClass=organizationalUnit))": "",
        "np. (&(objectClass=user)": "",
        "np. https://api.onesignal.com": "",
        "Nr budynku": "",
        "Nr dokumentu": "",
        "Nr dokumentu realizującego": "",
        "Nr dokumentu wewnętrznego": "",
        "Nr dokumentu z ERP": "",
        "Nr ERP": "",
        "Nr inwentaryzacji": "",
        "Nr kontroli jakości": "",
        "Nr lokalu": "",
        "Nr rejestracyjny": "",
        "Nr tel. os. kontaktowej": "",
        "Numer": "",
        "Numer dokumentu": "",
        "Numer dokumentu WMS": "",
        "Numer domu": "",
        "Numer listu przewozowego": "",
        "Numer mieszkania": "",
        "Numer oryginalny": "",
        "Numer sesji": "",
        "Numer telefonu": "",
        "Numeracja": "",
        "Obiekt %0 został %1": "",
        "Objętość [%0]": "",
        "Obok siebie": "",
        "Obraz został pomyślnie opisany": "",
        "Obraz został wycięty.": "",
        "Obraz został wygenerowany.": "",
        "Obsługa lokalizacji w WMS": "",
        "Obsługa nośników w systemie WMS": "",
        "Obsługiwane formaty plików: txt, md, pdf, docx.": "",
        "Oczekuje na wysłanie": "",
        "od": "",
        "Od": "",
        "od {0}": "",
        "od %0": "",
        "Od lewej do prawej": "",
        "Od prawej do lewej": "",
        "Odbiór osobisty": "",
        "Odblokuj": "",
        "Odblokuj pole": "",
        "Odchylenie standardowe": "",
        "Odejmowanie": "",
        "Odinstalowywanie": "",
        "Odpowiedzi": "",
        "Odpowiedź": "",
        "Odpowiedź dowolna": "",
        "Odrzucone": "",
        "Odstęp": "",
        "Odśwież": "",
        "Odśwież dane licencyjne": "",
        "Odśwież listę": "",
        "Odświeżono listę rozszerzeń": "",
        "Odznacz": "",
        "Odznacz wszystkie": "",
        "Ogólne": "",
        "Ok": "",
        "Opakowanie": "",
        "Opakowanie zeskanowane pomyślnie": "",
        "Opakowanie zostało zaktualizowane": "",
        "Opcje": "",
        "Opcje dodatkowe": "",
        "OpenAI": "",
        "Operacje": "",
        "Opis": "",
        "Opis (ERP)": "",
        "Opis błędu": "",
        "Opis dla pozycji": "",
        "Opis dla pozycji:": "",
        "Opis obrazu": "",
        "Opis zespołu": "",
        "Opis źródła": "",
        "Opisy": "",
        "Optymalizuj rozdzielczość obrazów": "",
        "Oraz hasło": "",
        "Oraz nowe hasło": "",
        "Organizacja": "",
        "Organizacja została usunięta.": "",
        "Orientacja": "",
        "Osoba blokująca": "",
        "Osoba kontaktowa": "",
        "Osoba zajmująca stanowisko": "",
        "Osoba zastępowana": "",
        "Osoba zastępująca": "",
        "Osobista": "",
        "Ostatnia aktywność": "",
        "Ostatnia strona": "",
        "Ostrzeżenia": "",
        "Ostrzeżenie": "",
        "Oś X": "",
        "Oś Y": "",
        "Otwórz": "",
        "Oznacz jako nieprzeczytane": "",
        "Oznacz jako przeczytane": "",
        "Oznacz wszystkie jako przeczytane": "",
        "P. oczekujące": "",
        "Paczka została przypisana": "",
        "Paczki oczekujące": "",
        "Pakowanie": "",
        "Pakowanie (Zależy od aktywnego procesu)": "",
        "Paleta kolorów": "",
        "Państwo": "",
        "Parametry, wymagane do wykonania zapytania:": "",
        "Partia": "",
        "Partia nie może być pusta": "",
        "Partia:": "",
        "Partie": "",
        "Pasek": "",
        "Pasek postępu": "",
        "Październik": "",
        "Pełny": "",
        "Pierwotny dokument": "",
        "Pierwsza strona": "",
        "Pierwsze zatowarowanie": "",
        "Pierwszy miesiąc": "",
        "Pigułki": "",
        "Piktogram": "",
        "PJM": "",
        "Plan dodany pomyślnie.": "",
        "Plan edytowany pomyślnie.": "",
        "Plan sprzedażowy": "",
        "Plan sprzedażowy na dany miesiąc PJM": "",
        "Plan sprzedaży": "",
        "Plik": "",
        "Plik audio": "",
        "Plik binarny": "",
        "Plik CSS": "",
        "Plik CSV": "",
        "Plik DOC": "",
        "Plik DOCX": "",
        "Plik GIF": "",
        "Plik HTML": "",
        "Plik jest wysyłany": "",
        "Plik JPEG": "",
        "Plik JPG": "",
        "Plik MP4": "",
        "Plik PNG": "",
        "Plik skompresowany": "",
        "Plik tekstowy json": "",
        "Plik tekstowy txt": "",
        "Plik ZIP": "",
        "Plik został dodany.": "",
        "Plik został usunięty.": "",
        "Plik został wysłany": "",
        "Pliki": "",
        "Pliki Asystent": "",
        "Pliki Asystenta": "",
        "Pliki asystenta AI": "",
        "Pliki do wysłania: %0": "",
        "Pliki prywatne": "",
        "Pliki publiczne": "",
        "Pliki współdzielone": "",
        "po %0 nieudanych próbach logowania": "",
        "Po jednym synonimie w wierszu": "",
        "Po wybraniu tej opcji konta użytkowników zostaną usunięte z systemu. Czy chcesz kontynuować?": "",
        "Po zaznaczeniu tej opcji uprawnienia indywidualne i pochodzące z ról nie są brane pod uwagę.": "",
        "Po zmianie konfiguracji koniecznie jest pełne odświeżenie strony. Klienci którzy połączyli się z poprzednią konfiguracją mogą mieć problemy z połączeniem do nowej. Zeleca się wyczyszczenie pamięci strony.": "",
        "Pobierz": "",
        "Pobierz z": "",
        "Poczta": "",
        "Podaj kod dostępu do stanowiska pakowania": "",
        "Podaj kod weryfikacyjny": "",
        "Podaj lokalizację kuriera": "",
        "Podaj lokalizację pakowacza": "",
        "Podaj nową nazwę": "",
        "Podaj swój adres email": "",
        "Podana nazwa jest zajęta.": "",
        "Podana wartość jest nieprawidłowa.": "",
        "Podanie nazwy jest wymagane.": "",
        "Podano adres": "",
        "Podano nieprawidłowe dane": "",
        "Podano nieprawidłowe dane.": "",
        "Podczas pierszego uruchomienia wymagane jest utworzenie konta głównego administratora.": "",
        "Podgląd": "",
        "Podgląd formularza": "",
        "Podgląd jest dostępny jedynie po dodaniu treści.": "",
        "Podgląd lokalizacji": "",
        "Podgląd lokalizacji dla produktu: %0": "",
        "Podgląd tabeli": "",
        "Podgląd zawartości": "",
        "Podkontrahent": "",
        "Podkreślenie": "",
        "Podpis": "",
        "Podpowiedzi lokalizacji": "",
        "Podstawowa jednostka miary": "",
        "Podstawowe": "",
        "Podstawowe informacje": "",
        "Podstawowe operacje na datach": "",
        "Podstawowe operacje na liczbach": "",
        "Podstawowe operacje tekstowe": "",
        "Podstawowe operacje warunkowe": "",
        "Podstawowy": "",
        "Podziedziałek - Piątek": "",
        "Pojedynczo": "",
        "Pojedynczy wybór": "",
        "Pokaż": "",
        "Pokaż dostawy": "",
        "Pokaż etykietę": "",
        "Pokaż historię": "",
        "Pokaż ilość": "",
        "Pokaż lokalizację": "",
        "Pokaż mniej": "",
        "Pokaż nośnik": "",
        "Pokaż nośnik końcowy": "",
        "Pokaż nośnik początkowy": "",
        "Pokaż numery stron": "",
        "Pokaż partię": "",
        "Pokaż powiązane dokumenty": "",
        "Pokaż produkty, których wystarczy na mniej niż": "",
        "Pokaż produkty, których wystarczy na więcej niż": "",
        "Pokaż przycisk ``Wyślij``": "",
        "Pokaż stany niezerowe": "",
        "Pokaż szczegóły": "",
        "Pokaż tylko liderów": "",
        "Pokaż tylko moje dokumenty": "",
        "Pokaż tytuł formularza": "",
        "Pokaż tytuł sekcji": "",
        "Pokaż tytuł strony": "",
        "Pokaż tytuły stron": "",
        "Pokaż w koncie użytkownika": "",
        "Pokaż więcej": "",
        "pokaż więcej...": "",
        "Pokaż wszystkie": "",
        "Pokaż wszystkie plany": "",
        "Pokaż wykres": "",
        "Pokaż z rezerwacjami": "",
        "Pokaż zdjęcie": "",
        "Pola dokumentu": "",
        "Pola własne": "",
        "Pole \"%0\" jest wymagane.": "",
        "Pole \"%0\" nie zawiera prawidłowego adresu email.": "",
        "Pole \"%0\" nie zawiera prawidłowego adresu URL.": "",
        "Pole \"%0\" zawiera niedozwoloną wartość.": "",
        "Pole \"Drugie imię\" jest wymagane.": "",
        "Pole \"Imię\" jest wymagane.": "",
        "Pole \"Nazwisko\" jest wymagane.": "",
        "Pole \"Tytuł\" jest wymagane.": "",
        "Pole dokumentu": "",
        "Pole jest wymagane": "",
        "Pole może zawierać jedynie cyfry.": "",
        "Pole może zawierać jedynie litery i cyfry.": "",
        "Pole może zawierać jedynie litery.": "",
        "Pole może zawierać tylko ciało metody walidującej dane w postaci natywnego kodu JavaScript.": "",
        "Pole nie może zawierać spacji": "",
        "Pole skopiowane": "",
        "Pole typu \"%0\" jest niedozwolone w tym formularzu.": "",
        "Pole wymagane": "",
        "Pole zawiera": "",
        "Polityka prywatności": "",
        "Polski": "",
        "Pomoc": "",
        "Pomyślne odświeżono dane licencyjne": "",
        "Pomyślne przypisano aplikację do klucza licencyjnego": "",
        "Pomyślnie anulowano dokument.": "",
        "Pomyślnie przekazano na magazyn dokument.": "",
        "Pomyślnie usunięto przesyłkę": "",
        "Pomyślnie zakończono inwentaryzację cząstkową.": "",
        "Pomyślnie zakończono kontrolę.": "",
        "Pomyślnie zakończono pakowanie.": "",
        "Pomyślnie zakończono przesunięcie.": "",
        "Pomyślnie zakończono przyjęcie.": "",
        "Pomyślnie zakończono wydanie.": "",
        "Pomyślnie zakończono zbieranie.": "",
        "Pomyślnie zakończono zwrot.": "",
        "Pomyślnie zaktualizowano kolejność lokalizacji": "",
        "Pomyślnie zaktualizowano kolejność rzędów": "",
        "Pomyślnie zaktualizowano kolejność stref.": "",
        "Pomyślnie zaktualizowano wykluczenie.": "",
        "Pomyślnie zapisano zmiany.": "",
        "Pomyślnie zeskanowano paczkę": "",
        "Pomyślnie zlecono zakończenie inwentaryzacji zbiorczej.": "",
        "Ponowiono zadanie.": "",
        "Ponów": "",
        "Poprawne": "",
        "Poprawnie zeskanowano pozostałe paczki.": "",
        "Poprawny": "",
        "Poprzednia strona": "",
        "Porównaj %0": "",
        "Porównaj z edytowaną wersją": "",
        "Porównania": "",
        "Porównanie": "",
        "Porównywana wersja": "",
        "Port": "",
        "Posiada załączniki": "",
        "Post": "",
        "POST: Lista wpisów (dowolne wyszukiwanie)": "",
        "POST: Upload załączników": "",
        "POST: Utworzenie wpisu": "",
        "POST: Zapisanie zmian": "",
        "Postęp zamykania inwentaryzacji": "",
        "Potwierdzam": "",
        "Potwierdzenia": "",
        "Potwierdzenie": "",
        "Potwierdzenie anulowania": "",
        "Potwierdzenie przeinstalowania": "",
        "Potwierdzenie przekazania": "",
        "Potwierdzenie przywrócenia": "",
        "Potwierdzenie usunięcia": "",
        "Potwierdzenie usunięcia lokalizacji {0}": "",
        "Potwierdzenie usunięcia poziomu {0}": "",
        "Potwierdzenie usunięcia przęsła {0}": "",
        "Potwierdzenie wylogowania użytkownika": "",
        "Potwierdzenie zakończenia": "",
        "Potwierdzenie zmiany statusu": "",
        "Potwierdź": "",
        "Potwierdź akceptację": "",
        "Potwierdź usunięciennOperacja jest nieodwracalna. Czy na pewno chcesz usunąć wybrany element?": "",
        "Powiadomienia": "",
        "Powiadomienia zostały skonfigurowane.": "",
        "Powiadomienie jest domyślnie wymagane. Nie da się wyłączyć wysyłania tego powiadomienia.": "",
        "Powiadomienie testowe zostało wysłane.": "",
        "Powiązana": "",
        "Powiązanie stref z magazynem ERP": "",
        "Powiększ": "",
        "Powinno się dodać dyrektywę PRIVATE dla treści personalizowanych dla użytkownika, zwłaszcza dla odpowiedzi otrzymywanych po zalogowaniu i z uwzględnieniem uprawnień.": "",
        "powrót": "",
        "Powrót": "",
        "Powtórz hasło": "",
        "Powtórzone": "",
        "Powyższe obiekty zawierają tylko właściwości i są pozbawione wszystkich metod.": "",
        "Poziom": "",
        "Poziom dostępu do webhooka": "",
        "Poziom dostępu użytkownika": "",
        "Poziom logowania błedów": "",
        "Poziom został dodany.": "",
        "Poziom został usunięty.": "",
        "Poziomy logowania": "",
        "Pozostało %0 znaków.": "",
        "Pozycja": "",
        "Pozycja edytowana pomyślnie.": "",
        "Pozycja o takich parametrach już istnieje.": "",
        "Pozycja została dodana.": "",
        "Pozycja została usunięta.": "",
        "Pozycje rozłożone poprawnie.": "",
        "Pozycje zostały dodana.": "",
        "pół roku": "",
        "Półmiesięczny": "",
        "Prawy panel": "",
        "Predefiniowane lokalizacje dla produktu": "",
        "Predefiniowane tła panelu z aplikacjami": "",
        "Predefiniowane zestawy kolorów": "",
        "Priorytet": "",
        "Priorytet kolejki": "",
        "private (tylko cache przeglądarki)": "",
        "Procent": "",
        "Proces został zaktualizowany.": "",
        "Procesy": "",
        "Produkt": "",
        "Produkt %0 - %1 został już dodany z inną jednostką miary": "",
        "Produkt został dodany.": "",
        "Produkt został przyjęty.": "",
        "Produkt został usunięty.": "",
        "Produkt został wydany.": "",
        "Produkt został zaktualizowany.": "",
        "Produkty": "",
        "Profil użytkownika": "",
        "Profil został zaktualizowany.": "",
        "Projekt": "",
        "Prometheus": "",
        "Proporcje obrazu": "",
        "Proste uprawnienia": "",
        "Próbkowanie": "",
        "Prywatne": "",
        "Przebudowanie indeksów": "",
        "Przebudowanie indeksównnOperacja może trwać od kilku sekund do kilku godzin w zależności od rozmiaru indeksów.nnNależy używać tylko w przypadku konieczności zaaplikowania zmian dokonanych w słownikach synonimów lub słów wykluczonych.": "",
        "Przeciągnij i upuść aby posortować pliki": "",
        "Przeczytane": "",
        "Przedrostek": "",
        "Przeindeksowanie danych": "",
        "Przeindeksowanie danychnnOperacja może trwać od kilku sekund do kilku godzin w zależności od ilości danych w bazie.nnNależy używać tylko w przypadku zauważenia braku danych w indeksach.": "",
        "Przeinstalowano poprawnie": "",
        "Przeinstaluj": "",
        "Przejdź do konfiguracji, aby włączyć narzędzia": "",
        "Przekazano": "",
        "Przekazano do magazynu poprawnie": "",
        "Przekaż do magazynu": "",
        "Przekaż na magazyn": "",
        "Przekroczono dozwoloną ilość znaków: %0.": "",
        "przekroczony dozwolony rozmiar pliku (%0 MB)": "",
        "Przełącznik": "",
        "Przeniesienie wybranych elementów": "",
        "przeniesiony do kosza": "",
        "Przenieś": "",
        "Przenieś${selected.length > 1 ? ' zaznaczone' : ''}": "",
        "przeniósł do kosza": "",
        "Przepisz aktualne stawki za 1000 tokenów z cennika OpenAI.": "",
        "Przepisz limit konta OpenAI.": "",
        "Przepnij alias": "",
        "Przerwano": "",
        "Przerwano kontrolę.": "",
        "Przerwano pracę nad dokumentem.": "",
        "Przerwano pracę nad wydaniem.": "",
        "Przerwij": "",
        "Przesunięcia": "",
        "Przesunięcie": "",
        "Przesunięcie zakończone.": "",
        "Przesuń": "",
        "Przesuń do góry": "",
        "Przesuń na dół": "",
        "Przesuń towary": "",
        "Przesuń w lewo": "",
        "Przesuń w prawo": "",
        "Przesyłanie plików powiodło się.": "",
        "Przetestuj": "",
        "Przetestuj poprawność zapisanych kluczy": "",
        "Przetestuj ustawienia poczty": "",
        "Przetestuj ustawienia Sms": "",
        "Przetwarzane": "",
        "Przęsło": "",
        "Przęsło ": "",
        "Przęsło zostało dodane.": "",
        "Przęsło zostało usunięte.": "",
        "Przybornik": "",
        "Przycisk": "",
        "Przyjęcia": "",
        "Przyjęcia awizowane": "",
        "Przyjęcia awizowane (Akceptacje)": "",
        "Przyjęcia bezpośrednie": "",
        "Przyjęcia bezpośrednie (Akceptacje)": "",
        "Przyjęcie": "",
        "Przyjęcie (inwentaryzacja)": "",
        "Przyjęcie bezpośrednie zostało dodane": "",
        "Przyjmij": "",
        "Przyjmij/wydaj": "",
        "Przykład": "",
        "Przykład:": "",
        "Przykład: domena\\użytkownik": "",
        "Przykład: użytkownik@domena": "",
        "Przykładowa struktura danych": "",
        "Przykłady": "",
        "Przypisanie do zespołu": "",
        "Przypisanie ról": "",
        "Przypisz": "",
        "Przypisz aplikację": "",
        "Przypisz opakowanie": "",
        "Przyrostek": "",
        "Przywrócenie": "",
        "przywrócił z kosza": "",
        "przywrócony z kosza": "",
        "Przywróć": "",
        "Przywróć domyślne": "",
        "Przywróć filtr": "",
        "Przywróć ustawienia domyślne reguł i celów": "",
        "Przyznaj dostęp": "",
        "public (cache przeglądarki i proxy)": "",
        "Publiczne": "",
        "Pusty rząd": "",
        "Put": "",
        "PUT: Aktualizacja wpisu": "",
        "PW": "",
        "PW – przyjęcie wewnętrzne": "",
        "Pytania": "",
        "PZ – przyjęcie zewnętrzne": "",
        "QR ": "",
        "Query string": "",
        "Radio": "",
        "Ranga": "",
        "Raport": "",
        "Raport sprzedażowy": "",
        "Raport sprzedaży": "",
        "Raport XYZ": "",
        "Raport został dodany.": "",
        "Raport został zaktualizowany.": "",
        "Raporty": "",
        "Raporty AI": "",
        "Raporty systemowe": "",
        "Realizacja": "",
        "Realizacja zlecenia przesunięcia": "",
        "ReCaptcha": "",
        "Region": "",
        "Reguła": "",
        "Reguła filtracyjna": "",
        "Reguła końcowa": "",
        "Reguła został dodana.": "",
        "reguła została usunięta.": "",
        "Reguła została zaktualizowana.": "",
        "Reguły": "",
        "Rejestr zmian": "",
        "Relacyjne": "",
        "Repozytorium plików": "",
        "Request body": "",
        "Resetuj": "",
        "Resetuj układ": "",
        "REST": "",
        "Rezerwacja": "",
        "Rezerwacje": "",
        "Rezerwacje kontroli jakości": "",
        "Rodzaj": "",
        "Rodzaj etykiety": "",
        "Rodzaj lokalizacji": "",
        "Rodzaj nośnika": "",
        "Rodzaj nośnika został dodany": "",
        "Rodzaj nośnika został usunięty.": "",
        "Rodzaj nośnika został zaktualizowany": "",
        "Rodzaj odpowiedzi": "",
        "Rodzaj przesyłki": "",
        "Rodzaj użytego narzędzia do wygenerowanej treści.": "",
        "Rodzaje lokalizacji": "",
        "rok": "",
        "Rok": "",
        "Rola": "",
        "Rola aktywna": "",
        "Rola domyślna": "",
        "Rola została dodana.": "",
        "Rola została usunięta.": "",
        "Rola została zaktualizowana.": "",
        "Role i uprawnienia": "",
        "Role użytkownika": "",
        "Role zostały przypisane": "",
        "Root": "",
        "Rozdzielczość": "",
        "Rozdzielczość wygnerowanego obrazu.": "",
        "Rozkładanie": "",
        "Rozłożono pomyślnie": "",
        "Rozłóż towary": "",
        "Rozmiar": "",
        "Rozmiar okna popup": "",
        "Rozmiar pliku": "",
        "Rozmiar wydruku": "",
        "Rozmiar zdjęcia został zmieniony.": "",
        "Rozmieść równomiernie": "",
        "Rozpoczęto kontrolę.": "",
        "Rozpoczęto pracę nad dokumentem.": "",
        "Rozpoczęto pracę nad wydaniem.": "",
        "Rozpocznij": "",
        "Rozpocznij kontrolę": "",
        "Rozpocznij pakowanie": "",
        "Rozpocznij przesunięcie": "",
        "Rozpocznij załadunek": "",
        "Rozpocznij zbieranie": "",
        "Rozszerzony - błedy ważne": "",
        "Równość": "",
        "RW": "",
        "RW – rozchód wewnętrzny": "",
        "Rząd": "",
        "Rząd został dodany.": "",
        "Rzeczywista wartość sprzedaży": "",
        "Rzędy": "",
        "Satysfakcja": "",
        "Schemat kodu lokalizacji": "",
        "Schemat kodu QR": "",
        "Sekcja": "",
        "Sentry": "",
        "Separator": "",
        "Server Side Cache": "",
        "Serwer bazy danych": "",
        "Serwer dostępny": "",
        "Serwer plików": "",
        "Serwer został dodany.": "",
        "Serwer został usunięty.": "",
        "Serwer został zaktualizowany.": "",
        "Serwery plików": "",
        "Sesja": "",
        "Sesja nr %0": "",
        "Sesja użytkownika": "",
        "Sierpień": "",
        "Skala 1-5": "",
        "Skaner": "",
        "Skanowanie": "",
        "Sklep": "",
        "Skontrolowano produkty.": "",
        "Skrót został dodany": "",
        "Skrót został usunięty": "",
        "słowa": "",
        "Słownik": "",
        "Słownik dowolny": "",
        "Słownik systemowy": "",
        "Słowo": "",
        "Słowo bazowe": "",
        "Słowo wykluczone": "",
        "Słowo zostało dodane.": "",
        "Słowo zostało usunięte.": "",
        "Słowo zostało zaktualizowane.": "",
        "Sortowanie malejąco": "",
        "Sortowanie rosnąco": "",
        "Spakuj wszystkie": "",
        "Specjalizowane": "",
        "Specyfikacja": "",
        "Spełnia kryteria do kontroli jakości": "",
        "Sposób dostawy": "",
        "Sposób prezentacji zakładek": "",
        "Sposób przeliczania zajętości lokalizacji": "",
        "Sprawdzenie czy pusty": "",
        "Sprawdzono:": "",
        "Sprawdź": "",
        "Sprawdź ilość:": "",
        "Sprawdź zgodność:": "",
        "Sprzedaż z okresu (dni)": "",
        "SQL": "",
        "Stała wartość": "",
        "Stan": "",
        "Stan minimalny": "",
        "Stan powiadomienia": "",
        "Stan przed": "",
        "Standardowa szczegółowość": "",
        "Standardowe uprawnienia": "",
        "Stanowisko": "",
        "Stanowisko zostało zwolnione": "",
        "Stany ERP": "",
        "Start": "",
        "Status": "",
        "Status dokumentu został zmieniony.": "",
        "Status edytowanej wersji dokumentu": "",
        "Status formularza został zmieniony.": "",
        "Status modułu": "",
        "Status webhooka": "",
        "Stopka": "",
        "Strefa": "",
        "Strefa została dodana.": "",
        "Strefa została usunięta.": "",
        "Strefa została zaktualizowana.": "",
        "Strefy": "",
        "Strona": "",
        "Strona %0": "",
        "Strona główna": "",
        "Struktura organizacyjna": "",
        "Stwierdzenie": "",
        "Styczeń": "",
        "Styl obrazu": "",
        "Sugerowana lokalizacja": "",
        "Sugerowane nośniki": "",
        "Sukces": "",
        "Suma danych wejściowych i wyjściowych podana w tokenach.": "",
        "Suma na lokalizacji": "",
        "Suma na magazynie": "",
        "Suma obj. zamówienia": "",
        "Suma sprzedaży": "",
        "Suma tokenów": "",
        "Suma wagi zamówienia": "",
        "Symbol": "",
        "Symbol jest wymagany": "",
        "Symbol jest zajęty": "",
        "Symbol klienta": "",
        "Symbol musi mieć co najmniej 3 znaki": "",
        "Symbol musi mieć co najmniej 5 znaków": "",
        "Symbol nie może być dłuższy niż 10 znaków": "",
        "Symbol nie może być dłuższy niż 20 znaków": "",
        "Symbol produktu": "",
        "Symbol w adresie URL": "",
        "Symbol:": "",
        "Symbol/Nazwa/EAN": "",
        "Synonimy": "",
        "Synonimy zostały dodane.": "",
        "Synonimy zostały usunięte.": "",
        "Synonimy zostały zaktualizowane.": "",
        "System": "",
        "Szablon modułu": "",
        "Szablon został dodany.": "",
        "Szablon został usunięty.": "",
        "Szablon został zaktualizowany.": "",
        "Szablony etykiet": "",
        "Szacowana kwota:": "",
        "Szczególy": "",
        "Szczegółowe": "",
        "Szczegółowość obrazu": "",
        "Szczegółowy - błedy krytyczne": "",
        "Szczegóły": "",
        "Szczegóły asystenta": "",
        "Szczegóły lokalizacji": "",
        "Szczegóły pliku": "",
        "Szczegóły procesu": "",
        "Szczegóły zadania": "",
        "Szczegóły zapytania": "",
        "Szczegóły zdarzenia": "",
        "Szczegóły zdarzenia nr %0": "",
        "Szerokość": "",
        "Szerokość (%0)": "",
        "Szerokość (mm)": "",
        "Szerokość [%0]": "",
        "Szerokość strony": "",
        "Szkic": "",
        "szt.": "",
        "Sztuczna inteligencja": "",
        "Szukaj": "",
        "Szukaj we wszystkich wersjach": "",
        "Szukana fraza": "",
        "Szukane słowo": "",
        "Szybkie przesunięcia": "",
        "Szyfruj": "",
        "Średni": "",
        "Średnia arytmetyczna": "",
        "Średnia sprzedaż z X dni": "",
        "Środowisko": "",
        "Ta strona jest chroniona przez reCAPTCHA i Google.": "",
        "Tabela": "",
        "Tagi": "",
        "Tak": "",
        "TAK": "",
        "Tak/Nie": "",
        "Tekst": "",
        "Tekst alternatywny": "",
        "Tekst zastępczy": "",
        "Telefon": "",
        "Ten dashboard nie zawiera jeszcze żadnych widgetów": "",
        "Ten element nie zawiera menu kontekstowego.": "",
        "Ten kanał ma możliwość wysyłania tekstu sformatowanego.": "",
        "Ten kanał nie ma możliwości wysyłania tekstu sformatowanego.": "",
        "Ten produkt został zeskanowany w innym dokumencie inwentaryzacyjnym.": "",
        "Teraz": "",
        "Termin dostawy": "",
        "Termin kolejnej dostawy": "",
        "Termin realizacji": "",
        "Termin relizacji": "",
        "Termin relizacji:": "",
        "Terminy do słownika będzie można dodawać dopiero po zapisaniu źródła danych.": "",
        "Test konfiguracji": "",
        "Test ustawień pomyślnie zakończony.": "",
        "Testowy adres email": "",
        "Testowy numer telefonu": "",
        "Testuj": "",
        "Testuj zapytanie": "",
        "Tłumaczenia": "",
        "To pole jest wymagane": "",
        "Token": "",
        "Token dostępu do konta. Znajduje się w zakładce `Keys & IDs`": "",
        "Token PAT": "",
        "Token został usunięty.": "",
        "Token został zapisany.": "",
        "Tokeny": "",
        "Tokeny wejściowe": "",
        "Tokeny wyjściowe": "",
        "Towar": "",
        "Towar zamówiony": "",
        "Towary": "",
        "Trace - najwięcej szczegółów": "",
        "Tracing": "",
        "Treści odpowiedzi są wymagane": "",
        "Treści pytań są wymagane": "",
        "Treść": "",
        "Treść błędu:.": "",
        "Treść szablonu": "",
        "Trwa generowanie dokumentu ...": "",
        "Trwa generowanie obrazów": "",
        "Trwa generowanie obrazu": "",
        "Trwa generowanie opisu dla wybranego obrazu": "",
        "Trwa generowanie zapytania. Proszę czekać": "",
        "Trwa pobieranie PDF ...": "",
        "Trwa sprawdzanie statusu": "",
        "Tryb konserwacji systemu": "",
        "Trzy kolumny": "",
        "Twoje hasło wygasło. Zmień je na nowe.": "",
        "Tworzenie dokumentów": "",
        "Tworzenie inwentaryzacji zbiorczej": "",
        "Tworzenie przyjęcia bezpośredniego": "",
        "Tworzenie wydania bezpośredniego": "",
        "Tworzenie zlecenia przesunięcia": "",
        "Twój token": "",
        "tydzień": "",
        "Tydzień": "",
        "Tylko aktywne źródła": "",
        "Tylko do odczytu": "",
        "tylko dokumenty wersjonowane": "",
        "Tylko serwer deweloperski. Nie używać w trybie produkcyjnym. ": "",
        "Typ": "",
        "Typ inwentaryzacji": "",
        "Typ kolumny": "",
        "Typ konta": "",
        "Typ logowania": "",
        "Typ mime": "",
        "Typ nośnika": "",
        "Typ obiektu": "",
        "Typ pliku": "",
        "Typ pola": "",
        "Typ powiadomienia": "",
        "Typ przyjęcia": "",
        "Typ ruchu": "",
        "Typ serwera": "",
        "Typ słownika": "",
        "Typ właściwy": "",
        "Typ wydania": "",
        "Typ zapytania": "",
        "Typ został dodany.": "",
        "Typ został usunięty.": "",
        "Typ został zaktualizowany.": "",
        "Typ źródła": "",
        "Typ źródła danych": "",
        "typu \"%0\" o ID \"%1\"": "",
        "Typy stref": "",
        "Tytuł": "",
        "Tytuł formularza jest wymagany": "",
        "Tytuł formularza nie może być dłuższy niż %0 znaków": "",
        "Tytuł sekcji": "",
        "Udało się zapisać ustawienia widgetów": "",
        "Układ": "",
        "Układ listy": "",
        "Układ zapisany poprawnie.": "",
        "Ukryj ilość": "",
        "Ukryj lokalizację": "",
        "Ukryj nośnik": "",
        "Ukryj nośnik końcowy": "",
        "Ukryj nośnik początkowy": "",
        "Ukryj partię": "",
        "Ukryta": "",
        "Ukryty": "",
        "Ulica": "",
        "Umieszczenie towarów w lokalizacji": "",
        "Upload": "",
        "Uprawnienia": "",
        "Uprawnienia do dokumentów": "",
        "Url": "",
        "Uruchom": "",
        "Uruchom zadanie": "",
        "Urządzenie zostało usunięte.": "",
        "Usługi": "",
        "Ustaw szerokości proporcjonalnie": "",
        "Ustawienia": "",
        "Ustawienia konta": "",
        "Ustawienia nośników": "",
        "Ustawienia powiadomień PUSH": "",
        "Ustawienia powiadomień zostały zaktualizowane.": "",
        "Ustawienia sesji": "",
        "ustawienia widgetu": "",
        "Ustawienia zaawansowane": "",
        "Ustawienia zostały zapisane": "",
        "Ustawienia zostały zapisane.": "",
        "usunął": "",
        "Usunął": "",
        "Usunięcie": "",
        "Usunięcie dokumentu": "",
        "Usunięcie formularzannOperacja jest nieodwracalna. Zostaną utracone wszystkie powiązane dokumenty!nnCzy na pewno chcesz usunąć wybrany formularz?": "",
        "Usunięcie indeksunnOperacja jest nieodwracalna. Czy na pewno chcesz usunąć wybrany indeks?": "",
        "Usunięcie kategorii spowoduje również usunięcie wszystkich powiązanych z nią formularzy.nnCzy na pewno kontynuować?": "",
        "Usunięcie wybranych elementów": "",
        "Usunięto dokument z agregacji.": "",
        "Usunięto dokument.": "",
        "Usunięto paczkę z załadunku.": "",
        "usunięty": "",
        "Usuń": "",
        "Usuń datę": "",
        "Usuń dział": "",
        "Usuń filtr": "",
        "Usuń grupę": "",
        "Usuń indeks": "",
        "Usuń kategorię": "",
        "Usuń kolejkę": "",
        "Usuń lokalizacje {0}": "",
        "Usuń lokalizację": "",
        "Usuń plik": "",
        "Usuń plik z listy": "",
        "Usuń poziom {0}": "",
        "Usuń pozycje": "",
        "Usuń pozycję": "",
        "Usuń produkt": "",
        "Usuń przęsło {0}": "",
        "Usuń sekcję": "",
        "Usuń skrót": "",
        "Usuń spacje": "",
        "Usuń stanowisko": "",
        "Usuń urządzenie": "",
        "Usuń urządzenie aby odwołać zapamiętane automatyczne logowanie na swoje konto": "",
        "Usuń warunek": "",
        "Usuń z zespołu": "",
        "Usuń zdjęcie": "",
        "Usuń${selected.length > 1 ? ' zaznaczone' : ''}": "",
        "Usuwanie dokumentów": "",
        "Usuwanie użytkowników": "",
        "Usuwanie własnych dokumentów": "",
        "Usuwanie wszystkich dokumentów": "",
        "Utworzył": "",
        "Utwórz": "",
        "Utwórz grupy": "",
        "Utwórz katalog": "",
        "Utwórz kopię formularza": "",
        "Utwórz kopię z": "",
        "Utwórz nową inwentaryzację zbiorczą": "",
        "Utwórz nowy": "",
        "Utwórz nowy wątek": "",
        "Utwórz zlecenie": "",
        "Uwaga": "",
        "UWAGA: Brak obsługi TypeScript oraz w kodzie metody nie można robić importów.": "",
        "Uwagi": "",
        "Uwagi do dokumentu wydania": "",
        "Uwagi do inwentaryzacji zbiorczej": "",
        "Uwagi do przesunięcia": "",
        "Uwagi dokumentu": "",
        "Uwagi:": "",
        "Uwzględnij wydziały podrzędne": "",
        "Uzupełnij wagę": "",
        "Uzupełnij wymiary": "",
        "Użyj": "",
        "Użyte narzędzie": "",
        "Użytkownicy": "",
        "Użytkownicy w tej roli": "",
        "Użytkownik": "",
        "Użytkownik \"%0\" %1 obiekt %2": "",
        "Użytkownik jest liderem zespołu": "",
        "Użytkownik nie może się zalogować dopóki konto nie jest aktywne.": "",
        "Użytkownik nie należy do żadnego zespołu.": "",
        "Użytkownik posiada pełne uprawnienia": "",
        "Użytkownik został dodany do zespołu.": "",
        "Użytkownik został dodany.": "",
        "Użytkownik został usunięty.": "",
        "Użytkownik został wylogowany.": "",
        "Użytkownik został zaktualizowany.": "",
        "Użyty Model": "",
        "W ciele metody walidującej dostępne są poniższe zmienne:": "",
        "W każdy dzień tygodnia": "",
        "W organizacji nie ma jeszcze żadnych działów.": "",
        "W przygotowaniu": "",
        "W przyszłości": "",
        "W trakcie": "",
        "W trakcie zbierania": "",
        "W zespole od %0": "",
        "W zespole od %0 do %1": "",
        "Waga": "",
        "Waga (%0)": "",
        "Waga całk.": "",
        "Waga została zaktualizowana.": "",
        "Walidacja": "",
        "Walidacja certyfikatu SSL": "",
        "Walidacja danych": "",
        "Walidacja po stronie API": "",
        "Walidacja umieszczania towarów na lokalizacji": "",
        "Wariant": "",
        "Warn - ostrzeżenia": "",
        "Wartości od 0 - 1.0 (0 oznacza 0%, a 1 oznacza 100%)": "",
        "Wartości rzeczywiste": "",
        "Wartość": "",
        "Wartość domyślna": "",
        "Wartość domyślna: (&(objectClass=organizationalUnit))": "",
        "Wartość domyślna: (&(objectClass=user)": "",
        "Wartość musi być liczbą całkowitą": "",
        "Wartość musi być liczbą dodatnią": "",
        "Wartość musi być liczbą ujemną": "",
        "Wartość procentowej sprzedaży": "",
        "Wartość: %0": "",
        "Warunki korzystania z usług Google": "",
        "Warunki logiczne": "",
        "Warunkowo": "",
        "Ważne informacje": "",
        "Ważność": "",
        "Wątek": "",
        "Wątek główny": "",
        "Wątek główny jest kontynuowany automatycznie przez asystenta.": "",
        "Webhook został dodany.": "",
        "Webhook został usunięty.": "",
        "Webhook został zaktualizowany.": "",
        "Weekend": "",
        "Wersja": "",
        "Wersja API": "",
        "Wersja utowrzona przez": "",
        "Wersje": "",
        "Wersje dokumentu": "",
        "wersji zaakceptowanej": "",
        "Wersjonowanie dokumentów": "",
        "Wersjonowanie wpisów": "",
        "Wertykalna": "",
        "Wewnętrznie": "",
        "Wiadomość testowa została wysłana.": "",
        "Wiadomość...": "",
        "Widgety": "",
        "Widoczność": "",
        "Widoczność modułu w menu": "",
        "Widoczność sekcji w menu modułu": "",
        "Widok ustawień": "",
        "Wielkość kroku": "",
        "Wielokrotny wybór": "",
        "Wiersz": "",
        "Więcej": "",
        "więcej...": "",
        "Więcej...": "",
        "Wklej komponent": "",
        "Właściwości": "",
        "Właściwości szablonu": "",
        "Włącz": "",
        "Włącz system powiadomień": "",
        "Włącz walidację po stronie API": "",
        "Włączenie moderacji danych wejściowych jest zalecane.": "",
        "Włączone": "",
        "Włączony": "",
        "WMS": "",
        "Województwo": "",
        "Wpis domyślny": "",
        "Wpis został dodany.": "",
        "Wpis został usunięty.": "",
        "Wpis został zaakceptowany.": "",
        "Wpis został zaktualizowany.": "",
        "Wpisz kod lokalizacji": "",
        "Wpisz wiadomość": "",
        "Wprowadzamy URL do seriwsów które chemy wykluczyć. Dane mogą być odzielone przecinkiem lub być w nowej linii": "",
        "Wprowadzona wartość jest nieprawidłowa": "",
        "Wprowadzona wartość jest za długa": "",
        "Wprowadzona wartość jest za krótka": "",
        "Wprowadzona wartość jest za niska": "",
        "Wprowadzona wartość jest za wysoka": "",
        "Wprowadzona wartość nie jest poprawna": "",
        "Wróć do logowania": "",
        "Wróć do strony głównej": "",
        "Wrzesień": "",
        "Wskazuje, jak długo odpowiedź pozostaje aktualna w pamięci serwera Redis.": "",
        "Współczynnik zmienności": "",
        "Wstaw": "",
        "Wstaw cztery kolumny 1/4": "",
        "Wstaw dwie kolumny 1/3 oraz 2/3": "",
        "Wstaw dwie kolumny 2/3 oraz 1/3": "",
        "Wstaw dwie równe kolumny": "",
        "Wstaw kod kreskowy": "",
        "Wstaw kontrolkę": "",
        "Wstaw logo": "",
        "Wstaw pole dokumentu": "",
        "Wstaw stronę": "",
        "Wstaw szablon informacyjny ze zdjęciem": "",
        "Wstaw trzy kolumny 1/3": "",
        "Wstaw z dołu": "",
        "Wstaw z góry": "",
        "Wstaw z lewej strony": "",
        "Wstaw z prawej strony": "",
        "Wstaw zdjęcie": "",
        "Wstecz": "",
        "Wstępne filtrowanie danych": "",
        "Wszystkie": "",
        "Wszystkie formularze": "",
        "Wszystkie niezapisane zmiany zostaną utracone.": "",
        "Wszystkie próby": "",
        "wybierz": "",
        "Wybierz": "",
        "wybierz długość": "",
        "Wybierz formularz zawierający dodatkowe pola dla kont użytkownków": "",
        "Wybierz katalog do którego chcesz przenieść wybrane elementy.": "",
        "wybierz model": "",
        "Wybierz plik": "",
        "Wybierz plik lub katalog": "",
        "Wybierz plik.": "",
        "Wybierz pliki": "",
        "Wybierz pliki (do {0})": "",
        "Wybierz rodzaj nośnika": "",
        "Wybierz serwer": "",
        "Wybierz shortcode": "",
        "Wybierz typ powiadomienia aby zobaczyć dostępne klucze.": "",
        "Wybierz użytkownika": "",
        "Wybierz użytkowników dla których chcesz wykonać akcje": "",
        "Wybierz wątek w którym chcesz prowadzić konwersację.": "",
        "Wybierz zaznaczone": "",
        "wybierz...": "",
        "Wybierz...": "",
        "Wybór": "",
        "Wybór języka": "",
        "Wybór+": "",
        "Wybrana data jest mniejsza niż dozwolona %0.": "",
        "Wybrana data jest większa niż dozwolona %0.": "",
        "Wybrana drukarka: %0": "",
        "Wybrana liczba jest mniejsza niż dozwolona %0.": "",
        "Wybrana liczba jest większa niż dozwolona %0.": "",
        "Wybrane": "",
        "Wybrane %0 na %1": "",
        "Wybrane dokumenty": "",
        "Wybrane elementy zostały przeniesione.": "",
        "Wybrane elementy zostały usunięte.": "",
        "Wybrane pliki": "",
        "Wybrane pliki: {0}": "",
        "Wybrano %0 użytkowników.": "",
        "Wybrano dostawcę Azure AI.": "",
        "Wybrano dostawcę Azure OpenAI.": "",
        "Wybrano dostawcę OpenAI.": "",
        "Wybrano jednego użytkownika.": "",
        "Wybrany język": "",
        "Wybróbuj możliwości AI": "",
        "Wyczyść": "",
        "Wydaj": "",
        "Wydania": "",
        "Wydania (Akceptacje)": "",
        "Wydania (Zależy od aktywnego procesu)": "",
        "Wydania bezpośrednie": "",
        "Wydania do kuriera (Zależy od aktywnego procesu)": "",
        "Wydania do kurierów": "",
        "Wydanie": "",
        "Wydanie (inwentaryzacja)": "",
        "Wydanie bezpośrednie zostało dodane": "",
        "Wydruk": "",
        "Wydruk etykiety": "",
        "Wydrukuj dokument": "",
        "Wydział": "",
        "Wygenerowana odpowiedź przez model AI.": "",
        "Wygenerowane": "",
        "Wygenerowane obrazy": "",
        "Wygenerowano dokument.": "",
        "Wygeneruj metadane w wybranym języku dla przesyłanych obrazów": "",
        "Wygeneruj zapytanie poniżej": "",
        "Wyjdź": "",
        "Wyklucz logowanie zależności": "",
        "Wykluczona": "",
        "Wykonaj": "",
        "Wykorzystane": "",
        "Wykres": "",
        "Wylicz": "",
        "Wyliczenia XYZ": "",
        "Wyloguj się": "",
        "Wyloguj zalogowanego obecnie użytkownika": "",
        "Wyłaczone": "",
        "Wyłącz": "",
        "Wyłączone": "",
        "Wymagaj znaków specjalnych w hasłach": "",
        "Wymagane": "",
        "Wymagane jest ustawienie minimalnej długości hasła": "",
        "Wymagane jest wybranie formularza": "",
        "Wymagane jest wybranie kategorii": "",
        "Wymagane jest wybranie komponentu": "",
        "Wymagane jest wybranie modułu": "",
        "Wymagane jest wybranie sekcji": "",
        "Wymagane jest wybranie źródła danych": "",
        "Wymagania": "",
        "Wymiary": "",
        "Wymiary zostały zaktualizowane.": "",
        "Wymuś zmianę hasła": "",
        "Wynik testu": "",
        "Wyniki testu zawszę będą ograniczone do maksymalnie 10 pozycji.": "",
        "Wyrażenia warunkowe": "",
        "Wyrażenie regularne": "",
        "Wyrażenie w postaci \"/pattern/flag\" nie jest obsługiwane, użyj same wartości pattern": "",
        "Wysłane": "",
        "Wysoka szczegółowość": "",
        "Wysokość": "",
        "Wysokość (%0)": "",
        "Wysokość (mm)": "",
        "Wysokość [%0]": "",
        "Wysokość strony": "",
        "Wystąpił błąd podczas ładowania obrazu. Spróbuj ponownie.": "",
        "Wystąpił błąd podczas próby exportu użytkowników.": "",
        "Wystąpił błąd podczas próby importu definicji modułu.": "",
        "Wystąpił błąd podczas próby importu użytkowników.": "",
        "Wystąpił błąd połączenia. Upewnij się że masz włączony internet i spróbuj ponownie.": "",
        "Wystąpił błąd przy zapisywaniu stanowisk pakowaczy.": "",
        "Wystąpił błąd serwera podczas obsługiwania wybranej operacji. Spróbuj ponownie.": "",
        "Wystąpił problem drukowania": "",
        "Wystąpił problem podczas przesyłania obrazu.": "",
        "Wystąpił problem, spróbuj ponownie.": "",
        "Wysyłaj dane o użytkowniku": "",
        "Wysyłaj tym kanałem": "",
        "Wyszukaj": "",
        "Wyszukaj (Ctrl+Q)": "",
        "Wyszukaj drukarkę w sieci": "",
        "wyszukaj użytkownika...": "",
        "Wyszukaj wszędzie": "",
        "wyszukaj...": "",
        "Wyszukaj...": "",
        "Wyszukiwanie plików": "",
        "Wyszukiwanie plików umożliwia asystentowi korzystanie z wiedzy z plików. Po przesłaniu pliku asystent automatycznie decyduje, kiedy pobrać treść, na podstawie żądań użytkownika.": "",
        "Wyszukiwany ciąg znaków": "",
        "Wyślij": "",
        "Wyślij do ERP": "",
        "Wyślij kod": "",
        "Wyświetl również zarchiwizowane formularze": "",
        "Wyświetlam": "",
        "Wyświetlanie dokumentów": "",
        "Wyświetlany układ / dane": "",
        "Wytnij": "",
        "Wyzeruj licznik": "",
        "WZ – wydanie zewnętrzne": "",
        "Wzorzec": "",
        "XYZ": "",
        "Z magazynu": "",
        "Z uprawnieniami": "",
        "Z uprawnieniami do modułu": "",
        "Z: %0": "",
        "Zaakceptowany": "",
        "Zaaktualizowano listę.": "",
        "Zaawansowane uprawnienia": "",
        "Zabierz dostęp": "",
        "Zablokowana": "",
        "Zablokowani użytkownicy": "",
        "Zablokowany": "",
        "Zablokuj": "",
        "Zablokuj pole": "",
        "Zachowaj oryginalny format obrazów": "",
        "zacznij pisać aby wyszukać użytkownika...": "",
        "Zacznij pisać Form lub Entry aby uruchomić podpowiadanie.": "",
        "Zaczyna sie od": "",
        "Zadania w tle": "",
        "Zadanie przebudowania indeksów zostało rozpoczęte.": "",
        "Zadanie przeindeksowania danych zostało rozpoczęte.": "",
        "Zadanie zostało uruchomione.": "",
        "Zadanie zostało włączone": "",
        "Zadanie zostało wyłączone": "",
        "Zagregowane": "",
        "Zagrożenie": "",
        "Zaimportowano definicję modułu.": "",
        "Zaimportowano listę użytkowników.": "",
        "Zainstalowane": "",
        "Zainstalowano poprawnie": "",
        "Zainstaluj": "",
        "Zakładki": "",
        "Zakolejkowane": "",
        "Zakończ": "",
        "Zakończ edycje": "",
        "Zakończ inwentaryzację": "",
        "Zakończ inwentaryzację cząstkową": "",
        "Zakończ kontrolę": "",
        "Zakończ pakowanie": "",
        "Zakończ przesunięcie": "",
        "Zakończ przyjęcie": "",
        "Zakończ sesję": "",
        "Zakończ sesję aby wylogować użytkownika": "",
        "Zakończ wydanie": "",
        "Zakończ zbieranie": "",
        "Zakończ zwrot": "",
        "Zakończone": "",
        "Zakres": "",
        "Zakres czasu": "",
        "Zaktualizuj formularz": "",
        "Zależna od kontrolki": "",
        "Zalogowano na stanowisko:": "",
        "Zalogowany": "",
        "Zaloguj się": "",
        "Zaloguj się na swoje konto": "",
        "ZALOGUJ ZA POMOCĄ LOGINU I HASŁA": "",
        "ZALOGUJ ZA POMOCĄ PINU": "",
        "Załaduj wszystkie": "",
        "Załadunek": "",
        "Załączniki": "",
        "Zamiany zostały zapisane.": "",
        "Zamknij": "",
        "Zamów": "",
        "Zamówienia": "",
        "Zamówienie": "",
        "Zamówienie zostało złożone": "",
        "Zaokrąglenie": "",
        "Zapamiętaj logowanie": "",
        "Zapamiętane urządzenia": "",
        "Zapisano": "",
        "Zapisano poprawnie": "",
        "Zapisano poprawnie etykietę.": "",
        "Zapisano zmiany.": "",
        "Zapisuj do celu": "",
        "Zapisywanie obrazów zakończone.": "",
        "Zapisz": "",
        "Zapisz filtr": "",
        "Zapisz i przekaż do realizacji": "",
        "Zapisz i wróć": "",
        "Zapisz i zamknij": "",
        "Zapisz raport, aby nadać uprawnienia": "",
        "Zapisz układ": "",
        "Zapisz ustawienia widgetów": "",
        "Zapisz wybrane": "",
        "Zapisz zmiany": "",
        "Zaplanowane": "",
        "Zapomniałeś hasła?": "",
        "Zapytanie SQL": "",
        "Zarchiwizowane": "",
        "Zarchiwizowany": "",
        "Zarządzaj kolumnami": "",
        "Zasięg": "",
        "Zastępstwo": "",
        "Zastępstwo wygasło.": "",
        "Zastępstwo wygaśnie za 10 minut.": "",
        "Zastępstwo wygaśnie za 5 minut.": "",
        "Zastępstwo wygaśnie za minutę.": "",
        "Zastępstwo zostało dodane.": "",
        "Zastępstwo zostało usunięte.": "",
        "Zastępstwo zostało zaktualizowane.": "",
        "Zastępuje osobę": "",
        "Zastosuj zmiany w aplikacji": "",
        "Zatwierdź": "",
        "Zatwierdź zmiany": "",
        "Zawartość": "",
        "Zawiera": "",
        "Zawiera listy przewozowe": "",
        "Zawsze": "",
        "Zaznacz": "",
        "Zaznacz wszystkie": "",
        "Zbieraj towary": "",
        "Zbieranie": "",
        "Zbieranie (Zależy od aktywnego procesu)": "",
        "Zbierz towary": "",
        "Zbiorczo": "",
        "Zdarzenie": "",
        "Zdjęcie": "",
        "Zdjęcie produktu": "",
        "Zdjęcie użytkownika": "",
        "Zebrane towary wg zamówień": "",
        "Zeskanowano pomyślnie": "",
        "Zeskanowano pomyślnie.": "",
        "Zeskanuj kod kreskowy": "",
        "Zeskanuj kod opakowania": "",
        "Zeskanuj kod opakowania lub list przewozowy": "",
        "Zeskanuj pozycje": "",
        "Zeskanuj pozycję": "",
        "Zespoły": "",
        "Zespół": "",
        "Zespół aktywny": "",
        "Zespół został dodany.": "",
        "Zespół został usunięty.": "",
        "Zespół został zaktualizowany.": "",
        "Zewnętrzene linki": "",
        "Zewnętrzny dokument wydania": "",
        "Zgoda": "",
        "Zgodna": "",
        "Zlecenia": "",
        "Zlecenia przesunięć": "",
        "Zlecenie zostało przekazane do realizacji.": "",
        "Zlecenie zostało utworzone.": "",
        "Zlecenie zostało zaktualizowane.": "",
        "Zliczanie dokumentów i wyświetlanie wyników w menu": "",
        "Zmiana hasła": "",
        "Zmiana na %0.": "",
        "Zmiana rozmiaru obrazu": "",
        "Zmiana z %0 na %1.": "",
        "Zmiana z %0.": "",
        "Zmiany ustawień monitoringu wymagają restartu aplikacji.": "",
        "Zmiany zaczną obowiązywać dopiero po przebudowaniu indeksów.": "",
        "Zmiany zostały zapisane.": "",
        "Zmiany zostaną zastosowane w aplikacji w przeciągu kikunastu sekund": "",
        "Zmieniono ustawienia widgetu": "",
        "Zmień hasło": "",
        "Zmień nazwę": "",
        "Zmień nazwę katalogu": "",
        "Zmień nazwę pliku": "",
        "Zmień rozmiar": "",
        "Zmień status edytowanej wersji": "",
        "Zmień widok": "",
        "Zmniejsz": "",
        "zmodyfikował": "",
        "Zmodyfikował": "",
        "zmodyfikowany": "",
        "znaki": "",
        "Zobacz sesję użytkownika": "",
        "Zobacz szczegóły sesji użytkownika": "",
        "Zobacz szczegóły zdarzenia": "",
        "Zobacz wszystkie": "",
        "Został przywrócony oryginalny harmonogram": "",
        "Zresetuj hasło": "",
        "Zrestartuj aplikacje": "",
        "Zwolnij stanowisko": "",
        "Zwroty": "",
        "Zwrócony": "",
        "Zwróć do magazynu": "",
        "Źródła LDAP": "",
        "Źródło": "",
        "Źródło aktywne": "",
        "Źródło danych": "",
        "źródło danych zostało dodane.": "",
        "Źródło danych zostało zaktualizowane.": "",
        "Źródło LDAP zostało dodane.": "",
        "Źródło LDAP zostało zaktualizowane.": "",
        "źródło zostało usunięte.": "",
        "Żaden kanał komunikacji nie został włączony w ustawieniach powiadomień.": "",
        "Żródło danych": ""
    }
};
