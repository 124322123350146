<template>
    <div class="chart-widget">
        <Bar v-if="loaded" :data="chartData" :options="chartOptions" />
    </div>
</template>

<script lang="ts" setup>
import { computed, ref, onMounted } from 'vue';
import { useAlerts } from '@/plugins/alerts';
import { useLocalization } from '@/plugins/localization';
import { useMixins } from '@/plugins/mixins';
import { Resource } from '@/helpers/Interfaces';
import { WidgetModel } from "@/modules/core/home/services/HomeService";
import WidgetsService, { StatusCountItemModel } from "@/modules/core/common/services/WidgetsService";

import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Tooltip, BarElement, CategoryScale, LinearScale, Colors, ChartData } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(Tooltip, BarElement, CategoryScale, LinearScale, Colors, ChartDataLabels);

interface Props
{
    config: WidgetModel
    publicId: string
}

defineOptions({
    name: 'ReceiptStatusesWidget',
    inheritAttrs: false,
    components: {
        Bar
    }
});

const props = defineProps({
  "config": { default: null },
  "publicId": { default: '' }
});

const { $alert } = useAlerts();
const { $t } = useLocalization();
const { $handleException } = useMixins();
const items = ref<Resource<StatusCountItemModel>[]>([]);
const loaded = ref<boolean>(false);

const chartData = computed<ChartData<'bar'>>(() =>
{
    const labels = items.value.map((item: Resource<StatusCountItemModel>) => item.result.status);
    const data = items.value.map((item: Resource<StatusCountItemModel>) => item.result.count);

    return {
        labels: labels,
        datasets: [{
            label: $t('[[[Ilość]]]'),
            data: data,
            borderRadius: 4,
            backgroundColor: ['#03a4dc', '#f28705', '#ff4255', '#4dbd73'],
            datalabels: {
                align: 'end',
                anchor: 'end'
            }
        }]
    };
});
const chartOptions = computed(() =>
{
    return {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                font: {
                    weight: 'bold'
                }
            }
        },
        layout: {
            padding: {
                top: 30,
            }
        }
    };
});

onMounted(async (): Promise<void> =>
{
    await loadData();
});

const loadData = async (): Promise<void> =>
{
    loaded.value = false;

    try
    {
        const response = await WidgetsService.getReceiptsStatusCount();

        items.value = response;
    }
    catch (ex)
    {
        $handleException(ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });
    }
    finally
    {
        loaded.value = true;
    }
};
</script>

<style lang="scss" scoped>
</style>
