<template>
    <div class="error-page">
        <h1><slot name="code"></slot></h1>
        <section class="info">
            <h2><slot name="header"></slot></h2>
            <p>
                <slot name="description"></slot>
            </p>
        </section>
        <section class="logo">
            <a href="http://edito.pl" class="logo">
                <img src="/static/img/asiston-logo-text.png" />
            </a>
        </section>
        <section class="back">
            <a href="/">
                <i class="arrow"></i>
                <span>{{ $t('[[[Wróć do strony głównej]]]') }}</span>
            </a>
        </section>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class ErrorLayout extends Vue
{
}
</script>

<style lang="scss" scoped>
.error-page {
    --ico-arrow: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9Ijc2OCIgaGVpZ2h0PSI3NjgiIHZpZXdCb3g9IjAgMCA3NjggNzY4Ij48dGl0bGU+PC90aXRsZT48ZyBpZD0iaWNvbW9vbi1pZ25vcmUiPjwvZz48cGF0aCBmaWxsPSIjMDAwIiBkPSJNNTc2IDM1MmgtMjMxLjgwOGw1MC43NTItNTAuNzUyYzI0Ljk5Mi0yNC45OTIgMjQuOTkyLTY1LjU2OCAwLTkwLjQ5Ni0yNC45OTItMjQuOTkyLTY1LjUwNC0yNC45OTItOTAuNDk2IDBsLTIwNS4yNDggMjA1LjI0OCAyMDUuMjQ4IDIwNS4yNDhjMTIuNDggMTIuNTEyIDI4Ljg2NCAxOC43NTIgNDUuMjQ4IDE4Ljc1MnMzMi43MzYtNi4yNCA0NS4yNDgtMTguNzUyYzI0Ljk5Mi0yNC45OTIgMjQuOTkyLTY1LjU2OCAwLTkwLjQ5NmwtNTAuNzUyLTUwLjc1MmgyMzEuODA4YzM1LjMyOCAwIDY0LTI4LjY3MiA2NC02NCAwLTM1LjM2LTI4LjY3Mi02NC02NC02NHoiPjwvcGF0aD48L3N2Zz4=);
    --error-bg: linear-gradient(to right, #0e1824 50%, #fff 50%);
    --error-color: #0e1824;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: var(--error-bg);

    a {
        text-decoration: none;
    }
    h1 {
        font-size: 160px;
        font-weight: normal;
        letter-spacing: 30px;
        margin-right: -30px;
        color: #fff;
        mix-blend-mode: difference;
    }
    .info {
        position: absolute;
        bottom: 15%;
        left: 10%;
        right: 60%;
        color: #fff;
        mix-blend-mode: difference;

        h2 {
            margin-bottom: 18px;
            font-size: 45px;
            font-weight: 700;
            text-align: center;
        }
        p {
            text-align: center;
            font-size: 20px;
        }
    }
    .logo {
        position: absolute;
        bottom: 15%;
        right: 10%;
    }
    .back {
        position: absolute;
        top: 10%;
        right: 10%;

        a {
            font-size: 17px;
            font-weight: 700;
            color: var(--error-text-color);

            i.arrow {
                display: inline-block;
                width: 19px;
                height: 20px;
                margin-right: 7px;
                background-image: var(--ico-arrow);
                background-size: cover;
                transition-duration: 0.3s;
                font-size: 22px;
                vertical-align: -3px;
            }

            &:hover {
                text-decoration: none;

                i.arrow {
                    margin-right: 14px;
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .error-page {
        .info {
            h2 {
                margin-bottom: 12px;
                font-size: 36px;
            }
            p {
                font-size: 16px;
            }
        }
    }
}

@media screen and (max-width: 1120px) {
    .error-page {
        .info {
            h2 {
                margin-bottom: 8px;
                font-size: 24px;
            }
            p {
                font-size: 13px;
            }
        }
        .back span {
            display: none;
        }
    }
}

@media screen and (max-width: 640px) {
    .error-page {
        h1 {
            font-size: 100px;
        }
        .info {
            left: 10%;
            right: 10%;
        }
        .logo {
            display: none;
        }
    }
}

@media screen and (max-height: 640px) {
    .error-page {
        .info {
            bottom: 10%;
        }
    }
}
</style>
