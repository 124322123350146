<template>
    <div style="position: relative;">
        <ideo-dropdown
            :text="$t('[[[Zarządzaj kolumnami]]]')"
            :dropleft="!tablet"
            variant="dark"
            boundry="body"
            no-flip
            no-caret
            :top-offset="5"
            :menu-class="['pt-0', 'filter-dropdown-object', 'manager-dropdown']"
            ref="more-columns"
        >
            <template #button-content>
                <span class="d-flex align-items-center">
                    <span class="far fa-cog" style="font-size: 1.05rem;"></span>
                </span>
            </template>
            <ideo-tabs nav-class="flex-nowrap nav-justified" content-class="my-1">
                <ideo-tab :title="$t('[[[Kolumny]]]')" active>
                    <div class="columns-customizer" v-if="mobile">
                        <ideo-dropdown-item-button
                            v-for="(header, index) in columnDef"
                            :key="index"
                            :data-order="header.order"
                            data-unclosing="true"
                            prevent-close
                        >
                            <div class="d-flex align-items-center">
                                <div class="d-flex flex-column" style="margin-right: 0.5rem;">
                                    <button :disabled="!canSwitchUp(header)" class="border-0 bg-transparent mb-1 order-switcher" type="button" @click="switchUp(header)">
                                        <span class="fas fa-arrow-alt-up"></span>
                                    </button>
                                    <button :disabled="!canSwitchDown(header)" class="border-0 bg-transparent order-switcher" type="button" @click="switchDown(header)">
                                        <span class="fas fa-arrow-alt-down"></span>
                                    </button>
                                </div>
                                <ideo-form-checkbox :modelValue="header.isActive" :disabled="isColumnCheckboxDisabled && header.isActive" @change="columnVisibilityChanged($event, header)">
                                    {{ $t(header.label) }} <template v-if="header.description"> - {{ $t(header.description) }}</template>
                                </ideo-form-checkbox>
                            </div>
                        </ideo-dropdown-item-button>
                    </div>
                    <div class="columns-customizer" v-else>
                        <draggable
                            v-model="columnDef"
                            class="drop-zone"
                            :component-data="{tag: 'ul', type: 'transition-group', name: !drag ? 'flip-list' : null}"
                            @start="drag=true"
                            @end="drag=false"
                            @change="handleOrderChange"
                            ghost-class="ghost"
                            :item-key="(element) => `${element.field}`"
                        >
                            <template #item="{element}">
                                <ideo-dropdown-item-button style="cursor: move" prevent-close>
                                    <div class="d-flex align-items-center">
                                        <span class="far fa-grip-vertical mr-2" style="margin-right: 0.5rem;"></span>
                                        <ideo-form-checkbox class="customizer-checkbox" :modelValue="element.isActive" :disabled="isColumnCheckboxDisabled && element.isActive" @change="columnVisibilityChanged($event, element)">
                                            {{ $t(element.label) }} <template v-if="element.description"> - {{ $t(element.description) }}</template>
                                        </ideo-form-checkbox>
                                    </div>
                                </ideo-dropdown-item-button>
                            </template>
                        </draggable>
                    </div>
                </ideo-tab>
            </ideo-tabs>
            <!-- <ideo-button-group class="border-top px-3 pt-2 pb-0 d-flex flex-column flex-sm-row">
                <ideo-button variant="success" class="mr-2 text-nowrap rounded" @click.stop="saveView">
                    {{ $t("[[[Zapisz układ]]]") }}
                </ideo-button>
                <ideo-button variant="text" class="text-nowrap" @click.stop="resetView">
                    <i class="fal fa-times mr-1"></i>
                    {{ $t("[[[Resetuj układ]]]") }}
                </ideo-button>
            </ideo-button-group> -->
        </ideo-dropdown>
    </div>
</template>

<script lang="ts">
import { Emit, Prop, Ref, Watch } from '@/helpers/Decorators';
import { Options, Vue } from 'vue-class-component';
import { DynamicGridColumn, DynamicGridConfiguration } from '@/components/dynamicgird/services/DynamicGridService';
import draggable from 'vuedraggable';

@Options({
    name: 'Customizer',
    components: {
        draggable
    }
})
export default class Customizer extends Vue
{
    @Ref('more-columns')
    private drodpown: () => IdeoDropdown;

    @Prop({ default: null })
    private columns: DynamicGridColumn[];

    private columnDef: DynamicGridColumn[] = null;
    private drag: boolean = false;

    public mounted(): void
    {
        this.initColumns();
    }

    public initColumns(): void
    {
        this.columnDef = this.columns;

        this.columnDef = this.columnDef.sort((a, b) =>
        {
            if (b.order < a.order)
            {
                return 1;
            }
            else return -1;
        });
    }

    public handleOrderChange(evt: any): DynamicGridColumn[]
    {
        this.columnDef.forEach((element, index) =>
        {
            element.order = index + 1;
        });

        this.saveView();
    }

    // mobile order switching

    public canSwitchUp(item: DynamicGridColumn): boolean
    {
        return item.order !== 1;
    }

    public switchUp(item: DynamicGridColumn): DynamicGridColumn[]
    {
        const columnsEntries = this.columnDef;

        const column = columnsEntries.sort((a, b) =>
        {
            if (b.order < a.order)
            {
                return 1;
            }
            else return -1;
        }).find(column =>
        {
            return (item.order - column.order) === 1;
        });

        column.order++;
        item.order--;

        this.saveView();

        return this.columnDef;
    }

    public get isColumnCheckboxDisabled(): boolean
    {
        const visibleColumns = this.columnDef.filter(column =>
        {
            return column.isActive;
        });

        return visibleColumns.length <= 1;
    }

    public canSwitchDown(item: DynamicGridColumn): boolean
    {
        const sorted = this.columnDef.sort((a, b) =>
        {
            if (b.order < a.order)
            {
                return 1;
            }
            else return -1;
        });

        return item.order !== sorted.length;
    }

    public switchDown(item: DynamicGridColumn): DynamicGridColumn[]
    {
        const columnsEntries = this.columnDef;
        const column = columnsEntries.sort((a, b) =>
        {
            if (b.order < a.order)
            {
                return 1;
            }
            else return -1;
        }).find(column =>
        {
            return (item.order - column.order) === -1;
        });

        column.order--;
        item.order++;

        this.saveView();

        return this.columnDef;
    }

    private columnVisibilityChanged(value: boolean, item: DynamicGridColumn): DynamicGridColumn[]
    {
        const visibleColumns = this.columnDef.filter(column =>
        {
            return column.isActive;
        });

        if (!value && visibleColumns.length === 1)
        {
            this.$alert.warning(this.$t("[[[Minimum jedna kolumna musi pozostać widoczna]]]"));
        }
        else
        {
            item.isActive = value;

            this.saveView();

            return this.columnDef;
        }
    }

    @Emit('save')
    private saveView(): DynamicGridConfiguration
    {
        return this.columnDef;
    }

    // @Emit('reset')
    // private resetView(): void
    // {}

    @Watch('columns')
    private setColumns(columns: DynamicGridColumn[]): void
    {
        this.columnDef = columns;
    }
}
</script>

<style lang="scss" scoped>
    .order-switcher{
        color: var(--filter-nav-link-color);
    }

    button[disabled]{
        opacity: 0.6;
    }

    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
        border-top: 3px solid red;
    }
</style>
