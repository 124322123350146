<template>
    <div>
        <data-card v-if="configuration" :use-panels="true" :fullscreen-panels="true" :right-panel-name="$t('[[[Filtry]]]')" ref="card">
            <!-- Header -->
            <template #toolbar>
                <div class="d-flex">
                    <!-- Filters -->
                    <slot name="filters"></slot>
                    <div class="ms-auto d-flex">
                        <div class="d-flex justify-content-end">
                            <div class="mx-2">
                                <Customizer :columns="configuration.grid" @save="saveConfiguration" />
                            </div>
                        </div>
                        <data-sorting v-if="mobile" :pager="pagerStore" :options="sortingOptions" class="w-auto mb-0" :class="{'me-2': !mobile}" :short="phone" @change="loadData()"></data-sorting>
                        <slot name="header"></slot>
                    </div>
                </div>
            </template>
            <slot name="toolbar"></slot>
            <!-- Tabela -->
            <list-view :items="items" :pager="pagerStore" @change="loadData()" :row-class="rowClass" :row-click="rowClick" @check="onCheck" class="grid-table" :absolute-arrows="false">
                <template #row="{item}">
                    <slot name="row-before" :item="item"></slot>
                    <template v-for="(column, index) in getColumns" :key="index">
                        <template v-if="index == 0">
                            <list-view-header :title="$t(column.label)" :sort="column.orderBy">
                                <component :is="getFormatter(column.type)" :configuration="column" :item="item"></component>
                            </list-view-header>
                        </template>
                        <template v-else>
                            <template v-if="column.type == 'image'">
                                <list-view-image width="36" :class="{'mobile-list-item': mobile}">
                                    <component :is="getFormatter(column.type)" :configuration="column" :item="item"></component>
                                </list-view-image>
                            </template>
                            <template v-else>
                                <list-view-item :title="$t(column.label)" :sort="column.orderBy" :class="getCssClasses(column)">
                                    <component :is="getFormatter(column.type)" :configuration="column" :item="item"></component>
                                </list-view-item>
                            </template>
                        </template>
                    </template>
                    <slot name="row-after" :item="item"></slot>
                </template>
            </list-view>
            <!-- Footer -->
            <template #footer>
                <pagination-bar :pager="pagerStore" @change="loadData()" />
            </template>
        </data-card>
    </div>
</template>

<script lang="ts">
import { Options, Vue, } from 'vue-class-component';
import {  Option, Pagination, Resource } from '@/helpers/Interfaces';
import {Prop } from '@/helpers/Decorators';
import Pager from '@/helpers/Pager';
import { FormType } from '@/helpers/Form';
import { restoreState } from '@/plugins/filters';

import DynamicGridService, { DynamicGridColumn, DynamicGridConfiguration } from '../services/DynamicGridService';
import Customizer from '@/components/dynamicgird/components/configuration/Customizer.vue';

@Options({
    components: {
        Customizer
    }
})
export default class DynamicGrid extends Vue
{
    @Prop({default: '', required: true })
    public id: string;
    @Prop()
    public pagerStore: Pager;
    @Prop()
    public filterStore: FormType<any>;
    @Prop()
    public fetch!: () => Promise<Pagination<Resource<any>>>;

    @Prop({default: [], required: true})
    public items: Resource<any>[];

    @Prop()
    public rowClick: (item: any) => void;

    @Prop()
    public rowClass: (item: any) => Record<string, boolean> | string[] | string;

    @Prop({default: true})
    public restoreState: boolean;

    public configuration: DynamicGridConfiguration = null;

    public get getColumns(): DynamicGridColumn[]
    {
        return this.configuration.grid.filter(x => x.isActive);
    }

    public getFormatter(type: string): any
    {
        return this.$dynamicGrid.format(type).vueElement;
    }

    public get sortingOptions(): Option[]
    {
        const sort = [] as Option[];

        this.getColumns.filter(x => x.sortable).forEach(item =>
        {
            sort.push({ value: item.orderBy, text: item.label });
        });

        return sort;
    }

    public created(): void
    {
        if (this.restoreState)
            restoreState(this.$route.name.toString(), this.filterStore, this.pagerStore);
    }

    public async mounted(): Promise<void>
    {
        this.configuration = await DynamicGridService.get(this.id);
        this.loadData();
    }

    public async loadData(): Promise<void>
    {
        this.$emit('loadData');
    }

    public getCssClasses(column: DynamicGridColumn) : string
    {
        let classes = "";

        if (this.mobile)
            classes = classes + `mobile-list-item `;

        if (column.type === 'progress')
            classes = classes + `hide-title `;

        if (column.customCssClass)
            classes = classes + `${column.customCssClass} `;

        return classes;
    }

    public async saveConfiguration(columns: DynamicGridColumn[]): Promise<void>
    {
        this.configuration.grid = columns;
        await DynamicGridService.save(this.id, this.configuration);
        this.$alert.success(this.$t("[[[Układ zapisany poprawnie.]]]"));
    }

    public onCheck(value: boolean): void
    {
        this.$emit('check', value);
    }
}
</script>
