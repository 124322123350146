<template>
    <div :class="showReset ? 'ideo-multiselect' : ''">
        <multiselect
            v-model="model" :options="optionsValue" :name="name" :loading="loading" :label="label" :track-by="trackBy"
            :placeholder="placeholder || $t('[[[Wybierz...]]]')" :select-label="''" :selected-label="''" :deselect-label="''" :multiple="multiple" :searchable="searchable" :options-limit="limit"
            :internal-search="false" :clear-on-select="false" :close-on-select="true" :max-height="300" :show-no-results="true" :hide-selected="false" :disabled="disabled" :allow-empty="allowEmpty" @select="onSelected"
        >
            <template #noOptions>{{ $t('[[[Lista jest pusta]]]') }}</template>
            <template #noResult>{{ $t('[[[Nie znaleziono żadnych wyników.]]]') }}</template>
            <template #singleLabel="{ option }"><slot name="selected" :option="option"></slot></template>
            <template #option="{ option }"><slot name="option" :option="option"></slot></template>
        </multiselect>
        <span v-if="showReset" class="btn btn-secondary btn-close bg-light" @click="reset()" />
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch, Emit } from '@/helpers/Decorators';
import { Option } from '@/helpers/Interfaces';
import Multiselect from 'vue-multiselect/src/Multiselect.vue';

@Options({
    name: 'ideo-autocomplete',
    components: {
        'multiselect': Multiselect
    }
})
export default class IdeoAutocomplete extends Vue
{
    @Prop({ default: '' }) public name: string;
    @Prop({ default: null }) public modelValue: any;
    @Prop({ default: 10 }) public limit: number;
    @Prop({ default: false }) public disabled: boolean;
    @Prop({ default: false }) public refresh: boolean;
    @Prop({ default: 'value' }) public trackBy: string;
    @Prop({ default: 'text' }) public label: string;
    @Prop() public placeholder: string;
    @Prop({ default: false }) public multiple: boolean;
    @Prop({ default: false }) public showReset: boolean;
    @Prop({ default: true }) public searchable: boolean;
    @Prop({ default: []}) public options: Option[];
    @Prop({ default: true }) public allowEmpty: boolean;


    public optionsValue: Option[] = [];
    public model: Option | Option[] = null;
    public loading: boolean = false;

    @Watch('options', { immediate: true })
    public setOptions(value: Option[]): void
    {
        if (value && value.length)
        {
            this.optionsValue = value;

            if (this.modelValue)
                this.model = value.find(x => x[this.trackBy] == this.modelValue) ?? null;
        }
    }

    @Watch('model')
    public onModelChanged(model: Option): void
    {
        this.updateModel(model);
        this.triggerChanged(model);
    }

    @Emit('update:modelValue')
    public updateModel(model: any): string | string[]
    {
        if (this.multiple)
        {
            if (model != null && Array.isArray(model))
            {
                return model.map((element: Option) => element[this.trackBy]);
            }
            else return null;
        }
        else
        {
            return model != null && this.trackBy in model ? model[this.trackBy] : null;
        }
    }

    @Emit('changed')
    public triggerChanged(model: Option): Option
    {
        return model;
    }

    @Emit('select')
    public onSelected(): any
    {
        return this.model;
    }

    @Emit('update:modelValue')
    public reset(): void
    {
        this.model = null;

        return null;
    }
}
</script>

<style lang="scss" scoped>
</style>
