<template>
    <ideo-datetime
        v-model="model"
        :name="props.name"
        :type="props.type"
        :range="props.range"
        :multi="props.multi"
        :teleport="true"
        :inline="false"
    >
        <template #trigger>
            <button type="button" class="btn btn-text-normal w-100" :class="{'btn-light': !model || !model.length, 'btn-dark': isDirty}">
                <div class="d-flex">
                    <div class="flex-fill text-start my-n1" v-if="isDirty">
                        <div class="lh-sm small fw-normal">{{ label }}</div>
                        <div class="lh-1 text-truncate display-width">
                            <slot name="display">{{ display }}</slot>
                        </div>
                    </div>
                    <div v-else>
                        <span class="fas fa-calendar-alt me-2"></span>
                        {{ label }}
                    </div>
                    <div class="ms-2" v-if="isDirty">
                        <i class="fas fa-fw fa-xmark" @click.stop="onReset()"></i>
                    </div>
                </div>
            </button>
        </template>
    </ideo-datetime>
</template>

<script lang="ts" setup>
import { computed, onMounted } from 'vue';
import { DateTime } from 'luxon';
import { formatDateTime } from '@/helpers/Utils';

interface Props
{
    modelValue?: string
    label: string
    name: string
    type?: string
    range?: boolean
    multi?: boolean
}

const props = defineProps({
  "modelValue": { default: null },
  "label": { default: '' },
  "name": { default: '' },
  "type": { default: 'date' },
  "range": { type: Boolean, default: false },
  "multi": { type: Boolean, default: false }
});

defineOptions({
    name: 'date-time-button',
    inheritAttrs: false
});

const emit = defineEmits(["update:modelValue"]);

const gte = defineModel<any>('gte');
const lte = defineModel<any>('lte');

const model = defineModel<any|any[]>({
    get(value)
    {
        if (props.range == true)
        {
            return gte.value && lte.value ? [gte.value, lte.value] : [];
        }

        return value;
    },
    set(value)
    {
        if (props.range == true)
        {
            if (value && Array.isArray(value) && value.length == 2)
            {
                gte.value = value.first();
                lte.value = value.last();
            }
            else
            {
                gte.value = null;
                lte.value = null;
            }
        }

        emit('update:modelValue', value);

        return value;
    }
});

const datetimeFormat = computed(() => props.type === 'datetime' ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd');
const isDirty = computed(() => props.range == true ? model.value && model.value.length : model.value);
// eslint-disable-next-line vue/no-dupe-keys
const display = computed(() =>
{
    if (model.value && Array.isArray(model.value) && model.value.length == 2)
    {
        return `${formatDateTime(model.value.first(), 'yyyy-MM-dd')} - ${formatDateTime(model.value.last(), 'yyyy-MM-dd')}`;
    }

    return formatDateTime(model.value, datetimeFormat.value, null, null);
});

function onReset()
{
    model.value = null;
}

onMounted(async (): Promise<void> =>
{
});
</script>

<style lang="scss" scoped>
</style>
