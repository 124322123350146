<template>
    <responsive :top="true" :hide-scroll="hideScroll" :absolute-arrows="props.absoluteArrows" :enabled="true" v-bind="attrs()">
        <table ref="table" class="table table-data table-stripped mb-0" :class="{'table-hover': rowHover}">
            <colgroup>
                <col v-for="(header, index) in headers" :key="`col-${index}`" :style="getStyle(header)">
            </colgroup>
            <thead v-if="showHeader">
                <tr>
                    <th
                        v-for="(header, index) in headers"
                        :key="`th-${index}`"
                        :class="{'buttons': header.type == 'buttons' || false, [header.class ?? '' ]: true }"
                    >
                        <ideo-form-checkbox
                            v-if="header.checkbox"
                            v-model="checkedAll"
                            @change="checkAllItems"
                        ></ideo-form-checkbox>
                        <a href="" @click.stop.prevent="onSorting(header)" v-else-if="header.sort || false">
                            <i :class="getIcon(header)"></i>
                            {{ header.title || '' }}
                        </a>
                        <span v-else>
                            {{ header.title || '' }}
                        </span>
                    </th>
                </tr>
            </thead>
            <VueDraggable
                v-if="items.length > 0"
                v-model="items"
                :item-key="(el: any, index: number) => index"
                draggable=".draggable"
                :move="canSort"
                tag="tbody"
            >
                <template #item="{ element: item, index }">
                    <list-view-utils-row :key="`row-${index}`" :index="index" :item="item" :headers="props.headers">
                        <template #underRow="{item}" v-if="hasUnderRowSlot">
                            <slot name="underRow" :item="item" :index="index"></slot>
                        </template>
                        <slot name="row" :item="item" :index="index"></slot>
                    </list-view-utils-row>
                </template>
            </VueDraggable>
            <tbody v-else>
                <td :colspan="headers.length" class="px-0">
                    <div class="text-center p-3 mt-1 bg-body-tertiary">{{ $t(emptyLabel) }}</div>
                </td>
            </tbody>
        </table>
    </responsive>
</template>

<script lang="ts" setup>
import { computed, inject, useAttrs, ref, useSlots, Ref } from 'vue';
import { useElementSize } from '@vueuse/core';
import VueDraggable from 'vuedraggable';
import Pager from '@/helpers/Pager';
import Row from '../utils/Row.vue';
import { only } from '@/helpers/Utils';
import { Header } from '../helpers';

export interface Props
{
    showHeader: boolean;
    headers: Header[];
    rows: any[];
    pager: Pager;
    absoluteArrows?: boolean;
}

defineOptions({
    name: 'list-view-table-layout',
    components: {
        'list-view-utils-row': Row
    },
    inheritAttrs: false
});

const props = defineProps({
  "showHeader": { type: Boolean,  },
  "headers": null,
  "rows": null,
  "pager": null,
  "absoluteArrows": { type: Boolean, default: true }
});
const emptyLabel = inject<string>('empty-label');
const draggable = inject<boolean>('draggable');
const rowHover = inject<string>('row-hover');
const scrollBar = inject<Ref<HTMLDivElement | null>>('scrollBar', null);
const emit = defineEmits(["change", "check"]);

const onDrag = inject<any>('onDrag');
const checkedAll = inject<boolean>('checkedAll', false);

const table = ref<HTMLTableElement | null>(null);
const { width: tableWidth } = useElementSize(table);

const items = computed({
    get()
    {
        return props.rows;
    },
    set(value: any[])
    {
        onDrag(value);
    }
});

const $slots = useSlots();
const $attrs = useAttrs();
const attrs = (): Record<string, any> => only($attrs, 'class', 'style');
const headers = computed(() =>
{
    return props.headers
        .where(p => !p.disabled && p.visible !== false)
        .orderBy(p => p.position)
        .toArray();
});
const hasUnderRowSlot = computed(() => !!$slots.underRow);

const hideScroll = computed(() => !!scrollBar.value);

const getStyle = (header: any): Record<string, string> =>
{
    return {
        'width': header.width ? `${header.width || 0}${(header.width || 0).toString().endsWith('%') ? '' : 'px'}` : ''
    };
};

const canSort = (): boolean =>
{
    return draggable;
};

const getIcon = (header: any): string =>
{
    return "fa fa-fw fa-sort" + (props.pager.sorting !== (header.sort || '') ? '' : props.pager.order.toLowerCase() === 'asc' ? '-up' : '-down');
};

const onSorting = (header: any): any =>
{
    emit('change', header);
};

const checkAllItems = (value: boolean): void =>
{
    emit('check', value);
};

const setScrollLeft = (value: number): void =>
{
    if (!table.value) return;

    table.value.parentElement.parentElement.scrollLeft = value;
};

defineExpose({
    width: tableWidth,
    setScrollLeft,
});
</script>
