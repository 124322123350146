<template>
    <div id="breadcrumbs" class="d-flex px-3 pt-2 me-1 flex-column">
        <div class="flex-fill d-flex align-items-center">
            <h3 class="text-body-emphasis d-none d-lg-block m-0" v-if="lastCrumb">
                {{ $t(lastCrumb.name) }}
            </h3>
            <div class="ms-lg-auto">
                <portal-target name="breadcrumbs" />
                <template v-if="allCrumbs.length > 0">
                    <template v-for="(item, index) in allCrumbs" :key="index">
                        <router-link class="link" :to="url(item)">{{ $t(item.name) }}</router-link>
                    </template>
                    <router-link class="link active" v-if="lastCrumb" :to="url(lastCrumb)">{{ $t(lastCrumb.name) }}</router-link>
                </template>
            </div>
        </div>
        <div id="under-breadcrumbs" class="w-100"></div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Watch } from '@/helpers/Decorators';
import { RouteLocationNormalizedLoaded as Route } from 'vue-router';
import { SitemapNode } from '@/plugins/sitemap';

@Options({
    name: 'partials-navigation-breadcrumbs'
})
export default class Menu extends Vue
{
    public crumbs: SitemapNode[] = [];
    public extraCrumb: string = '';

    public get allCrumbs(): SitemapNode[]
    {
        if (this.extraCrumb)
        {
            return this.crumbs;
        }

        return this.crumbs.slice(0, -1);
    }

    public get lastCrumb(): SitemapNode | null
    {
        if (this.extraCrumb)
        {
            return {
                name: this.extraCrumb,
                visible: true,
                allowed: true
            };
        }

        return this.crumbs[this.crumbs.length - 1] || null;
    }

    public url(node: any): any
    {
        return this.$sitemap.url(node, this.$route);
    }

    @Watch('$sitemap.extraCrumb')
    public handleExtraCrumbChange(value: string): void
    {
        this.extraCrumb = value;
    }

    @Watch('$route', { immediate: true })
    public async onRouteChanged(route: Route): Promise<void>
    {
        this.$sitemap.append('');
        this.crumbs = await this.$sitemap.crumbs(route);
    }
}
</script>

<style lang="scss">
#breadcrumbs {
    height: var(--ideo-breadcrumbs-height);

    .link {
        color: var(--bs-body-color);

        &:not(.separator) {
            cursor: pointer;
        }

        &.active {
            color: var(--bs-primary);
            font-weight: 500;
        }

        &:not(:last-child)::after {
            content: "\f324";
            font-family: var(--fa-font-family);
            font-size: 8px;
            vertical-align: 1px;
            display: inline-block;
            color: var(--bs-gray-400);
            padding: 0 8px;
        }
    }
}
</style>
