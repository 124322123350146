import { Prop } from '@/helpers/Decorators';
import { Options, Vue } from 'vue-class-component';
import { DynamicGridColumn } from '../services/DynamicGridService';
import { Resource } from '@/helpers/Interfaces';
import { RouteParamsRaw } from 'vue-router';

@Options({
    name: 'BaseFormatterMixin'
})
export class BaseFormatterMixin extends Vue
{
    @Prop()
    public configuration: DynamicGridColumn;
    @Prop()
    public item: Resource<any>;

    public get value() : any
    {
        let tempValue = this.item?.result;

        if (this.configuration.field)
        {
            const splittedField = this.configuration.field.split(".");

            for (let index = 0; index < splittedField.length; index++)
            {
                const element = splittedField[index];

                tempValue = tempValue[element];
            }
        }

        return tempValue;
    }

    public get params() : RouteParamsRaw
    {
        const temp : RouteParamsRaw = {};

        if (this.configuration.params)
        {
            const entries = Object.entries(this.configuration.params);

            for (let index = 0; index < entries.length; index++)
            {
                const element = entries[index];

                if (element.length < 2)
                    continue;

                const splittedValue = element[1].split('.');

                let tempValue = this.item.result;

                for (let index = 0; index < splittedValue.length; index++)
                {
                    const element = splittedValue[index];

                    tempValue = tempValue[element];
                }

                temp[element[0]] = tempValue;
            }
        }

        return temp;
    }
}
