import { ref } from 'vue';
import { defineStore } from 'pinia';
import WmsSettingsService, { ViewSettingsItemModel, GlobalConfiguration } from '@/modules/wms/configuration/settings/services/WmsSettingsService';

export const useWmsViewsSettingsStore = defineStore('wms-views-settings', () =>
{
    const viewSettings = ref<ViewSettingsItemModel[]>([]);
    const globalConfig = ref<GlobalConfiguration>();

    const showField = (fieldName: string): boolean =>
    {
        return viewSettings.value.some(element => element.id === fieldName);
    };

    const isBlockedField = (fieldName: string): boolean =>
    {
        const field = viewSettings.value.find(element => element.id === fieldName);

        return !!(field && field.blocked);
    };

    const resetViewSettings = (): void =>
    {
        viewSettings.value = [];
    };

    const loadGlobalSettings = async (): Promise<void> =>
    {
        try
        {
            const { result } = await WmsSettingsService.getGlobalConfiguration();

            globalConfig.value = result;
        }
        catch (ex: any)
        {
            // @ts-ignore
            console.error(ex);
        }
    };

    return {
        viewSettings,
        globalConfig,
        showField,
        isBlockedField,
        resetViewSettings,
        loadGlobalSettings
    };
});
