<template>
    <span v-if="item.result[configuration.field]" class="badge" :class="getStyle(item.result[configuration.field].style)">{{ item.result[configuration.field].text }}</span>
</template>

<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { Options, Vue } from 'vue-class-component';
import { Resource } from '@/helpers/Interfaces';

import { DynamicGridColumn } from '../../services/DynamicGridService';

@Options({})
export default class TextFormatter extends Vue
{
    @Prop()
    public configuration: DynamicGridColumn;
    @Prop()
    public item: Resource<any>;

    public getStyle(style: string) : string
    {
        return `badge--${style}`;
    }
}
</script>
