<template>
    <ideo-modal ref="modal" :title="$t('[[[Termin kolejnej dostawy]]]')" size="md" :hide-footer="true" static>
        <list-view
            :preload="false"
            :items="items"
            :pager="pager"
            @change="loadData()"
        >
            <template #row="{item}">
                <list-view-item :title="$t('[[[Numer dokumentu]]]')" :class="{'mobile-list-item' : mobile}" sort="documentNumber">
                    {{ item.result.documentNumber }}
                </list-view-item>
                <list-view-item :title="$t('[[[Data]]]')" :class="{'mobile-list-item' : mobile}" sort="deliveryDateUtc">
                    {{ $filters.date(item.result.deliveryDateUtc) }}
                </list-view-item>
                <list-view-item :title="$t('[[[Ilość]]]')" :class="{'mobile-list-item' : mobile}" sort="quantity.Quantity">
                    {{ item.result.quantity.quantityAsStringWithUnit }}
                </list-view-item>
            </template>
        </list-view>
        <pagination-bar v-if="showPager" :pager="pager" @change="loadData()" />
    </ideo-modal>
</template>

<script lang="ts" setup>
import { computed, reactive, ref } from 'vue';
import { useLogging } from '@/plugins/logging';
import { useLocalization } from '@/plugins/localization';
import IdeoModal from '@/components/ideo/modal/IdeoModal.vue';
import ProductsService, { NextDeliveryInfoModel } from '../../../services/ProductsService';
import Pager from '@/helpers/Pager';
import { Resource } from '@/helpers/Interfaces';

defineOptions({
    name: 'NextDeliveryInfosModal'
});


const { $log } = useLogging();
const { $t } = useLocalization();

const modal = ref<any>(null);

const items = ref<Resource<NextDeliveryInfoModel>[]>([]);
const pager = reactive(new Pager(1, 20, 'DeliveryDateUtc', 'ASC'));
const showPager = computed(() => !!(items.value.length && pager.totalPages > 1));
const productId = ref<string>(null);

const loadData = async (): Promise<void> =>
{
    if (!productId.value)
        return;

    items.value = [];

    try
    {
        const response = await ProductsService.getDeliveryInfos(productId.value, pager);

        pager.setTotalRows(response.totalRows);
        items.value = response.items;
    }
    catch (ex)
    {
        pager.setTotalRows(0);
        items.value.length = 0;
        $log.debug(ex);
    }
};

const open = (productPublicId: string): void =>
{
    productId.value = productPublicId;
    items.value = [];
    loadData();
    modal.value.show();
};


defineExpose({
    open
});
</script>

<style lang="scss" scoped>
</style>
