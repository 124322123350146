<template>
    <template v-if="options.length > 0">
        <div class="mx-3 my-2">
            <ideo-form-select
                name="substitutions"
                :class="{'border-danger': $auth.impersonating()}"
                v-model="current"
                :options="[{value: me, text: $t('[[[Moje konto]]]') }, ...options]"
                @click.stop
            >
            </ideo-form-select>
        </div>
        <div class="dropdown-divider"></div>
    </template>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import AuthService, { Substitution } from '@/modules/core/auth/services/AuthService';
import { Listen } from '@/plugins/signalr';
import { DateTime, Duration } from 'luxon';
import { Watch } from '@/helpers/Decorators';
import { Option } from '@/helpers/Interfaces';

@Options({})
export default class Substitutions extends Vue
{
    public substitutions: Substitution[] = [];
    public interval: any = null;
    public now: DateTime = null;

    public get me(): number
    {
        return this.$auth.user()?.id;
    }

    public get current(): number
    {
        return this.$auth.impersonating() || this.me;
    }

    public set current(value: number)
    {
        if (value != this.me)
            this.$auth.impersonate(value);
        else
            this.$auth.unimpersonate();
    }

    public get options(): Option<number>[]
    {
        return this.substitutions
            .filter(p =>
            {
                return p.intervals.filter(q => q.dateBeginUtc <= this.now && this.now <= q.dateEndUtc).length > 0;
            })
            .map(p => ({value: p.id, text: p.name}));
    }

    public async mounted(): Promise<void>
    {
        this.now = DateTime.utc();
        this.interval = setInterval(() =>
        {
            this.now = DateTime.utc();
        },
        5 * 1000); // co 10 sekund
        this.reloadSubstitutions();
    }

    public async unmounted(): Promise<void>
    {
        if (this.interval)
        {
            clearInterval(this.interval);
        }
    }

    public dashboard(): void
    {
        this.redirect({ name: 'dashboard' }, () =>
        {
            this.$auth.reload();
        });
    }

    public checkSubstitutionInterval(userId: number, time: DateTime): void
    {
        const substitution = this.substitutions.find(p => p.id == userId);

        if (substitution)
        {
            const interval = substitution.intervals.find(q => q.dateBeginUtc <= time && time <= q.dateEndUtc);

            if (interval)
            {
                const ttl = interval.dateEndUtc.minus(Duration.fromObject({ seconds: 15 })).diff(time);

                switch (Math.ceil(ttl.as('minutes')))
                {
                    case 10:
                        this.$alert.info(this.$t('[[[Zastępstwo wygaśnie za 10 minut.]]]'));
                        break;
                    case 5:
                        this.$alert.info(this.$t('[[[Zastępstwo wygaśnie za 5 minut.]]]'));
                        break;
                    case 1:
                        this.$alert.danger(this.$t('[[[Zastępstwo wygaśnie za minutę.]]]'));
                        break;
                    case 0:
                        this.$alert.danger(this.$t('[[[Zastępstwo wygasło.]]]'));
                        this.$auth.unimpersonate();
                        this.dashboard();
                        break;
                }
            }
        }
    }

    @Watch('current')
    public onCurrentChanged(value: DateTime, old: DateTime): void
    {
        this.dashboard();
    }

    @Watch('now')
    public onTimeChanged(value: DateTime, old: DateTime): void
    {
        if (value && old && value.minute != old.minute)
        {
            if (this.current != this.me)
            {
                const time = DateTime.fromObject(
                    { ...value.toObject(), ...{ second: 0, millisecond: 0 } },
                    { zone: 'utc' }
                );

                this.checkSubstitutionInterval(this.current, time);
            }
        }
    }

    @Listen('substitutions')
    public async reloadSubstitutions(): Promise<void>
    {
        try
        {
            this.substitutions = await AuthService.getSubstitutions();
        }
        catch (ex)
        {
            //
        }
    }
}
</script>

<style lang="scss" scoped>
select.form-control {
    width: 95%;
    margin-left: 5px;
}
</style>
