<script lang="ts" setup>
import properties from '../../properties';
import { ButtonType, ButtonMethodTypes, ButtonVariantTypes } from '.';
import { FormEntry } from '../form';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { computed } from 'vue';
import { Option } from '@/helpers/Interfaces';
import { axios } from '@/plugins/axios';

defineOptions({
    name: 'button-blueprint',
    components: {
        ...properties
    }
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null
});

const blueprint = computed(() => props.blueprint);

const buttonMethodTypeOptions: Option<ButtonMethodTypes>[] = [
    { value: ButtonMethodTypes.Get, text: '[[[Get]]]' },
    { value: ButtonMethodTypes.Post, text: '[[[Post]]]' },
    { value: ButtonMethodTypes.Put, text: '[[[Put]]]' },
    { value: ButtonMethodTypes.Delete, text: '[[[Delete]]]' }
];

const buttonVariantTypeOptions: Option<ButtonVariantTypes>[] = [
    { value: ButtonVariantTypes.Primary, text: '[[[Główny]]]' },
    { value: ButtonVariantTypes.Secondary, text: '[[[Drugorzędny]]]' },
    { value: ButtonVariantTypes.Success, text: '[[[Sukces]]]' },
    { value: ButtonVariantTypes.Danger, text: '[[[Niebezpieczeństwo]]]' },
    { value: ButtonVariantTypes.Warning, text: '[[[Uwaga]]]' },
    { value: ButtonVariantTypes.Info, text: '[[[Informacja]]]' },
    { value: ButtonVariantTypes.Dark, text: '[[[Ciemny]]]' },
    { value: ButtonVariantTypes.Light, text: '[[[Jasny]]]' },
    { value: ButtonVariantTypes.Link, text: '[[[Link]]]' },
];

const click = async () : Promise<void> =>
{
    if (!blueprint.value.endpoint)
    {
        return;
    }

    let data = {};

    if (blueprint.value.endpointMethodType != ButtonMethodTypes.Get && blueprint.value.body)
    {
        data = props.form.expressions.executeExpression(blueprint.value.body);
    }

    try
    {
        const result = await axios.request({
            method: blueprint.value.endpointMethodType,
            url: blueprint.value.endpoint,
            data: data
        });

        if (blueprint.value.postSuccessAction)
        {
            props.form.expressions.executeExpression(blueprint.value.postSuccessAction, { data: result.data, isExpression: false });
        }
    }
    catch
    {
        if (blueprint.value.postErrorAction)
        {
            props.form.expressions.executeExpression(blueprint.value.postErrorAction, { data: {}, isExpression: false });
        }
    }

};


</script>

<template>
    <form-component-wrapper class="button-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div>
                <ideo-button :variant="blueprint.variant" @click="click">{{ props.form.localization.translate(blueprint.label) }}</ideo-button>
            </div>
            <form-help :blueprint="blueprint" />
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.label[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <ideo-form-localize v-slot="{ locale }">
                <field-textarea v-model="blueprint.help[locale]" :label="$t('[[[Pomoc]]]')" />
            </ideo-form-localize>
            <field-select v-model="blueprint.variant" :options="buttonVariantTypeOptions" :label="$t('[[[Wariant]]]')" />

            <field-choice v-model="blueprint.endpointMethodType" :options="buttonMethodTypeOptions" :label="$t('[[[Typ zapytania]]]')" />
            <field-text v-model="blueprint.endpoint" :label="$t('[[[Endpoint]]]')" :placeholder="'/resource/example'" />
            <field-expression v-model="blueprint.body" :form="form" :label="$t('[[[Zawartość]]]')" v-if="blueprint.endpointMethodType != ButtonMethodTypes.Get" />
            <field-expression v-model="blueprint.postSuccessAction" :form="form" :label="$t('[[[Akcja sukces]]]')" />
            <field-expression v-model="blueprint.postErrorAction" :form="form" :label="$t('[[[Akcja błąd]]]')" />
            <field-visible :form="form" :blueprint="blueprint" />
            <field-readonly :form="form" :blueprint="blueprint" />
        </template>
    </form-component-wrapper>
</template>

<style lang="scss" scoped>
.button-component {
    min-height: 50px;
}
</style>
