<script lang="ts" setup>
import __MACROS_useVModel from "/vue-macros/define-models/use-vmodel";
defineEmits(["update:collapsed"]);
import { computed } from 'vue';
import { ColorTranslator } from 'colortranslator';
import { LOGO, useThemeStore } from '@/store/theme';
import { useLicencesStore } from '@/store/licenses';

const props = defineProps({
  "collapsed": { type: Boolean,  },
  "hover": { type: Boolean, default: false }
});
const { collapsed } = __MACROS_useVModel("collapsed");
const licensesStore = useLicencesStore();

const style = computed(() =>
{
    const { colors, theme } = useThemeStore();

    if (colors.nav.bg && theme == 'light')
    {
        const bg = new ColorTranslator(colors.nav.bg);

        if (bg.L > 80)
            return 'light';
    }

    return 'dark';
});
const logo = computed(() => LOGO[licensesStore.appAccess][style.value]);
const src = computed(() =>
{
    return collapsed.value && !props.hover ? logo.value.small : logo.value.default;
});
</script>

<template>
    <nav class="h-100 d-flex align-items-center position-relative">
        <div class="navbar-brand mx-auto pointer logo-wrapper" @click="$router.push({name:'dashboard'})" v-if="desktop || collapsed">
            <img :src="src" class="fit-to-parent">
        </div>
        <button type="button" class="navbar-toggler position-absolute end-0" @click="collapsed = !collapsed" v-if="mobile">
            <i class="far fa-times"></i>
        </button>
    </nav>
</template>

<style lang="scss" scoped>
.navbar-toggler:hover {
    background: none !important;

    > i {
        color: var(--bs-primary) !important;
    }
}
</style>
