<script lang="ts" setup>
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch, type ComputedRef} from 'vue';
import { handleException } from '@/helpers/Utils';
import { Form, FormType } from '@/helpers/Form';
import { useAlerts } from '@/plugins/alerts';
import { useEvents } from '@/plugins/events';
import { Resource } from '@/helpers/Interfaces';
import { useLogging } from '@/plugins/logging';
import { useLocalization } from '@/plugins/localization';
import IssueServices, { PackerStationsItemModel, PackerStationsCoordinatesModel, PackerStationCoordinates } from '@/modules/wms/issue/common/services/IssueServices';

interface AdditionalConfiguration {
    autocompleteType: string;
    type: string;
    value: string | null
    visibility?: string[]
    extraData: any
    fields: AdditionalConfiguration[]
    name: string
    setToField: string
}

interface Item {
    additionalConfiguration?: AdditionalConfiguration
    name: string;
    type: string;
    value: PackerStationsItemModel[];
    values: any[];
    hidden: boolean,
    fields: AdditionalConfiguration[]
    setToField: string
}

interface Props {
    item: Item;
    mainItem: Item;
}

const { $alert } = useAlerts();
const { $log } = useLogging();
const { $t } = useLocalization();
const  { $events }  = useEvents();

const emit = defineEmits(["update"]);

const props = defineProps({
  "item": null,
  "mainItem": null
});
const computedItem: ComputedRef<Item> = computed(() =>
{
    return props.item;
});
const packerStations = ref<PackerStationsItemModel[]>(null);
const form = ref(Form.create<any>({}));

const mainItem: ComputedRef<Item> = computed(() =>
{
    return props.mainItem;
});

const stationCoordinatesOptions = ref<PackerStationsCoordinatesModel[]>([]);

onMounted(() =>
{
    loadConfig();
    getPackerStationsData();

    $events.$on("save-settings", save);
});

onBeforeUnmount(() =>
{
    $events.$off("save-settings", save);
});

const add = () : void  =>
{
    const object: PackerStationsItemModel = {
        publicId: '',
        name: '',
        accessCode: '',
        packerStationLocalizations: [{
            zonePublicId: '',
            localizationPublicId: ''
        }],
        packerStationPrinters: [],
        packerStationCoordinates: [],
    };

    if (!packerStations.value)
        packerStations.value = [];

    packerStations.value = [ ...packerStations.value, object];
};

async function loadConfig()
{
    try
    {
        const response = await IssueServices.getPackerStationsCoordinates();

        stationCoordinatesOptions.value = response;
    }
    catch (ex)
    {
        stationCoordinatesOptions.value = [];
        handleException($log, ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });
    }
}

async function save()
{
    try
    {
        await IssueServices.savePackerStations(packerStations.value);
    }
    catch (ex)
    {
        handleException($log, ex, {
            400: (ex: any) => $alert.warning(ex.message),
            422: (ex: any) =>
            {
                $alert.danger($t('[[[Wystąpił błąd przy zapisywaniu stanowisk pakowaczy.]]]'));
                form.value.$errors.record(ex.data.errors);
            }
        });
    }
}

async function getPackerStationsData()
{
    try
    {
        const response = await IssueServices.getPackerStations();

        const items = response.map((item: Resource<PackerStationsItemModel>) => item.result);

        packerStations.value = items;
    }
    catch (ex)
    {
        handleException($log, ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });
    }
}

async function releaseStation(id: string)
{
    try
    {
        await IssueServices.releasePackerStation(id);

        $alert.success($t('[[[Stanowisko zostało zwolnione]]]'));
        getPackerStationsData();
    }
    catch (ex)
    {
        handleException($log, ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });
    }
}

const getStationCoordinatesOptions = (station: PackerStationsItemModel, coordinationType: string = ''): any[] =>
{
    const usedTypes: string[] = station.packerStationCoordinates.filter((item: PackerStationCoordinates) => item.type != '').map((item: PackerStationCoordinates) => item.type);
    const options = stationCoordinatesOptions.value.filter((el: PackerStationsCoordinatesModel) => !usedTypes.includes(el.type) || el.type == coordinationType);

    return options ?? [];
};

const getCoordinatesTypeFields = (typeName: string): any[] =>
{
    const type = stationCoordinatesOptions.value.find((el: PackerStationsCoordinatesModel) => el.type == typeName);

    return type?.properties ?? [];
};

const getCoordinatesFieldComponent = (componentType: PackerStationsCoordinatesModel): string =>
{
    switch (componentType.type)
    {
        case "autocomplete-product-group":
            return "ProductGroupsPicker";
        case 'Decimal':
        case 'Int32':
            return 'ideo-form-input';
        default:
            return 'ideo-form-input';
    }
};

const onCoordinationSelect = (index: number, coordinatesIndex: number) =>
{
    nextTick(() =>
    {
        const selectedCoordinate = packerStations.value[index].packerStationCoordinates[coordinatesIndex];

        getCoordinatesTypeFields(selectedCoordinate.type);

        const properties = getCoordinatesTypeFields(selectedCoordinate.type);

        properties.forEach((item: any) =>
        {
            if (item.type == 'autocomplete-product-group')
            {
                (selectedCoordinate.args[item.key] as any) = [];
            }
        });
    });
};

const addCoordination = (value: any) =>
{
    const coordination: PackerStationCoordinates = {
        type: '',
        args: {}
    };

    value.packerStationCoordinates = [ ...value.packerStationCoordinates, coordination];
};

const removeCoordination = (valueIndex: number, coordinateIndex: number) =>
{
    (packerStations.value as any[])[valueIndex].packerStationCoordinates.splice(coordinateIndex, 1);
};

watch(() => props.item, () =>
{
    emit('update');
}, {deep: true});

const remove = (index: number) : void =>
{
    (packerStations.value as any[]).splice(index, 1);
};
</script>
<template>
    <div class="p-2 bg-light mb-2">
        <ideo-form class="mt-3" @input="form.$errors.clear($event.target.name);">
            <template v-for="(value, index) in packerStations" :key="index">
                <div class="d-flex align-items-start">
                    <ideo-form-group :label="$t('[[[Nazwa stanowiska]]]')" class="me-2 min-width-150" :invalid-feedback="form.$errors.first(`m[${index}].Name`)" :state="form.$errors.state(`[${index}].Name`)">
                        <ideo-form-input v-model="value.name" :name="`[${index}].Name`" />
                    </ideo-form-group>
                    <ideo-form-group :label="$t('[[[Kod dostępu stanowiska]]]')" class="me-2 min-width-150" :invalid-feedback="form.$errors.first(`m[${index}].AccessCode`)" :state="form.$errors.state(`[${index}].AccessCode`)">
                        <ideo-form-input v-model="value.accessCode" :name="`[${index}].AccessCode`" />
                    </ideo-form-group>
                    <ideo-form-group :label="$t('[[[Strefa]]]')" class="me-2 min-width-150" :invalid-feedback="form.$errors.first(`m[${index}].PackerStationLocalizations`)" :state="form.$errors.state(`m[${index}].PackerStationLocalizations`)">
                        <ZonesPicker
                            v-model="value.packerStationLocalizations[0].zonePublicId"
                            :name="`m[${index}].PackerStationLocalizations`"
                            @changed="form.$errors.clear(`m[${index}].PackerStationLocalizations`)"
                            :refresh="true"
                        />
                    </ideo-form-group>
                    <ideo-form-group :label="$t('[[[Lokalizacja]]]')" class="me-2 min-width-150" :invalid-feedback="form.$errors.first(`m[${index}].PackerStationLocalizations`)" :state="form.$errors.state(`m[${index}].PackerStationLocalizations`)">
                        <LocalizationPicker
                            v-model="value.packerStationLocalizations[0].localizationPublicId"
                            :name="`m[${index}].PackerStationLocalizations`"
                            :zone-public-id="value.packerStationLocalizations[0].zonePublicId"
                            @changed="form.$errors.clear(`m[${index}].PackerStationLocalizations`)"
                            :refresh="true"
                        />
                    </ideo-form-group>
                    <ideo-form-group :label="$t('[[[Drukarki]]]')" class="me-2 min-width-150" :invalid-feedback="form.$errors.first(`m[${index}].PackerStationPrinters`)" :state="form.$errors.state(`[${index}].PackerStationPrinters`)">
                        <PrintersPicker
                            v-model="value.packerStationPrinters"
                            :multiple="true"
                            :close-on-select="false"
                            :name="`[${index}].PackerStationPrinters`"
                            @changed="form.$errors.clear(`[${index}].PackerStationPrinters`)"
                        />
                    </ideo-form-group>
                </div>
                <div class="d-flex flex-column mt-auto">
                    <div class="d-flex" v-for="(coordination, coordinatesIndex) in value.packerStationCoordinates" :key="coordinatesIndex">
                        <ideo-form-group :label="$t('[[[Typ]]]')" class="me-2" style="min-width: 200px" :invalid-feedback="form.$errors.first(`m[${index}].PackerStationCoordinates`)" :state="form.$errors.state(`m[${index}].PackerStationCoordinates`)">
                            <ideo-multiselect
                                v-model="coordination.type"
                                :options="getStationCoordinatesOptions(value, coordination.type)"
                                track-by="type"
                                label="name"
                                name="type"
                                :searchable="false"
                                :show-reset="false"
                                :disabled="false"
                                @select="onCoordinationSelect(index, coordinatesIndex)"
                                @changed="form.$errors.clear(`m[${index}].PackerStationCoordinates`)"
                            />
                        </ideo-form-group>
                        <template v-if="getCoordinatesTypeFields(coordination.type).length">
                            <ideo-form-group v-for="configField in getCoordinatesTypeFields(coordination.type)" style="width: 200px" :label="configField.name" :key="configField.key" class="me-2">
                                <component
                                    v-if="configField.type.includes('autocomplete')"
                                    :is="getCoordinatesFieldComponent(configField)" v-model="coordination.args[configField.key]"
                                    :name="configField.key" :searchable="true" :show-reset="false" :disabled="false" :refresh="true"
                                    :multiple="getCoordinatesFieldComponent(configField).includes('ProductGroupsPicker')"
                                />
                                <component
                                    v-else-if="configField.type.includes('Decimal') || configField.type.includes('Int32')"
                                    :is="getCoordinatesFieldComponent(configField)" v-model="coordination.args[configField.key]"
                                    :name="configField.key" min="0" type="number"
                                />
                                <component
                                    v-else
                                    :is="getCoordinatesFieldComponent(configField)" v-model="coordination.args[configField.key]"
                                    :name="configField.key"
                                />
                            </ideo-form-group>
                        </template>
                        <ideo-form-group label="" class="mt-auto">
                            <ideo-button variant="danger" :title="$t('[[[Usuń warunek]]]')" @click="removeCoordination(index, coordinatesIndex)" class="mb-auto mt-auto normal-button">
                                <i class="fa-solid fa-trash"></i>
                            </ideo-button>
                        </ideo-form-group>
                    </div>
                    <div class="d-flex">
                        <ideo-form-group label="" class="mt-auto me-2" style="width: 200px">
                            <ideo-button variant="success" :title="$t('[[[Dodaj warunek]]]')" @click="addCoordination(value)" class="normal-button w-100" :disabled="value.packerStationCoordinates.length == stationCoordinatesOptions.length">
                                <span>{{ $t('[[[Dodaj warunek]]]') }}</span><i class="fa-solid fa-plus ms-2"></i>
                            </ideo-button>
                        </ideo-form-group>
                        <ideo-form-group label="" style="width: 200px">
                            <ideo-button variant="danger" :title="$t('[[[Usuń stanowisko]]]')" @click="remove(index)" class="normal-button w-100">
                                <span>{{ $t('[[[Usuń stanowisko]]]') }}</span><i class="fa-solid fa-minus ms-2"></i>
                            </ideo-button>
                        </ideo-form-group>
                    </div>
                    <div class="d-flex" v-if="value.packerStationUser">
                        <ideo-form-group :label="$t('[[[Osoba zajmująca stanowisko]]]')" class="me-2" style="min-width: 200px">
                            <div>
                                <strong>{{ value.packerStationUser.user.fullName }}</strong>
                            </div>
                        </ideo-form-group>
                        <ideo-form-group class="me-2 mt-auto" style="min-width: 200px">
                            <ideo-button variant="primary" :title="$t('[[[Zwolnij stanowisko]]]')" @click="releaseStation(value.publicId)" class="normal-button w-100">
                                <span>{{ $t('[[[Zwolnij stanowisko]]]') }}</span>
                            </ideo-button>
                        </ideo-form-group>
                    </div>
                </div>
                <hr>
            </template>
        </ideo-form>
        <div class="d-flex align-items-center">
            <ideo-form-group class="mt-auto mb-3">
                <ideo-button variant="success" :title="$t('[[[Dodaj stanowisko]]]')" class="normal-button" @click="add()">
                    <span>{{ $t('[[[Dodaj stanowisko]]]') }}</span><i class="fa-solid fa-plus ms-2"></i>
                </ideo-button>
            </ideo-form-group>
        </div>
    </div>
</template>


<style lang="scss" scoped>
.min-width-150{
    min-width: 150px;
}
</style>
