<template>
    <component :is="'div'" class="d-flex py-1" :class="getClass(index)" @click="onClick" v-bind="bindData">
        <!-- <div class="ms-2" v-if="checkbox().length > 0">
            <vnodes :vnodes="checkbox()" />
        </div> -->
        <div class="ms-2" v-if="images().length > 0">
            <vnodes :vnodes="images()" />
        </div>
        <div class="d-flex flex-column flex-grow-1 mx-1">
            <div class="d-flex">
                <div class="me-2 list-checkbox" v-if="checkbox().length > 0">
                    <vnodes :vnodes="checkbox()" />
                </div>
                <div class="flex-grow-1 mx-1 mt-1">
                    <vnodes :vnodes="header()" />
                </div>
                <div class="d-flex ms-auto">
                    <div class="ms-2" v-if="buttons().length > 0">
                        <vnodes :vnodes="buttons()" />
                    </div>
                </div>
            </div>
            <div class="mb-1" v-if="label().length > 0">
                <vnodes :vnodes="label()" />
            </div>
            <div class="pb-1" v-if="items().length > 0">
                <div :class="itemsClassNames">
                    <vnodes :vnodes="items()" />
                </div>
            </div>
        </div>
    </component>
</template>

<script lang="ts" setup>
import { computed, inject, provide, useSlots, useAttrs } from 'vue';
import { Header, travel } from '../helpers';

export interface Props
{
    index: number;
    item: any;
    headers: Header[];
}

defineOptions({
    name: 'list-view-utils-flex',
    components: {
        'vnodes': (props: Record<string, any>, context: any) => props.vnodes
    },
    inheritAttrs: false
});

const $slots = useSlots();
const $attrs = useAttrs();
const props = defineProps({
  "index": null,
  "item": null,
  "headers": null
});
const itemsClassNames = computed(() => `row row-cols-1 row-cols-sm-2 row-cols-md-3`);
const item = computed(() => props.item);
const rowClass = inject<(item: any, i: number) => Record<string, boolean> | string[] | string>('row-class');
const rowClick = inject<(item: any, index: number, e: PointerEvent) => any>('row-click');

const bindData = computed(() => ({
    'data-draggable': $attrs['data-draggable'],
    draggable: $attrs.draggable,
    style: $attrs.style
}));

provide('item', item);

const draggable = inject<boolean>('draggable');

const getClass = (index: number): any =>
{
    let result = { 'bg-light-subtle': index % 2 == 0, 'draggable': draggable };

    if (rowClass)
    {
        result = Object.assign(result, rowClass(props.item, props.index));
    }

    return result;
};

const onClick = (e: any): void =>
{
    if (rowClick && props.item)
        rowClick(props.item, props.index, e);
};

const cells = (): any[] =>
{
    const allowed = [
        'list-view-header',
        'list-view-item',
        'list-view-label',
        'list-view-buttons',
        'list-view-image',
        'list-view-checkbox'
    ];
    const prefix = (new Date()).getTime();

    return travel($slots.default(), allowed)
        .map((p, i) =>
        {
            p.key = `${prefix}-${i}`;
            p.props = p.props || {};
            p.props.index = i;
            p.props.header = props.headers[i];

            return p;
        })
        .filter(p => !p.props.header.disabled)
        .orderBy(p => p.props.header.position)
        .toArray();
};

const filter = (tagName: string): any[] =>
{
    return cells().filter(p => p.type.name == tagName);
};

const header = (): any[] =>
{
    return filter('list-view-header');
};

const items = (): any[] =>
{
    return filter('list-view-item');
};

const label = (): any[] =>
{
    return filter('list-view-label');
};

const buttons = (): any[] =>
{
    return filter('list-view-buttons');
};

const images = (): any[] =>
{
    return filter('list-view-image');
};

const checkbox = (): any[] =>
{
    return filter('list-view-checkbox');
};
</script>
